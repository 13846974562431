import {Deserializable} from './deserializable.model';

export class User implements Deserializable {
  id: number;
  name: number;
  email: string;
  last_name: string;
  phone: string;
  birthdate: string;
  country_id: number;
  state_id: number;
  city: string;
  avatar: string;
  zipcode: string;
  address: string;

  deserialize(input: any): this {
    this.id = input.id;
    this.name = input.aditional_data.name;
    this.email = input.email;
    this.last_name = input.aditional_data.last_name;
    this.phone = input.aditional_data.phone;
    this.birthdate = input.aditional_data.birthdate;
    this.country_id = input.aditional_data.country_id;
    this.state_id = input.aditional_data.state_id;
    this.city = input.aditional_data.city;
    this.avatar = input.aditional_data.avatar;
    this.zipcode = input.aditional_data.zipcode;
    this.address = input.aditional_data.address;

    return this;
  }
}
