import { Component, OnInit, Input } from "@angular/core";
declare var $: any;
@Component({
  selector: "app-hotel-listado-result",
  templateUrl: "./hotel-listado-result.component.html",
  styleUrls: ["./hotel-listado-result.component.css"],
})
export class HotelListadoResultComponent implements OnInit {
  @Input() isLoading = false;
  @Input() hotelsAvailabilities: any;
  constructor() {}

  ngOnInit() {
    $(".selectpicker").selectpicker({
      style: "custom-select-button",
    });
  }
}
