import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  totalItems: number = 0;
  total: number = 0;
  constructor() { }

  calculateBookPrice(book_with, price)
  {
    if(book_with.type === "amount"){
      return Math.round(book_with.quantity);
    }else if(book_with.type === "percentage"){
      const bookWithPrice = (price * book_with.quantity / 100);
      return Math.round(bookWithPrice);
    }
  }

  async loadCart(){
    const localCart = await localStorage.getItem('cart');
    if(localCart !== null && localCart !== undefined){
      const cart = JSON.parse(localCart);
      this.totalItems = cart.items.length;
      this.total = cart.total;
      return cart;
    }else{
      return null;
    }
  }

  addItem(item){
    if(item.product_type !== 1){
      this.clearCart();
    }
    
    let currentCart = localStorage.getItem('cart');
    let items = [];
    if(currentCart !== null && currentCart !== undefined){
      items = JSON.parse(currentCart).items;
    }
    items.push(item);
    let cart = {
      items,
      cost: this.calculateTotalCost(items),
      subtotal: this.calculateTotalPrice(items),
      discount: 0,
      coupon_id: null,
      total: this.calculateTotalPrice(items),
      to_pay: this.calculateTotalPrice(items)
    }
    this.loadCart();
    return localStorage.setItem('cart', JSON.stringify(cart));
  }

  removeItem(index){
    let currentCart = localStorage.getItem('cart');
    let items = [];
    if(currentCart !== null && currentCart !== undefined){
      items = JSON.parse(currentCart).items;
    }
    items.splice(index, 1);
    let cart = {
      items,
      cost: this.calculateTotalCost(items),
      subtotal: this.calculateTotalPrice(items),
      discount: 0,
      coupon_id: null,
      total: this.calculateTotalPrice(items),
      to_pay: this.calculateTotalPrice(items)
    }
    this.loadCart();
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  changeAmountToPaid(amount){
    let currentCart = JSON.parse(localStorage.getItem('cart'));
    currentCart.to_pay = amount;
    localStorage.setItem('cart', JSON.stringify(currentCart));
  }

  clearCart(){
    this.totalItems = 0;
    this.total = 0;
    localStorage.removeItem('cart');
  }

  calculateTotalCost(items){
    return items.reduce((partial_sum, element) => partial_sum + element.cost, 0)
  }

  calculateTotalPrice(items){
    return items.reduce((partial_sum, element) => partial_sum + element.price, 0)
  }
}
