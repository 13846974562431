import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-package-detail-tabs',
  templateUrl: './package-detail-tabs.component.html',
  styleUrls: ['./package-detail-tabs.component.css']
})
export class PackageDetailTabsComponent implements OnInit {
  @Output() changeMinPricePackage: EventEmitter<any> =  new EventEmitter();
  @Input() package: any;
  @Input() activeTab;
  hotels = [];
  flights = [];
  packageId: 0;
  constructor() { }

  ngOnInit() {
    console.log('detail tabs')
    console.log(this.package)
    this.packageId = this.package.id;
  }

  setAvailabilities(res) {
    this.hotels = res.hotels;
    this.flights = res.flights;
    this.changeMinPricePackage.emit(res.minPrice);
  }
}
