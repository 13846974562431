import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { UserService } from '../../../services/User/user.service';

@Component({
  selector: 'app-loginregister',
  templateUrl: './loginregister.component.html',
  styleUrls: ['./loginregister.component.css']
})
export class LoginregisterComponent implements OnInit {
  @Input() fromCheckout: boolean = false;
  isLoading = false;
  isLoadingLogin = false;
  formRegHasError = false;
  formLoginHasError = false;
  samePassword = true;
  
  formReg = new FormGroup({
    first_name: new FormControl(null, [Validators.required]),
    last_name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required, Validators.minLength(8),]),
    confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(8),]),
    checkTerms: new FormControl(null,[Validators.required])
  });

  formLogin = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required]),
  })
  

  constructor(private toastr: ToastrService, private userService: UserService, private translate: TranslateService) { }

  ngOnInit() {

  }

  onSubmitRegister() {
    this.formRegHasError = !this.formReg.valid;
    this.samePassword = this.formReg.get('password').value === this.formReg.get('confirmPassword').value
    
    if(!this.formRegHasError && this.samePassword){
      this.sendRegForm();
    }
  }

  async sendRegForm(){
    let msgSuccess;
    let errorEmail;
    await this.translate.get('RegistroExitoso').toPromise().then(resp => msgSuccess = resp)
    await this.translate.get('EmailExiste').toPromise().then(resp => errorEmail = resp)
    this.isLoading = true;
    this.userService.Register(this.formReg.value).toPromise().then(data => {
      this.isLoading = false;
      const response: any = data;
      if (response.success) {
        this.toastr.success(msgSuccess);
        localStorage.setItem("user", JSON.stringify(response.data));
        if(this.fromCheckout){
          document.location.href = "/checkout";
        }else{
          document.location.href = "/";
        }
        
      } else {
        this.toastr.error(response.message);
      }
    })
    .catch((err) => {
      this.isLoading = false;
      const msgError = err.error.message === 'email_exists' ? errorEmail : err.message
      this.toastr.error(msgError);
    });

  }

  onSubmitLogin(){
    this.formLoginHasError = !this.formLogin.valid;
    if(this.formLogin.valid){
      this.sendLogin();
    }
  }

  async sendLogin(){
    let loginFailed;
    await this.translate.get('LoginFallido').toPromise().then(resp => loginFailed = resp)
    this.isLoadingLogin = true;
    this.userService.Login(this.formLogin.value).toPromise().then(data => {
      this.isLoadingLogin = false;
      const response: any = data;
      if (response.success) {
        localStorage.setItem("user", JSON.stringify(response.data));
        if(this.fromCheckout){
          document.location.href = "/checkout";
        }else{
          document.location.href = "/";
        }
      } else {
        this.toastr.error(loginFailed);
      }
    })
    .catch((err) => {
      this.isLoadingLogin = false;
      const msgError = err.error.message === 'unauthorized' ? loginFailed : err.message
      this.toastr.error(msgError);
    });
  }
}
