import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-package-detail-title',
  templateUrl: './package-detail-title.component.html',
  styleUrls: ['./package-detail-title.component.css']
})
export class PackageDetailTitleComponent implements OnInit {
  @Input()
  package: any;
  days: number;
  constructor() {}

  ngOnInit() {
    this.days = this.package.nights + 1;
  }

}
