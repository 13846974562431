import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment"; // Angular CLI environment

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RouterModule } from "@angular/router";
import { routing } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgwWowModule } from "ngx-wow";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ModalModule } from "ngx-bootstrap/modal";
// import { OwlModule } from 'ngx-owl-carousel';
import { TabsModule } from "ngx-bootstrap/tabs";
import { NoCommaPipe } from "./pipes/noComma.pipe";
import { BlockUIModule } from "ng-block-ui";
import { DeviceDetectorModule } from "ngx-device-detector";
import { SlideshowModule } from "ng-simple-slideshow";

import { HomeComponent } from "./components/home/home/home.component";
import { HeaderComponent } from "./components/header/header/header.component";
import { MenuComponent } from "./components/header/menu/menu.component";
import { TopBarComponent } from "./components/header/top-bar/top-bar.component";
import { FooterComponent } from "./components/shared/footer/footer.component";
import { SearchComponent } from "./components/home/search/search.component";
import { RecentBlogComponent } from "./components/home/recent-blog/recent-blog.component";
import { TopDestinationComponent } from "./components/home/top-destination/top-destination.component";
import { HotDealsComponent } from "./components/home/hot-deals/hot-deals.component";
import { ChooseUsComponent } from "./components/home/choose-us/choose-us.component";
import { LoadingComponent } from "./components/shared/loading/loading.component";
import { HotelComponent } from "./components/hotel/hotel/hotel.component";
import { LastMinuteDealsComponent } from "./components/hotel/last-minute-deals/last-minute-deals.component";
import { HotelSearchComponent } from "./components/hotel/hotel-search/hotel-search.component";
import { HotelTopDestinatiosComponent } from "./components/hotel/hotel-top-destinatios/hotel-top-destinatios.component";
import { ImageGalleryComponent } from "./components/shared/image-gallery/image-gallery.component";
import { HotelDetailComponent } from "./pages/hotel-detail/hotel-detail.component";
import { HotelDetailLocationComponent } from "./components/hotel/hotel-detail-location/hotel-detail-location.component";
import { HotelDetailResumeComponent } from "./components/hotel/hotel-detail-resume/hotel-detail-resume.component";
import { HotelDetailSimilarHotelsComponent } from "./components/hotel/hotel-detail-similar-hotels/hotel-detail-similar-hotels.component";
import { HotelDetailTabsComponent } from "./components/hotel/hotel-detail-tabs/hotel-detail-tabs.component";
import { RentaComponent } from "./components/renta/renta/renta.component";
import { RentaSearchComponent } from "./components/renta/renta-search/renta-search.component";
import { RentaLastMinuteDealsComponent } from "./components/renta/renta-last-minute-deals/renta-last-minute-deals.component";
import { RentaTopDestinationComponent } from "./components/renta/renta-top-destination/renta-top-destination.component";
import { RentaDetailComponent } from "./components/renta/renta-detail/renta-detail.component";
import { VuelosComponent } from "./components/vuelos/vuelos/vuelos.component";
import { VuelosListadoSearchComponent } from "./components/vuelos/vuelosListado/vuelos-listado-search/vuelos-listado-search.component";
import { VuelosListadoFiltersComponent } from "./components/vuelos/vuelosListado/vuelos-listado-filters/vuelos-listado-filters.component";
import { VuelosListadoResultComponent } from "./components/vuelos/vuelosListado/vuelos-listado-result/vuelos-listado-result.component";
import { VuelosListadoComponent } from "./components/vuelos/vuelosListado/vuelos-listado/vuelos-listado.component";
import { HotelDetailTabOverviewComponent } from "./components/hotel/hotel-detail-tab-overview/hotel-detail-tab-overview.component";
import { HotelDetailTabRoomsComponent } from "./components/hotel/hotel-detail-tab-rooms/hotel-detail-tab-rooms.component";
import { HotelDetailTabAmmenitiesComponent } from "./components/hotel/hotel-detail-tab-ammenities/hotel-detail-tab-ammenities.component";
import { HotelDetailTabReviewsComponent } from "./components/hotel/hotel-detail-tab-reviews/hotel-detail-tab-reviews.component";
import { HotelDetailTabWriteReviewComponent } from "./components/hotel/hotel-detail-tab-write-review/hotel-detail-tab-write-review.component";
import { SearchVuelosComponent } from "./components/home/search-vuelos/search-vuelos.component";
import { SearchHotelesComponent } from "./components/home/search-hoteles/search-hoteles.component";
import { SearchRentasComponent } from "./components/home/search-rentas/search-rentas.component";
import { SearchPaquetesComponent } from "./components/home/search-paquetes/search-paquetes.component";
import { SearchCrucerosComponent } from "./components/home/search-cruceros/search-cruceros.component";
import { SearchAutosComponent } from "./components/home/search-autos/search-autos.component";
import { SearchToursComponent } from "./components/home/search-tours/search-tours.component";
import { SearchOfferSliderComponent } from "./components/home/search-offer-slider/search-offer-slider.component";
import { PackageComponent } from "./pages/package/package.component";
import { PackageDetailComponent } from "./pages/package-detail/package-detail.component";
import { PackageHotSaleComponent } from "./components/package/package-hot-sale/package-hot-sale.component";
import { PackageMostPopularComponent } from "./components/package/package-most-popular/package-most-popular.component";
import { PackageSearchComponent } from "./components/package/package-search/package-search.component";
import { TourComponent } from "./components/tour/tour/tour.component";
import { TourDetailComponent } from "./components/tour/tour-detail/tour-detail.component";
import { TourSpecialOffersComponent } from "./components/tour/tour-special-offers/tour-special-offers.component";
import { TourMostPopularComponent } from "./components/tour/tour-most-popular/tour-most-popular.component";
import { TourSearchComponent } from "./components/tour/tour-search/tour-search.component";
import { TourForAllComponent } from "./components/tour/tour-for-all/tour-for-all.component";
import { CarComponent } from "./components/car/car/car.component";
import { CarDetailComponent } from "./components/car/car-detail/car-detail.component";
import { CarHotSaleComponent } from "./components/car/car-hot-sale/car-hot-sale.component";
import { CarMostPopularComponent } from "./components/car/car-most-popular/car-most-popular.component";
import { CarSearchComponent } from "./components/car/car-search/car-search.component";
import { CarSponsorsComponent } from "./components/car/car-sponsors/car-sponsors.component";
import { TourDetailResumenTourComponent } from "./components/tour/tour-detail-resumen-tour/tour-detail-resumen-tour.component";
import { TourDetailRecomendadosComponent } from "./components/tour/tour-detail-recomendados/tour-detail-recomendados.component";
import { AsistenciaComponent } from "./components/shared/asistencia/asistencia.component";
import { TourDetailTabsComponent } from "./components/tour/tour-detail-tabs/tour-detail-tabs.component";
import { TourDetailTabsDisponibilidadComponent } from "./components/tour/tour-detail-tabs-disponibilidad/tour-detail-tabs-disponibilidad.component";
import { TourDetailTabsInformacionGeneralComponent } from "./components/tour/tour-detail-tabs-informacion-general/tour-detail-tabs-informacion-general.component";
import { TourDetailTabsDetallesComponent } from "./components/tour/tour-detail-tabs-detalles/tour-detail-tabs-detalles.component";
import { CarDetailTitleComponent } from "./components/car/car-detail-title/car-detail-title.component";
import { CarDetailOverviewComponent } from "./components/car/car-detail-overview/car-detail-overview.component";
import { CarDetailSidebarComponent } from "./components/car/car-detail-sidebar/car-detail-sidebar.component";
import { CarDetailSimilarComponent } from "./components/car/car-detail-similar/car-detail-similar.component";
import { CarDetailTabsComponent } from "./components/car/car-detail-tabs/car-detail-tabs.component";
import { CarDetailTabAvailabilityComponent } from "./components/car/car-detail-tab-availability/car-detail-tab-availability.component";
import { CarDetailTabFeaturesComponent } from "./components/car/car-detail-tab-features/car-detail-tab-features.component";
import { CarDetailTabAdditionalChargesComponent } from "./components/car/car-detail-tab-additional-charges/car-detail-tab-additional-charges.component";
import { PackageDetailTitleComponent } from "./components/package/package-detail-title/package-detail-title.component";
import { PackageDetailSidebarComponent } from "./components/package/package-detail-sidebar/package-detail-sidebar.component";
import { PackageDetailTabsComponent } from "./components/package/package-detail-tabs/package-detail-tabs.component";
import { PackageDetailTabDetailsComponent } from "./components/package/package-detail-tab-details/package-detail-tab-details.component";
import { PackageDetailTabIncludesComponent } from "./components/package/package-detail-tab-includes/package-detail-tab-includes.component";
import { PackageDetailTabItineraryComponent } from "./components/package/package-detail-tab-itinerary/package-detail-tab-itinerary.component";
import { PackageDetailTabInfoComponent } from "./components/package/package-detail-tab-info/package-detail-tab-info.component";
import { PackageDetailSupportComponent } from "./components/package/package-detail-support/package-detail-support.component";
import { CarListadoComponent } from "./components/car/carListado/car-listado/car-listado.component";
import { CarListadoFiltersComponent } from "./components/car/carListado/car-listado-filters/car-listado-filters.component";
import { CarListadoResultComponent } from "./components/car/carListado/car-listado-result/car-listado-result.component";
import { CarListadoSearchComponent } from "./components/car/carListado/car-listado-search/car-listado-search.component";
import { TourListadoComponent } from "./components/tour/tourListado/tour-listado/tour-listado.component";
import { TourListadoFiltersComponent } from "./components/tour/tourListado/tour-listado-filters/tour-listado-filters.component";
import { TourListadoResultComponent } from "./components/tour/tourListado/tour-listado-result/tour-listado-result.component";
import { TourListadoSearchComponent } from "./components/tour/tourListado/tour-listado-search/tour-listado-search.component";
import { VuelosSearchComponent } from "./components/vuelos/vuelos-search/vuelos-search.component";
import { VuelosHotSaleComponent } from "./components/vuelos/vuelos-hot-sale/vuelos-hot-sale.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LeftSidebarComponent } from "./components/dashboard/left-sidebar/left-sidebar.component";
import { TitleComponent } from "./components/dashboard/title/title.component";
import { ProfileCenterBarComponent } from "./components/dashboard/profile-center-bar/profile-center-bar.component";
import { ProfileRightSidebarComponent } from "./components/dashboard/profile-right-sidebar/profile-right-sidebar.component";
import { OverviewCenterBarComponent } from "./components/dashboard/overview-center-bar/overview-center-bar.component";
import { OverviewRightSidebarComponent } from "./components/dashboard/overview-right-sidebar/overview-right-sidebar.component";
import { BookingCenterBarComponent } from "./components/dashboard/booking-center-bar/booking-center-bar.component";
import { MyCardsCenterBarComponent } from "./components/dashboard/my-cards-center-bar/my-cards-center-bar.component";
import { HelpCenterBarComponent } from "./components/dashboard/help-center-bar/help-center-bar.component";
import { HotelListadoComponent } from "./pages/hotel-listado/hotel-listado.component";
import { HotelListadoFiltersComponent } from "./components/hotel/hotel-listado/hotel-listado-filters/hotel-listado-filters.component";
import { HotelListadoResultComponent } from "./components/hotel/hotel-listado/hotel-listado-result/hotel-listado-result.component";
import { HotelListadoSearchComponent } from "./components/hotel/hotel-listado/hotel-listado-search/hotel-listado-search.component";
import { PackageListadoComponent } from "./pages/package-listado/package-listado.component";
import { PackageListadoFiltersComponent } from "./components/package/package-listado/package-listado-filters/package-listado-filters.component";
import { PackageListadoResultComponent } from "./components/package/package-listado/package-listado-result/package-listado-result.component";
import { PackageListadoSearchComponent } from "./components/package/package-listado/package-listado-search/package-listado-search.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { CheckoutLoginComponent } from "./components/checkout/checkout-login/checkout-login.component";
import { CheckoutInfoComponent } from "./components/checkout/checkout-info/checkout-info.component";
import { CheckoutPagoComponent } from "./components/checkout/checkout-pago/checkout-pago.component";
import { CheckoutReservaComponent } from "./components/checkout/checkout-reserva/checkout-reserva.component";
import { CheckoutOrdenResumeRightSidebarComponent } from "./components/shared/checkout-orden-resume-right-sidebar/checkout-orden-resume-right-sidebar.component";
import { AsistenciaHorizontalComponent } from "./components/shared/asistencia-horizontal/asistencia-horizontal.component";
import { ShoppingCartComponent } from "./pages/shopping-cart/shopping-cart.component";
import { PackageDetailTabAvailabilityComponent } from "./components/package/package-detail-tab-availability/package-detail-tab-availability.component";
import { Home2Component } from "./pages/home2/home2.component";
import { Home2PrincipalComponent } from "./components/home2/home2-principal/home2-principal.component";
import { Home2PackagesComponent } from "./components/home2/home2-packages/home2-packages.component";
import { Home2DestiniesComponent } from "./components/home2/home2-destinies/home2-destinies.component";
import { Home2ContactUsComponent } from "./components/home2/home2-contact-us/home2-contact-us.component";
import { Home2ReasonsComponent } from "./components/home2/home2-reasons/home2-reasons.component";
import { PopupReserveComponent } from "./components/shared/popup-reserve/popup-reserve.component";
import { VuelosMostPopularComponent } from "./components/vuelos/vuelos-most-popular/vuelos-most-popular.component";
import { PoliciesComponent } from "./pages/policies/policies.component";
import { WhoWeAreComponent } from "./pages/who-we-are/who-we-are.component";
import { TopBarLightComponent } from "./components/header/top-bar-light/top-bar-light.component";
import { HeaderLightComponent } from "./components/header/header-light/header-light.component";
import { PackageDetailAvailabilityItemComponent } from "./components/package/package-detail-availability-item/package-detail-availability-item.component";
import { PackageDetailIncludesHotelItemComponent } from "./components/package/package-detail-includes-hotel-item/package-detail-includes-hotel-item.component";
import { PackageDetailIncludesFlightItemComponent } from "./components/package/package-detail-includes-flight-item/package-detail-includes-flight-item.component";
import { PackageDetailAvailabilityMultiItemComponent } from "./components/package/package-detail-availability-multi-item/package-detail-availability-multi-item.component";
import { LoginregisterComponent } from "./components/user/loginregister/loginregister.component";
import { RegisterComponent } from "./components/user/loginregistermodal/register/register.component";
import { ServicesBoxComponent } from "./components/shopping/services-box/services-box.component";
import { AddMoreProductsComponent } from "./components/shopping/add-more-products/add-more-products.component";
import { ItemPackageComponent } from "./components/shopping/item-packages/item-package/item-package.component";
import {
  AccordionModule,
  ButtonsModule,
  PaginationModule,
} from "ngx-bootstrap";
import { SquareComponent } from "./components/checkout/paymentforms/square/square.component";
import { OrderConfirmationComponent } from "./pages/order-confirmation/order-confirmation.component";
import { PackageDetailTabNoincludesComponent } from "./components/package/package-detail-tab-noincludes/package-detail-tab-noincludes.component";

import { FlightModule } from "./components/flight/flight.module";
import { FlightsListComponent } from "./pages/flights-list/flights-list.component";
import { ItemPackageMultidestinyComponent } from "./components/shopping/item-packages/item-package-multidestiny/item-package-multidestiny.component";
import { PackageMultidestinyResumeCardComponent } from "./components/package/package-multidestiny-resume-card/package-multidestiny-resume-card.component";
import { CarTableRowComponent } from "./components/shopping/car-table-row/car-table-row.component";
import { PackageListadoResultItemComponent } from "./components/package/package-listado/package-listado-result-item/package-listado-result-item.component";
import { OrderCardPackageComponent } from "./components/dashboard/order-card-package/order-card-package.component";
import { OrderCardFlightComponent } from "./components/dashboard/order-card-flight/order-card-flight.component";
import { SquareupformComponent } from "./components/dashboard/creditcards/new/squareupform/squareupform.component";
import { CreditcardCardComponent } from "./components/dashboard/creditcards/creditcard-card/creditcard-card.component";
import { HelpTicketCardComponent } from "./components/dashboard/help-ticket-card/help-ticket-card.component";
import { LoginComponent } from "./components/user/loginregistermodal/login/login.component";
import { GuestComponent } from "./components/user/loginregistermodal/guest/guest.component";
import { PasswordresetComponent } from "./components/user/loginregistermodal/passwordreset/passwordreset.component";
import { UsermodalloginComponent } from "./components/user/loginregistermodal/usermodallogin/usermodallogin.component";
import { UserloginComponent } from "./pages/userlogin/userlogin.component";
import { UserregisterComponent } from "./pages/userregister/userregister.component";
import { UserlogincheckoutComponent } from "./pages/userlogincheckout/userlogincheckout.component";
import { AsistenciaboxComponent } from "./components/checkout/asistenciabox/asistenciabox.component";
import { ImageGalleryMobileComponent } from "./components/shared/image-gallery-mobile/image-gallery-mobile.component";
import { Home2SearchTabsComponent } from "./components/home2/home2-search-tabs/home2-search-tabs.component";
import { PaymentsComponent } from "./components/dashboard/payments/payments/payments.component";
import { PaymentsListComponent } from "./components/dashboard/payments/payments-list/payments-list.component";
import { PaymentsNewComponent } from "./components/dashboard/payments/payments-new/payments-new.component";
import { ConfirmationResumeComponent } from "./components/checkout/confirmation-resume/confirmation-resume.component";
import { BookingCenterBarItemComponent } from "./components/dashboard/booking-center-bar-item/booking-center-bar-item.component";
import { HotelSearchTabComponent } from "./components/hotel/hotel-search-tab/hotel-search-tab.component";
import { HotelListadoResultItemComponent } from "./components/hotel/hotel-listado/hotel-listado-result-item/hotel-listado-result-item.component";
import { StarsComponent } from "./components/shared/stars/stars.component";

import { StoreModule } from "@ngrx/store";
import { hotelReducer } from "./reducers/hotel.reducer";
import { HotelResumeCardComponent } from './components/hotel/hotel-resume-card/hotel-resume-card.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SearchComponent,
    RecentBlogComponent,
    TopDestinationComponent,
    HotDealsComponent,
    ChooseUsComponent,
    LoadingComponent,
    HotelComponent,
    LastMinuteDealsComponent,
    HotelSearchComponent,
    HotelTopDestinatiosComponent,
    MenuComponent,
    TopBarComponent,
    ImageGalleryComponent,
    HotelDetailComponent,
    HotelDetailLocationComponent,
    HotelDetailResumeComponent,
    HotelDetailSimilarHotelsComponent,
    HotelDetailTabsComponent,
    RentaComponent,
    RentaSearchComponent,
    RentaLastMinuteDealsComponent,
    RentaTopDestinationComponent,
    RentaDetailComponent,
    VuelosComponent,
    VuelosListadoSearchComponent,
    VuelosListadoFiltersComponent,
    VuelosListadoResultComponent,
    VuelosListadoComponent,
    HotelDetailTabOverviewComponent,
    HotelDetailTabRoomsComponent,
    HotelDetailTabAmmenitiesComponent,
    HotelDetailTabReviewsComponent,
    HotelDetailTabWriteReviewComponent,
    SearchVuelosComponent,
    SearchHotelesComponent,
    SearchRentasComponent,
    SearchPaquetesComponent,
    SearchCrucerosComponent,
    SearchAutosComponent,
    SearchToursComponent,
    SearchOfferSliderComponent,
    PackageComponent,
    PackageDetailComponent,
    PackageHotSaleComponent,
    PackageMostPopularComponent,
    PackageSearchComponent,
    TourComponent,
    TourDetailComponent,
    TourSpecialOffersComponent,
    TourMostPopularComponent,
    TourSearchComponent,
    TourForAllComponent,
    CarComponent,
    CarDetailComponent,
    CarHotSaleComponent,
    CarMostPopularComponent,
    CarSearchComponent,
    CarSponsorsComponent,
    TourDetailResumenTourComponent,
    TourDetailRecomendadosComponent,
    AsistenciaComponent,
    TourDetailTabsComponent,
    TourDetailTabsDisponibilidadComponent,
    TourDetailTabsInformacionGeneralComponent,
    TourDetailTabsDetallesComponent,
    CarDetailTitleComponent,
    CarDetailOverviewComponent,
    CarDetailSidebarComponent,
    CarDetailSimilarComponent,
    CarDetailTabsComponent,
    CarDetailTabAvailabilityComponent,
    CarDetailTabFeaturesComponent,
    CarDetailTabAdditionalChargesComponent,
    PackageDetailTitleComponent,
    PackageDetailSidebarComponent,
    PackageDetailTabsComponent,
    PackageDetailTabDetailsComponent,
    PackageDetailTabIncludesComponent,
    PackageDetailTabItineraryComponent,
    PackageDetailTabInfoComponent,
    PackageDetailSupportComponent,
    TourDetailResumenTourComponent,
    TourDetailRecomendadosComponent,
    AsistenciaComponent,
    TourDetailTabsDisponibilidadComponent,
    TourDetailTabsInformacionGeneralComponent,
    TourDetailTabsDetallesComponent,
    CarListadoComponent,
    CarListadoFiltersComponent,
    CarListadoResultComponent,
    CarListadoSearchComponent,
    TourListadoComponent,
    TourListadoFiltersComponent,
    TourListadoResultComponent,
    TourListadoSearchComponent,
    VuelosSearchComponent,
    VuelosHotSaleComponent,
    DashboardComponent,
    LeftSidebarComponent,
    TitleComponent,
    ProfileCenterBarComponent,
    ProfileRightSidebarComponent,
    OverviewCenterBarComponent,
    OverviewRightSidebarComponent,
    BookingCenterBarComponent,
    MyCardsCenterBarComponent,
    HelpCenterBarComponent,
    HotelListadoComponent,
    HotelListadoFiltersComponent,
    HotelListadoResultComponent,
    HotelListadoSearchComponent,
    PackageListadoComponent,
    PackageListadoFiltersComponent,
    PackageListadoResultComponent,
    PackageListadoSearchComponent,
    CheckoutComponent,
    CheckoutLoginComponent,
    CheckoutInfoComponent,
    CheckoutPagoComponent,
    CheckoutReservaComponent,
    CheckoutOrdenResumeRightSidebarComponent,
    AsistenciaHorizontalComponent,
    ShoppingCartComponent,
    PackageDetailTabAvailabilityComponent,
    Home2Component,
    Home2PrincipalComponent,
    Home2PackagesComponent,
    Home2DestiniesComponent,
    Home2ContactUsComponent,
    Home2ReasonsComponent,
    PopupReserveComponent,
    VuelosMostPopularComponent,
    PoliciesComponent,
    WhoWeAreComponent,
    TopBarLightComponent,
    HeaderLightComponent,
    PackageDetailAvailabilityItemComponent,
    PackageDetailIncludesHotelItemComponent,
    PackageDetailIncludesFlightItemComponent,
    PackageDetailAvailabilityMultiItemComponent,
    RegisterComponent,
    ServicesBoxComponent,
    AddMoreProductsComponent,
    ItemPackageComponent,
    SquareComponent,
    OrderConfirmationComponent,
    PackageDetailTabNoincludesComponent,
    FlightsListComponent,
    ItemPackageMultidestinyComponent,
    PackageMultidestinyResumeCardComponent,
    CarTableRowComponent,
    PackageListadoResultItemComponent,
    OrderCardPackageComponent,
    OrderCardFlightComponent,
    SquareupformComponent,
    CreditcardCardComponent,
    HelpTicketCardComponent,
    NoCommaPipe,
    LoginComponent,
    LoginregisterComponent,
    GuestComponent,
    PasswordresetComponent,
    UsermodalloginComponent,
    UserloginComponent,
    UserregisterComponent,
    UserlogincheckoutComponent,
    AsistenciaboxComponent,
    ImageGalleryMobileComponent,
    Home2SearchTabsComponent,
    PaymentsComponent,
    PaymentsListComponent,
    PaymentsNewComponent,
    ConfirmationResumeComponent,
    BookingCenterBarItemComponent,
    HotelSearchTabComponent,
    HotelListadoResultItemComponent,
    StarsComponent,
    HotelResumeCardComponent,
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({ hotel: hotelReducer }),
    RouterModule,
    routing,
    HttpClientModule,
    NgwWowModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ButtonsModule.forRoot(),
    PaginationModule.forRoot(),
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    // OwlModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BlockUIModule.forRoot(),
    FlightModule,
    DeviceDetectorModule.forRoot(),
    SlideshowModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
