import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-stars",
  templateUrl: "./stars.component.html",
  styleUrls: ["./stars.component.css"],
})
export class StarsComponent implements OnInit {
  @Input() numberStars: number;
  starsColored: number[] = [];
  starsEmpty: number[] = [];
  constructor() {}

  ngOnInit() {
    console.log("Stars", this.numberStars);
    for (let i = 1; i <= this.numberStars; i++) {
      this.starsColored.push(i);
    }
    for (let i = 5; i > this.numberStars; i--) {
      this.starsEmpty.push(i);
    }
    console.log("Stars Colored", this.starsColored);
    console.log("Stars Empty", this.starsEmpty);
  }
}
