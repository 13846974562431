import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-tour-search',
  templateUrl: './tour-search.component.html',
  styleUrls: ['./tour-search.component.css']
})
export class TourSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#offer1').owlCarousel({
      loop: true,
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      navigation: false,
      stopOnHover: true,
      responsive: {
        1000: {
          items: 1,
          loop: true
        }
      }
    });

    $( '#checkIn' ).datepicker({ minDate: -0, maxDate: '+3M' });
    $( '#checkOut' ).datepicker({ minDate: -0, maxDate: '+3M' });

    $( "#adultCount" ).spinner({
      min: 1
    });
    $( "#childCount" ).spinner( {
      min: 1
    });
  }
}
