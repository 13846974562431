import {Deserializable} from './deserializable.model';

export class Charter implements Deserializable {
  id: number;
  name: string;
  logo: string;

  deserialize(input: any): this {
    this.id = input.id;
    this.name = input.name;
    this.logo = input.logo;

    return this;
  }
}
