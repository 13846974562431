import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-hotel-listado-search",
  templateUrl: "./hotel-listado-search.component.html",
  styleUrls: ["./hotel-listado-search.component.css"],
})
export class HotelListadoSearchComponent implements OnInit {
  @Output() search: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onSubmitSearch() {
    this.search.emit({});
    // if (this.form.valid) {
    //   this.hasError = false;
    //   this.form.get('startDate').setValue(moment(this.form.get('startDate').value).format('YYYY-MM'));
    //   localStorage.setItem('searchFields', JSON.stringify(this.form.value));
    //   this.searchPackages.emit(this.form.value);
    //   this.showFilters = false;
    //   this.assignValuesCities();
    // } else {
    //   this.hasError = true;
    // }
  }
}
