import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { CreditCard } from "../../../../models/creditcard.model";
import { CreditCardService } from "../../../../services/CreditCard/credit-card.service";
import { UserService } from "../../../../services/User/user.service";
import { PaymentService } from "../../../../services/Payment/payment.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { formatNumber } from "@angular/common";
import { SquareComponent } from "../../../checkout/paymentforms/square/square.component";
@Component({
  selector: "app-payments-new",
  templateUrl: "./payments-new.component.html",
  styleUrls: ["./payments-new.component.css"],
})
export class PaymentsNewComponent implements OnInit {
  @ViewChild(SquareComponent) square: SquareComponent;
  @Input() orderId: number;
  @Input() orderDebt: number;
  @Input() orderCurrency: string;
  @Output() onCloseModalPayment: EventEmitter<any> = new EventEmitter();
  isLoading = false;
  formHasError = false;
  form: FormGroup;
  // formCard = new FormGroup({
  //   cardHolderName: new FormControl(null, [Validators.required]),
  // });
  cards: Array<CreditCard>;
  paymentMethodSelected: string = "";

  constructor(
    private creditCardService: CreditCardService,
    private userService: UserService,
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.getCards();
    this.form = this.formBuilder.group({
      debt: this.formBuilder.control(
        `$${formatNumber(this.orderDebt, "en-US", "1.0-0")}`
      ),
      currency: this.formBuilder.control(this.orderCurrency),
      amount: this.formBuilder.control(null, [Validators.required]),
      cardHolderName: new FormControl(null),
      cardSelected: new FormControl("new-card", [Validators.required]),
    });
  }

  async getCards() {
    this.cards = await this.creditCardService.getAll();
  }

  async onSubmitPayment() {
    const userData = await this.userService.UserDataLogin();
    this.formHasError = !this.form.valid;
    if (!this.formHasError) {
      this.isLoading = true;
      if (this.form.get("cardSelected").value === "new-card") {
        alert("enviar guardar tarjeta");
        // this.square.sendForm();
      } else if (this.form.get("cardSelected").value === "paypal") {
        alert("paypal");
      } else {
        try {
          const data = {
            order_id: this.orderId,
            amount: this.form.get("amount").value,
            source_id: this.form.get("cardSelected").value,
            currency: this.form.get("currency").value,
            // zipcode: dataPayment.zipCode, //SOLO SI SE VA A GUARDAR LA TARJETA
            client_id: userData.id,
            // cardholder_name: dataPayment.name, //SOLO SI SE VA A GUARDAR LA TARJETA
            type: "CHARGE",
            save_card: false,
          };
          const response = await this.paymentService.squareCreateCharge(data);
          console.log("RESPONSE PAGO", { response });
        } catch (error) {
          console.log(error);
          this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
        }
        // const response = await this.paymentService.squareCreateCharge(data);
        // .toPromise()
        // .then((response) => {
        //   const r: any = response;
        //   if (r.success) {
        //     localStorage.removeItem("cart");
        //     localStorage.removeItem("guest");
        //     this.router.navigate(["/confirmation", this.numOrder]);
        //   } else {
        //     this.toastr.error(
        //       "Ocurrió un error, inténtelo de nuevo más tarde."
        //     );
        //   }
        // })
        // .catch((err) => {
        //   this.toastr.error(
        //     "Ocurrió un error, inténtelo de nuevo más tarde."
        //   );
        // });
      }
    }
  }

  sendPayment() {
    alert("a");
    console.log(this.form.value);
  }

  async sendPaymentCard(data) {
    alert("aqui");
    console.log(data);
    //Al terminar de guardar la tarjeta, enviamos el pago
    // this.onSubmitPayment();

    // const userData = await this.userService.UserDataLogin();
    // const dataCC = {
    //   client_id: userData.id,
    //   card_nonce: data.nonceCard,
    //   cardholder_name: this.formCard.get('cardHolderName').value
    // }

    // await this.creditCardService.save(dataCC).toPromise()
    // .then(response => {
    //   this.loadCreditCards.emit();
    //   this.isLoading = false;
    //   this.closeModal.emit();
    // })
    // .catch((err) => {
    //   this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
    //   this.isLoading = false;
    // });
  }

  selectCard(id) {
    this.form.controls["cardSelected"].setValue(id);
  }

  handleCloseModalPayment() {
    this.onCloseModalPayment.emit();
  }
}
