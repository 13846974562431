import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlternativeReservationService {
  private apiUrl: string = environment.APIURL;
  constructor(private http: HttpClient) { }

  Save(reservation) {
    const url = this.apiUrl + 'package_light_data_reservations';
    return this.http.post(url, reservation);
  }
}
