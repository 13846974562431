import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Flight} from '../../../models/flight.model';
import {environment} from '../../../../environments/environment';
import {FlightService} from '../../../services/Flight/flight.service';

@Component({
  selector: 'app-flight-reserve-card',
  templateUrl: './flight-reserve-card.component.html',
  styleUrls: ['./flight-reserve-card.component.css']
})
export class FlightReserveCardComponent implements OnInit {
  @Input() adultsAmount: Number;
  @Input() childsAmount: Number;
  @Input() infantsAmount: Number;
  @Input() flight: Flight;
  @Input() otherFlight: Flight;
  @Output() reserve = new EventEmitter<Flight>();

  URL_WEB = environment.URL_WEB;

  constructor(private flightService: FlightService) {
  }

  ngOnInit() {
  }

  handleReserve() {
    this.reserve.emit(this.flight);
  }

  getPrice() {
    let price = 0;
    price += this.flight.priceAdult * this.flightService.getSearchParams().adults;
    price += this.flight.priceChild * this.flightService.getSearchParams().children;
    price += this.flight.priceInfant * this.flightService.getSearchParams().babies;
    
    price += this.otherFlight.priceAdult * this.flightService.getSearchParams().adults;
    price += this.otherFlight.priceChild * this.flightService.getSearchParams().children;
    price += this.otherFlight.priceInfant * this.flightService.getSearchParams().babies;
    return price;
  }

  __arrayOne(n: number): any[] {
    return Array(n);
  }
}
