import {Component, Input, OnInit} from '@angular/core';
import {CartFlight} from '../../../models/cart-flight.model';
import {environment} from '../../../../environments/environment';
import {FlightService} from '../../../services/Flight/flight.service';
import {Flight} from '../../../models/flight.model';

@Component({
  selector: 'app-flight-resume-card',
  templateUrl: './flight-resume-card.component.html',
  styleUrls: ['./flight-resume-card.component.css']
})
export class FlightResumeCardComponent implements OnInit {
  @Input() isReturn: boolean;
  @Input() flight: Flight;
  @Input() isCheckout: boolean = false;
  @Input('flightSecondary') cartFlightSecondary: CartFlight;
  URL_WEB = environment.URL_WEB;

  constructor(private flightService: FlightService) {
  }

  ngOnInit() {
  }
}
