import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { UserService } from "../User/user.service";
import { User } from "../../models/user.model";
import { Order } from "../../models/order.model";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private apiUrl: string = environment.APIURL;

  constructor(private http: HttpClient, private userService: UserService) {}

  create(data) {
    const url = `${this.apiUrl}orders`;
    return this.http.post(url, data);
  }

  async getByNumOrder(numOrder: string): Promise<any> {
    const user: User = await this.userService.getCurrent();
    const url = `${this.apiUrl}orders/${numOrder}`;
    return await this.http
      .get(url)
      .pipe(
        map((response: any) => {
          return response.data.map((order) => {
            return new Order().deserialize(order);
          });
        })
      )
      .toPromise();
  }

  async getAll(): Promise<any> {
    const user: User = await this.userService.getCurrent();
    const url = `${this.apiUrl}orders?user_id=${user.id}`;
    return await this.http
      .get(url)
      .pipe(
        map((response: any) => {
          return response.data.map((order) => {
            return new Order().deserialize(order);
          });
        })
      )
      .toPromise();
  }
}
