import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ToolsService } from '../../../services/Tools/tools.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-package-detail-tab-includes',
  templateUrl: './package-detail-tab-includes.component.html',
  styleUrls: ['./package-detail-tab-includes.component.css']
})
export class PackageDetailTabIncludesComponent implements OnInit {
  @Input() services: [];
  @Input() package: any;
  serv_includes = [];
  URL_WEB = environment.URL_WEB;
  constructor(private tools: ToolsService) { }

  ngOnInit() {
    const includes = JSON.parse(JSON.stringify(this.services));
    const numElementsIncluded = Math.round(includes.length / 2);
    this.serv_includes = this.tools.chunkArray(includes, numElementsIncluded);
  }

}
