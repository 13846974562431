import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../services/User/user.service';
import {CountryService} from '../../../services/Country/country.service';
import {StateService} from '../../../services/State/state.service';
import * as moment from 'moment';

@Component({
  selector: 'app-profile-right-sidebar',
  templateUrl: './profile-right-sidebar.component.html',
  styleUrls: ['./profile-right-sidebar.component.css']
})
export class ProfileRightSidebarComponent implements OnInit {
  form: FormGroup;
  submitting = false;

  constructor(private toastr: ToastrService, private formBuilder: FormBuilder, private userService: UserService) {
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      current_password: this.formBuilder.control(null, [Validators.required]),
      password: this.formBuilder.control(null, [Validators.required]),
      confirm_password: this.formBuilder.control(null, [Validators.required, this.matchValues('password')]),
    });
  }

  requestSave() {
    if (this.form.valid) {
      this.submitting = true;
      this.userService.updatePassword(this.form.value).then((response) => {
        console.log(response);
        this.submitting = false;
        // this.toastr.success('Datos actualizados');
      });
    }
  }

  matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value
        ? null
        : {isMatching: false};
    };
  }
}
