import {Component, OnInit} from '@angular/core';
import {TicketService} from '../../../services/Ticket/ticket.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-help-center-bar',
  templateUrl: './help-center-bar.component.html',
  styleUrls: ['./help-center-bar.component.css']
})
export class HelpCenterBarComponent implements OnInit {
  tickets: Array<any> = [];
  ticketsActive: Array<any> = [];
  ticketsResolved: Array<any> = [];
  categories: Array<any>;
  subcategories: Array<any>;
  ticketForm: FormGroup;
  ticketSubmiting: boolean;

  constructor(private toastr: ToastrService, private ticketService: TicketService, private formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.ticketForm = this.formBuilder.group({
      category_id: this.formBuilder.control(null, [Validators.required]),
      subcategory_id: this.formBuilder.control(null, [Validators.required]),
      num_order: this.formBuilder.control(null, [Validators.required]),
      num_confirmacion: this.formBuilder.control(null, []),
      description: this.formBuilder.control(null, [Validators.required]),
    });
    this.ticketService.getCategories().then((categories) => {
      this.categories = categories;
    });

    this.updateTickets();
  }

  async updateTickets() {
    this.tickets = await this.ticketService.getAll();
    this.ticketsActive = this.tickets.filter((ticket) => {
      return ticket.status_id === 1;
    });
    this.ticketsResolved = this.tickets.filter((ticket) => {
      return ticket.status_id === 2;
    });
  }

  updateSubcategories() {
    this.ticketService.getSubcategories(this.ticketForm.get('category_id').value).then((subcategories) => {
      this.subcategories = subcategories;
      this.ticketForm.get('subcategory_id').setValue(null);
    });
  }

  async submitTicket(formRef) {
    if (this.ticketForm.valid && !this.ticketSubmiting) {
      this.ticketSubmiting = true;
      this.ticketService.createTicket(this.ticketForm.value).then((data) => {
        formRef.reset();
        this.toastr.success('Ticket enviado.');
        this.ticketSubmiting = false;
        this.updateTickets();
      });
    } else {
      this.toastr.error('Rellena los campos obligatorios.');
    }
  }
}
