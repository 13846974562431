import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-renta-top-destination',
  templateUrl: './renta-top-destination.component.html',
  styleUrls: ['./renta-top-destination.component.css']
})
export class RentaTopDestinationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
