import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../services/User/user.service';
import {User} from '../../../models/user.model';
import {DomSanitizer} from '@angular/platform-browser';
import {ExchangerateService} from '../../../services/ExchangeRate/exchangerate.service';
import {CartService} from '../../../services/Cart/cart.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';

declare var $: any;

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  @ViewChild('loginModal') public loginModal;
  @ViewChild('signUpModal') public signUpModal;
  
  exchangeRate: number = 1;
  isLoading: boolean = true;

  constructor(
    public cartService: CartService,
    public exchangerateService: ExchangerateService,
    private translate: TranslateService,
    private userService: UserService,
    private _sanitizer: DomSanitizer,
    private modalService: BsModalService
    ) {
  }

  currency: String = 'USD';
  language: String = 'Español (MX)';
  flagSrc: String = 'mexico.jpeg';
  isLogged = false;
  userData;
  user: User;
  avatar: any;
  firstName: String = '';

  async ngOnInit() {
    $('.selectpicker').selectpicker({
      style: 'custom-select-button'
    });

    this.cartService.loadCart();
    this.userData = await this.userService.UserDataLogin();
    if (this.userData !== null && this.userData !== undefined) {
      this.isLogged = true;
      this.firstName = this.userData.name.split(" ")[0];
    }

    this.avatar = 'assets/images/default_avatar.png';
    if(this.userData !== null){
      this.user = await this.userService.getCurrent();
      if(this.user !== null && this.user.avatar !== null){
        this.avatar = this._sanitizer.bypassSecurityTrustResourceUrl(this.user.avatar);
      }
    }
    

    let lang = navigator.language;

    if (lang.startsWith('es-')) {
      lang = 'es';
    }

    this.changeLang(lang);

    if (lang === 'es') {
      this.changeCurrency('MXN');
    } else {
      this.changeCurrency('USD');
    }

    this.userService.updated$.subscribe(async () => {
      this.user = await this.userService.getCurrent();
      this.avatar = this._sanitizer.bypassSecurityTrustResourceUrl(this.user.avatar);
    });
  }

  async logout() {
    await this.userService.Logout();
  }

  changeLang(lang) {
    if (lang === 'es') {
      this.language = 'Español (MX)';
      this.flagSrc = 'mexico.jpeg';
    } else if (lang === 'en') {
      this.language = 'English(US)';
      this.flagSrc = 'flag_usa.png';
    }

    this.translate.use(lang);
  }

  async changeCurrency(currency) {
    this.currency = currency;
    localStorage.setItem('currency', currency);
    await this.exchangerateService.get(currency);
  }

  closeModalLogin(){
    this.loginModal.hide();
  }

  closeModalSignUp(){
    this.signUpModal.hide();
  }
}
