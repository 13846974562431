import { Component, OnInit, Input } from "@angular/core";
import { CartService } from "../../../services/Cart/cart.service";
import { environment } from "../../../../environments/environment";
import { CartFlight } from "../../../models/cart-flight.model";
import { ExchangerateService } from "../../../services/ExchangeRate/exchangerate.service";
import { PRODUCT_TYPES } from "../../../constants/productTypes";
@Component({
  selector: "app-checkout-orden-resume-right-sidebar",
  templateUrl: "./checkout-orden-resume-right-sidebar.component.html",
  styleUrls: ["./checkout-orden-resume-right-sidebar.component.css"],
})
export class CheckoutOrdenResumeRightSidebarComponent implements OnInit {
  @Input() isCheckout: Boolean = true;
  @Input() isConfirmation: Boolean = false;
  URL_WEB = environment.URL_WEB;
  cart: any;
  imageBanner: string = "";
  bookWithPrice = 0;
  payBook = false;
  PRODUCT_TYPES = PRODUCT_TYPES;
  constructor(
    public exchangerateService: ExchangerateService,
    private cartService: CartService
  ) {}

  async ngOnInit() {
    await this.loadCart();
  }

  async loadCart() {
    const localCart = await localStorage.getItem("cart");
    if (localCart !== null && localCart !== undefined) {
      this.cart = JSON.parse(localCart);
      this.setBannerImage();
      if (this.cart.items.length === 0) {
        this.cart = null;
      }
      if (
        this.cart.items[0].product_type === PRODUCT_TYPES.PACKAGES &&
        this.cart.items[0].availability.book_with !== null
      ) {
        this.calculateBookWithPrice();
      }
    }
  }

  calculateBookWithPrice() {
    const { book_with, calculatedPrice } = this.cart.items[0].availability;
    this.bookWithPrice = this.cartService.calculateBookPrice(
      book_with,
      calculatedPrice
    );
  }

  setBannerImage() {
    if (this.cart.items[0].product_type === PRODUCT_TYPES.PACKAGES) {
      this.imageBanner = this.cart.items[0].package.image;
    }
  }

  castToFlight(item): CartFlight {
    return item as CartFlight;
  }
}
