import { Component, OnInit, Input } from "@angular/core";
import { UserService } from "../../../../services/User/user.service";
import { PaymentService } from "../../../../services/Payment/payment.service";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.css"],
})
export class PaymentsComponent implements OnInit {
  @Input() orderId;
  payments: any;
  constructor(
    private paymentService: PaymentService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getPayments();
  }

  async getPayments() {
    this.payments = await this.paymentService.list(this.orderId);
  }
}
