import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-usermodallogin',
  templateUrl: './usermodallogin.component.html',
  styleUrls: ['./usermodallogin.component.css']
})
export class UsermodalloginComponent implements OnInit {
  @Output() closeModal: EventEmitter<any> =  new EventEmitter();
  @Input() viewDefault: string = '';
  @Input() isCheckout: boolean = false;
  view: string = ''
  constructor() { }

  ngOnInit() {
    this.view = this.viewDefault
  }

  changeView(view){
    this.view = view;
  }

  handleCloseModal(){
    this.view = this.viewDefault
    this.closeModal.emit();
  }

}
