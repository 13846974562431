import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgwWowService} from 'ngx-wow';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private wowSubscription: Subscription;

  constructor(private router: Router, private translate: TranslateService, private wowService: NgwWowService) {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      // Reload WoW animations when done navigating to page,
      // but you are free to call it whenever/wherever you like
      // this.wowService.init();
    });
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');

    let lang = navigator.language;

    if (lang.startsWith('es-')) {
      lang = 'es';
    }

    translate.use(lang);
    console.log(`El idioma del navegador es: ${navigator.language}`);
  }

  ngOnInit() {
    // you can subscribe to WOW observable to react when an element is revealed
    // this.wowSubscription = this.wowService.itemRevealed$.subscribe(
    //   (item: HTMLElement) => {
    //     // do whatever you want with revealed element
    //   });
  }

  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    // this.wowSubscription.unsubscribe();
  }
}
