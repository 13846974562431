import {Deserializable} from './deserializable.model';
import {City} from './city.model';
import {FlightConnection} from './flightConnection.model';
import {Charter} from './charter.model';

export class Flight implements Deserializable {
  id: number;
  description: number;
  type: string;
  direction: string;
  refundable: string;
  cityDeparture: City;
  cityArrival: City;
  hours: number;
  costAdult: number;
  costChild: number;
  costInfant: number;
  priceAdult: number;
  priceChild: number;
  priceInfant: number;
  connections: FlightConnection[];
  firstConnection: FlightConnection;
  lastConnection: FlightConnection;
  charter: Charter;

  deserialize(input: any): this {
    this.id = input.id;
    this.type = input.flightType;
    this.direction = input.direction;
    this.hours = input.hours;
    this.refundable = input.refundable;
    this.description = input.description;
    this.costAdult = input.directions[0].costAdult;
    this.costChild = input.directions[0].costChild;
    this.costInfant = input.directions[0].costInfant;
    this.priceAdult = input.directions[0].priceAdult;
    this.priceChild = input.directions[0].priceChild;
    this.priceInfant = input.directions[0].priceInfant;
    this.cityDeparture = new City().deserialize(input.city_from);
    this.cityArrival = new City().deserialize(input.city_to);
    this.connections = input.directions[0].transits.map((connection: any) => {
      return new FlightConnection().deserialize(connection);
    });
    this.firstConnection = this.connections[0];
    this.lastConnection = this.connections[this.connections.length - 1];
    this.charter = new Charter().deserialize(input.charter);

    return this;
  }
}
