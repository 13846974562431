import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hot-deals',
  templateUrl: './hot-deals.component.html',
  styleUrls: ['./hot-deals.component.css']
})
export class HotDealsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
