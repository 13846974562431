import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfacCategoryService {
  private apiUrl: string = environment.APIURL;

  constructor(private http: HttpClient) {
  }

  getAll() {
    const url = `${this.apiUrl}ofac_categories`;
    return this.http.get(url).pipe(map((response: any) => {
      return response.data;
    })).toPromise();
  }
}
