import { Component, OnInit } from "@angular/core";
import { CartService } from "../../services/Cart/cart.service";
import { ExchangerateService } from "../../services/ExchangeRate/exchangerate.service";
import { PRODUCT_TYPES } from "../../constants/productTypes";
@Component({
  selector: "app-shopping-cart",
  templateUrl: "./shopping-cart.component.html",
  styleUrls: ["./shopping-cart.component.css"],
})
export class ShoppingCartComponent implements OnInit {
  cart: any;
  PRODUCT_TYPES = PRODUCT_TYPES;
  constructor(
    public exchangerateService: ExchangerateService,
    private cartService: CartService
  ) {}

  async ngOnInit() {
    await this.loadCart();
  }

  openCheckout() {
    document.location.href = "/checkout";
  }

  async loadCart() {
    this.cart = await this.cartService.loadCart();
    if (this.cart !== null) {
      if (this.cart.items.length === 0) {
        this.cart = null;
      }
    }
  }

  async clearCart() {
    await this.cartService.clearCart();
    this.cart = null;
  }

  async removeItem(index) {
    await this.cartService.removeItem(index);
    this.loadCart();
  }
}
