import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CartFlight} from '../../../models/cart-flight.model';
import {Flight} from '../../../models/flight.model';
import {environment} from '../../../../environments/environment';
import {CartService} from '../../../services/Cart/cart.service';
import {BsModalService} from 'ngx-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {FlightService} from '../../../services/Flight/flight.service';
import {Router} from '@angular/router';

interface FlightsChunk {
  departure: Flight;
  return: Array<Flight>;
}

@Component({
  selector: 'app-flight-builder',
  templateUrl: './flight-builder.component.html',
  styleUrls: ['./flight-builder.component.css']
})
export class FlightBuilderComponent implements OnInit, OnChanges {
  @Input('flights') flights: Array<FlightsChunk>;
  departureCartFlight = new CartFlight();
  returnCartFlight = new CartFlight();
  URL_WEB = environment.URL_WEB;
  stepIndex = 0;

  selectedChunk: FlightsChunk;

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private cartService: CartService,
    private flightService: FlightService,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flights) {
      console.log('vuelos')
      console.log(this.flights);
      this.stepIndex = 0;
      this.departureCartFlight = new CartFlight();
      this.returnCartFlight = new CartFlight();
    }
  }

  selectDeparture(flight: Flight, chunk: FlightsChunk) {
    this.departureCartFlight.product_id = flight.id;
    this.departureCartFlight.flight = flight;
    this.departureCartFlight.type = 'Ida';
    const {adults, children, babies} = this.flightService.getSearchParams();
    this.departureCartFlight.calculateTotal(adults, children, babies);
    this.selectedChunk = chunk;
    this.stepIndex = 1;
  }

  selectReturn(flight: Flight) {
    this.returnCartFlight.product_id = flight.id;
    this.returnCartFlight.flight = flight;
    this.returnCartFlight.type = 'Retorno';
    const {adults, children, babies} = this.flightService.getSearchParams();
    this.returnCartFlight.calculateTotal(adults, children, babies);
    this.stepIndex = 2;

    this.reserve();
  }

  reserve() {
    this.cartService.addItem(this.departureCartFlight);
    this.cartService.addItem(this.returnCartFlight);
    this.router.navigate(['/cart']);
  }
}
