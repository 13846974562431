import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-center-bar',
  templateUrl: './overview-center-bar.component.html',
  styleUrls: ['./overview-center-bar.component.css']
})
export class OverviewCenterBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
