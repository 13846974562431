import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/User/user.service';
import * as moment from 'moment';
import {User} from '../../../models/user.model';
import {CountryService} from '../../../services/Country/country.service';
import {StateService} from '../../../services/State/state.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-profile-center-bar',
  templateUrl: './profile-center-bar.component.html',
  styleUrls: ['./profile-center-bar.component.css']
})
export class ProfileCenterBarComponent implements OnInit {
  form: FormGroup;
  countries: Array<any>;
  states: Array<any>;
  submitting = false;

  constructor(private toastr: ToastrService, private formBuilder: FormBuilder, private userService: UserService, private countryService: CountryService, private stateService: StateService) {
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      name: this.formBuilder.control(null, [Validators.required]),
      last_name: this.formBuilder.control(null, [Validators.required]),
      email: this.formBuilder.control(null),
      phone: this.formBuilder.control(null, [Validators.required]),
      birthdate_day: this.formBuilder.control(null, [Validators.required]),
      birthdate_month: this.formBuilder.control(null, [Validators.required]),
      birthdate_year: this.formBuilder.control(null, [Validators.required]),
      country_id: this.formBuilder.control(null, [Validators.required]),
      state_id: this.formBuilder.control(null, [Validators.required]),
      city: this.formBuilder.control(null, [Validators.required]),
      address: this.formBuilder.control(null, [Validators.required]),
      zipcode: this.formBuilder.control(null, [Validators.required]),
      avatar: this.formBuilder.control(null, [Validators.required]),
    });

    this.countries = await this.countryService.getAll();
    this.states = await this.stateService.getAll();

    const user = await this.userService.getCurrent();

    this.form.get('name').setValue(user.name);
    this.form.get('last_name').setValue(user.last_name);
    this.form.get('email').setValue(user.email);
    this.form.get('phone').setValue(user.phone);
    this.form.get('country_id').setValue(user.country_id);
    this.form.get('state_id').setValue(user.state_id);
    this.form.get('city').setValue(user.city);
    this.form.get('address').setValue(user.address);
    this.form.get('zipcode').setValue(user.zipcode);
    // this.form.get('avatar').setValue(user.avatar);

    if (user.birthdate) {
      const birthdate = moment(user.birthdate);
      this.form.get('birthdate_day').setValue(birthdate.date());
      this.form.get('birthdate_month').setValue(birthdate.month() + 1);
      this.form.get('birthdate_year').setValue(birthdate.year());
    }
  }

  handleAvatarUpload($event) {
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.form.get('avatar').setValue(myReader.result);
    };
    myReader.readAsDataURL(file);
  }

  requestSave() {
    if (this.form.valid) {
      const params = {
        birthdate: `${this.form.value.birthdate_year}-${this.form.value.birthdate_month}-${this.form.value.birthdate_day}`,
        name: this.form.value.name,
        last_name: this.form.value.last_name,
        phone: this.form.value.phone,
        country_id: this.form.value.country_id,
        state_id: this.form.value.state_id,
        city: this.form.value.city,
        avatar: this.form.value.avatar,
        zipcode: this.form.value.zipcode,
        address: this.form.value.address,
      };

      this.submitting = true;
      this.userService.update(params).then((response) => {
        this.submitting = false;
        this.toastr.success('Datos actualizados');
      });
    }
  }
}
