import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-vuelos',
  templateUrl: './vuelos.component.html',
  styleUrls: ['./vuelos.component.css']
})
export class VuelosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#offer1').owlCarousel({
      loop: true,
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      navigation: false,
      stopOnHover: true,
      responsive: {
        1000: {
          items: 1,
          loop: true
        }
      }
    });
  }
}
