import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-tour-listado-filters',
  templateUrl: './tour-listado-filters.component.html',
  styleUrls: ['./tour-listado-filters.component.css']
})
export class TourListadoFiltersComponent implements OnInit {

  categorias: any = [];
  intereses: any = [];
  servicios: any = [];
  idiomas: any = [];
  constructor() { }

  ngOnInit() {
    this.llenarInformacion();
    $( '#price-range' ).slider({
      range: true,
      min: 0,
      max: 100,
      values: [ 0, 50 ],
      slide: function( event, ui ) {
      $( '#amount' ).val( '$ ' + ui.values[ 0 ] + ' - $ ' + ui.values[ 1 ] );
      }
    });
    $( '#amount' ).val( '$ ' + $( '#price-range' ).slider( 'values', 0 ) +
      ' - $ ' + $( '#price-range' ).slider( 'values', 1 ) );
  }

  llenarInformacion(){
    this.categorias = [{nombre: 'Tours de medio dia'},{nombre: 'Tours de medio dia'},{nombre: 'Tours de medio dia'}];
    this.intereses = [{nombre: 'Opcion 1', fa: 'life-ring'},{nombre: 'Opcion 2', fa: 'life-ring'},{nombre: 'Opcion 3', fa: 'life-ring'}]
    this.servicios = [{nombre: 'Opcion 1', fa: 'fighter-jet'},{nombre: 'Opcion 2', fa: 'fighter-jet'},{nombre: 'Opcion 3', fa: 'fighter-jet'}]
    this.idiomas = [{nombre: 'Español'},{nombre: 'Inglés'},{nombre: 'Portgués'},{nombre: 'Italiano'}]
  }

}
