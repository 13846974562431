import {Deserializable} from './deserializable.model';

export class Airport implements Deserializable {
  id: number;
  name: string;
  code: string;

  deserialize(input: any): this {
    this.id = input.id;
    this.name = input.name;
    this.code = input.code;

    return this;
  }
}
