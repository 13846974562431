import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlternativePackagesService {
  private apiUrl: string = environment.APIURL;
  constructor(private http: HttpClient) { }

  List() {
    const url = this.apiUrl + 'package_lights';
    return this.http.get(url);
  }

  ListDestinies() {
    const url = this.apiUrl + 'popular_package_lights';
    return this.http.get(url);
  }
}
