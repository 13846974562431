import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-search-rentas',
  templateUrl: './search-rentas.component.html',
  styleUrls: ['./search-rentas.component.css']
})
export class SearchRentasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
