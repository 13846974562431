import { Component, OnInit } from '@angular/core';
import { AlternativePackagesService } from '../../../services/AlternativePackages/alternative-packages.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-home2-packages',
  templateUrl: './home2-packages.component.html',
  styleUrls: ['./home2-packages.component.css']
})
export class Home2PackagesComponent implements OnInit {
  packages: any;
  isLoading: Boolean = false;
  URL_WEB = environment.URL_WEB;
  constructor(private packagesService: AlternativePackagesService, private toastr: ToastrService) { }

  async ngOnInit() {
    await this.loadPackages();
  }

  loadPackages() {
    this.isLoading = true;
    this.packagesService.List().toPromise().then(data => {
      this.isLoading = false;
      const r: any = data;
      if (r.success) {
        this.packages = r.data;
        const t = this;
        setTimeout(() => {
          t.createCarrousel();
        }, 1000);
      } else {
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      }
    })
    .catch((err) => {
      this.isLoading = false;
      this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
    });
  }

  createCarrousel() {
    $('#post-list').owlCarousel({
      loop: true,
      margin: 30,
      responsiveClass: true,
      autoHeight: false,
      autoHeightClass: 'owl-height',
      autoplay: false,
      autoplayTimeout: 5000,
      navigation: false,
      stagePadding: 20,
      stopOnHover: true,
      responsive: {
        0: {
          items: 1,
          loop: true
        },
        600: {
          items: 2,
          loop: true
        },
        1000: {
          items: 4,
          loop: true
        }
      }
    });
    $('.open-popup-link').magnificPopup({
      type: 'inline',
      midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't
    });
  }

}
