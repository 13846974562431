import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/User/user.service";
import { ActivatedRoute } from "@angular/router";
import { OrderService } from "../../services/Order/order.service";
import { Order } from "../../models/order.model";

@Component({
  selector: "app-order-confirmation",
  templateUrl: "./order-confirmation.component.html",
  styleUrls: ["./order-confirmation.component.css"],
})
export class OrderConfirmationComponent implements OnInit {
  private sub: any;
  dataConfirmation: Order;
  activeTab = 4;
  isLogged = false;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {}

  ngOnInit() {
    this.isLogged = this.userService.isLogged;
    this.sub = this.route.params.subscribe((params) => {
      this.orderService.getByNumOrder(params["numOrder"]).then((order) => {
        this.dataConfirmation = order[0];
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
