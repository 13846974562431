import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExchangerateService {
  currentExchangeRate = 1;
  currentCurrency = 'USD';
  constructor() { }

  async getCurrent()
  {
    let exchange = JSON.parse(localStorage.getItem('exchangeRate'));
    if(exchange !== null && exchange !== undefined){
      return parseFloat(exchange);
    }
    return 1;
  }

  async get(currency)
  {
    this.currentCurrency = currency;
    let amount = 1;
    if(currency !== 'USD'){
      amount = 18.20;
    }
    
    localStorage.setItem('exchangeRate', amount+"");
    this.currentExchangeRate = amount;
  }

  async getCurrency(){
    let currency = localStorage.getItem('currency');
    if(currency !== null && currency !== undefined){
      return currency;
    }
    return '';
  }
}
