import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { UserService } from '../../../../services/User/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @Output() changeView: EventEmitter<any> =  new EventEmitter();
  @Input() isCheckout: boolean = false;
  isLoading = false;
  formRegHasError = false;
  samePassword = true;
  formReg = new FormGroup({
    first_name: new FormControl(null, [Validators.required]),
    last_name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required, Validators.minLength(8),]),
    confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(8),]),
    checkTerms: new FormControl(null,[Validators.required])
  });
  
  constructor(private toastr: ToastrService, private userService: UserService, private translate: TranslateService) { }

  ngOnInit() {
  }

  handleChangeView(view) {
    this.changeView.emit(view);
  }

  onSubmitRegister() {
    this.formRegHasError = !this.formReg.valid;
    this.samePassword = this.formReg.get('password').value === this.formReg.get('confirmPassword').value
    
    if(!this.formRegHasError && this.samePassword){
      this.sendRegForm();
    }
  }

  async sendRegForm(){
    let msgSuccess;
    let errorEmail;
    await this.translate.get('RegistroExitoso').toPromise().then(resp => msgSuccess = resp)
    await this.translate.get('EmailExiste').toPromise().then(resp => errorEmail = resp)
    this.isLoading = true;
    this.userService.Register(this.formReg.value).toPromise().then(data => {
      this.isLoading = false;
      const response: any = data;
      if (response.success) {
        this.toastr.success(msgSuccess);
        localStorage.removeItem('guest');
        localStorage.setItem("user", JSON.stringify(response.data));
        if(this.isCheckout){
          document.location.href = "/checkout";
        }else{
          document.location.href = "/";
        }
      } else {
        this.toastr.error(response.message);
      }
    })
    .catch((err) => {
      this.isLoading = false;
      const msgError = err.error.message === 'email_exists' ? errorEmail : err.message
      this.toastr.error(msgError);
    });

  }

}
