import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-car-detail-tabs',
  templateUrl: './car-detail-tabs.component.html',
  styleUrls: ['./car-detail-tabs.component.css']
})
export class CarDetailTabsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $( '#date_pickup' ).datepicker({ minDate: -0, maxDate: '+3M' });
  }

}
