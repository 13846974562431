import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-renta-last-minute-deals',
  templateUrl: './renta-last-minute-deals.component.html',
  styleUrls: ['./renta-last-minute-deals.component.css']
})
export class RentaLastMinuteDealsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
