import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { PackageService } from '../../../services/Package/package.service';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-package-detail-tab-availability',
  templateUrl: './package-detail-tab-availability.component.html',
  styleUrls: ['./package-detail-tab-availability.component.css']
})
export class PackageDetailTabAvailabilityComponent implements OnInit {
  @Output() setAvailabilities: EventEmitter<any> =  new EventEmitter();
  @Input() package: any;
  @Input() packageId: number;
  @Input() is_multiple_destiny: number;
  availabilitiesItems: any;
  hotel: any;
  packageSelected: any;
  URL_WEB = environment.URL_WEB;
  isLoading: Boolean = false;
  rooms: any = [];
  minDateFrom: Date;
  minDateTo: Date;
  bsConfig = { dateInputFormat: 'YYYY-MM', containerClass: 'theme-blue', showWeekNumbers: false };
  form = new FormGroup({
    packageId: new FormControl(null),
    startDate: new FormControl(null, [Validators.required]),
    adults: new FormControl(1),
    children: new FormControl(0),
  });
  constructor(private packagesService: PackageService, private toastr: ToastrService) {
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };     
    container.setViewMode('month');
  }

  ngOnInit() {
    this.packageSelected = this.package;
    const searchFiltersParams = JSON.parse(localStorage.getItem('searchFields'));
    if (searchFiltersParams) {
      this.form.get('packageId').setValue(this.packageId);
      this.form.get('startDate').setValue(moment(searchFiltersParams.startDate).toDate());
      this.form.get('adults').setValue(searchFiltersParams.adults);
      this.form.get('children').setValue(searchFiltersParams.children);
      this.minDateFrom = moment().toDate();
      this.sendForm();
    } else {
      this.form.get('packageId').setValue(this.packageId);
      this.form.get('startDate').setValue(moment().toDate());
      this.form.get('adults').setValue(1);
      this.form.get('children').setValue(0);
      this.minDateFrom = moment().toDate();
      this.sendForm();
    }
  }

  // startChanged(e) {
  //   if (e != null) {
  //     const prevEndDate = moment(e).add(1, 'days');
  //     if (this.form.get('startDate').value < e || this.form.get('endDate').value === '') {
  //       this.form.get('endDate').setValue(prevEndDate.toDate());
  //       // let ele: HTMLElement = document.getElementById('ll') as HTMLElement;
  //       // ele.open();
  //     }
  //     this.minDateTo = prevEndDate.toDate();
  //   }
  // }

  sendForm() {
    if (this.form.valid) {
      this.rooms = [];
      const availabilities = { items: [], hotels: [], flights: [], minPrice: 0};
      this.setAvailabilities.emit(availabilities);
      this.isLoading = true;
      this.form.get('startDate').setValue(moment(this.form.get('startDate').value).format('YYYY-MM'));
      this.packagesService.searchRooms(this.form.value).toPromise().then(data => {
        this.isLoading = false;
        const t = this;
        const r: any = data;
        if (r.success) {
          t.hotel = r.data.hotel;
          if (t.is_multiple_destiny === 1) {
            t.getRoomsData(r.data.availabilities);
          } else {
            r.data.availabilities.map(av => {
              const avItem = {
                id: av,
                package_id: av.package_id,
                date_start: av.date_start,
                date_end: av.date_end,
                book_with: av.book_with,
                calculatedPrice: t.calculateAveragePerNight(av.single, av.one_child)
              };
              availabilities.items.push(avItem);
            });
            availabilities.items.sort((a, b) => {
              return a.calculatedPrice - b.calculatedPrice;
            });
            t.availabilitiesItems = availabilities.items;
          }
          availabilities.hotels = t.getHotels(r.data);
          availabilities.flights = t.getFlights(r.data.availabilities);
          if (t.is_multiple_destiny === 1) {
            availabilities.minPrice = (t.rooms.length > 0) ? t.rooms[0].calculatedPrice : 0;
          } else {
            availabilities.minPrice = (t.availabilitiesItems.length > 0) ? t.availabilitiesItems[0].calculatedPrice : 0;
          }
          t.setAvailabilities.emit(availabilities);
        } else {
          this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
        }
      })
      .catch((err) => {
        this.isLoading = false;
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      });
    }
  }

  getRoomsData(availabilities) {
    availabilities.map(function(ava) {
      ava.rooms.map(function(room) {
        room.date_start = ava.date_start;
        room.date_end = ava.date_end;
        room.calculatedPrice = this.calculateAveragePerNight(room.pivot.adult, room.pivot.one_child);
        this.rooms.push(room);
      });
    });

    this.rooms.sort((a, b) => {
      return a.calculatedPrice - b.calculatedPrice;
    });
  }

  getHotels(data) {
    let hotels = [];
    if (this.is_multiple_destiny === 1) { // es un destino
      hotels.push(data.hotel);
    } else {
      data.itinerary_list.map(itinerary => {
        const found = hotels.some(el => el.id === itinerary.hotel.id);
        if (!found) {
          hotels.push(itinerary.hotel);
        }
      });
    }
    return hotels;
  }

  getFlights(availabilities) {
    let flights = [];
    availabilities.map(av => {
      av.flights.map(f => { flights.push(f); });
    });
    return flights;
  }

  calculateAveragePerNight(adult, child) {
    const priceAdults = parseFloat(adult) * this.form.get('adults').value;
    const priceChildren = parseFloat(child) * this.form.get('children').value;
    return priceAdults + priceChildren;
  }
}
