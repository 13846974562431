import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CartService } from '../../../services/Cart/cart.service';

declare var $: any;

@Component({
  selector: 'app-package-detail-availability-item',
  templateUrl: './package-detail-availability-item.component.html',
  styleUrls: ['./package-detail-availability-item.component.css']
})
export class PackageDetailAvailabilityItemComponent implements OnInit {
  @Input() package;
  @Input() room;
  @Input() adults;
  @Input() children;
  URL_WEB = environment.URL_WEB;
  constructor(private cartService: CartService) { }

  ngOnInit() {
    // setTimeout(() => {
    //   this.createReserveLinkPopUp();
    // }, 1000);
  }

  createReserveLinkPopUp() {
    $('.open-popup-link').magnificPopup({
      type: 'inline',
      midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't
    });
  }

  addItemToCart(item){
    const itemToAdd = {
      ...item,
      multidestiny: false,
    }

    this.cartService.addItem(itemToAdd);
  }

}
