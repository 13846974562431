import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-hoteles',
  templateUrl: './search-hoteles.component.html',
  styleUrls: ['./search-hoteles.component.css']
})
export class SearchHotelesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
