import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Order } from "../../../models/order.model";
import { environment } from "../../../../environments/environment";
import { orderStatus } from "../../../constants/orderStatus";
@Component({
  selector: "app-order-card-package",
  templateUrl: "./order-card-package.component.html",
  styleUrls: ["./order-card-package.component.css"],
})
export class OrderCardPackageComponent implements OnInit {
  @Input() order: Order;
  @Output() openModal: EventEmitter<any> = new EventEmitter();

  URL_WEB = environment.URL_WEB;
  ORDER_STATUS = orderStatus;
  package: any;
  constructor() {}

  ngOnInit() {
    let cities = [];
    this.order.details[0].product_data.itinerary.map((item) => {
      cities.push({ city: { nombre: item.nombre } });
    });

    this.package = {
      jsonData: true,
      package: {
        category: this.order.details[0].product_data.category,
        included_services: this.order.details[0].product_data.included_services,
        itinerary_list: cities,
        nights: this.order.details[0].product_data.nights,
      },
      availability: this.order.details[0].product_data.availabilities[0],
    };
  }

  openPaymentsModal() {
    this.openModal.emit({ orderId: this.order.id });
  }
}
