import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '../../../services/Subscription/subscription.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });
  isLoading: Boolean = false;
  constructor(private subscriptionService: SubscriptionService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  onSubmit() {
    this.isLoading = true;
    this.subscriptionService.Save(this.form.value).toPromise().then(data => {
      this.isLoading = false;
      const response: any = data;
      if (response.success) {
        this.toastr.success('Su correo ha sido registrado exitosamente.');
      } else {
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      }
    })
    .catch((err) => {
      this.isLoading = false;
      this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
    });
  }

}
