import { Deserializable } from "./deserializable.model";

export class Order implements Deserializable {
  id: number;
  num_order: string;
  cost: number;
  subtotal: number;
  total: number;
  debt: number;
  paid: number;
  details: any;
  items: any;
  created_at: string;
  status: number;
  is_reserved: number;
  currency: string;
  exchange_rate: number;

  deserialize(input: any): this {
    this.id = input.id;
    this.num_order = input.num_order;
    this.cost = input.cost;
    this.created_at = input.created_at;
    this.subtotal =
      input.currency !== "USD"
        ? input.subtotal * input.exchange_rate
        : input.subtotal;
    this.total =
      input.currency !== "USD"
        ? input.total * input.exchange_rate
        : input.total;
    this.debt =
      input.currency !== "USD" ? input.debt * input.exchange_rate : input.debt;
    this.paid = this.total - this.debt;
    this.status = input.orderstatus_id;
    this.is_reserved = input.is_reserved;
    this.currency = input.currency;
    this.exchange_rate = input.exchange_rate;

    this.details = input.details.map((detail) => {
      const productType = detail.product_type_id;
      const productData = JSON.parse(detail.product_data);
      switch (productType) {
        case 1:
          return {
            product_type: detail.product_type_id,
            num_confirmations: detail.num_confirmations,
            product_data: JSON.parse(detail.product_data),
          };
        case 7:
          return {
            adults: detail.adults,
            availability: productData.availabilities[0],
            babies: detail.babies,
            children: detail.children,
            cost: detail.cost,
            discount: detail.discount,
            hotel: productData.hotel,
            multidestiny: productData.is_multiple_destiny === 2,
            package: productData,
            price: detail.total,
            product_id: detail.product_id,
            subproduct_id: productData.availabilities[0].id,
            subtotal: detail.subtotal,
            product_type: detail.product_type_id,
            num_confirmations: detail.num_confirmations,
            product_data: productData,
          };
      }
    });
    return this;
  }
}
