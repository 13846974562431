import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-sponsors',
  templateUrl: './car-sponsors.component.html',
  styleUrls: ['./car-sponsors.component.css']
})
export class CarSponsorsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
