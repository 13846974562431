import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CityService } from "../../../services/City/city.service";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from "@angular/forms";
import * as moment from "moment";

declare var $: any;
@Component({
  selector: "app-package-search",
  templateUrl: "./package-search.component.html",
  styleUrls: ["./package-search.component.css"],
})
export class PackageSearchComponent implements OnInit {
  @ViewChild("dpe") fechaFinRef: ElementRef;
  citiesFrom: any = [];
  citiesTo: any = [];
  // public citiesFromArray: string[] = [];
  // public citiesToArray: string[] = [];
  public minDateFrom: Date;
  public minDateTo: Date;
  public bsConfig = {
    dateInputFormat: "YYYY-MM",
    containerClass: "theme-blue",
    showWeekNumbers: false,
  };
  hasError = false;
  form = new FormGroup({
    departureCity: new FormControl(null, [Validators.required]),
    destinyCity: new FormControl(null, [Validators.required]),
    startDate: new FormControl(null, [Validators.required]),
    adults: new FormControl(1),
    children: new FormControl(0),
    babies: new FormControl(0),
  });
  constructor(
    private toastr: ToastrService,
    private cityService: CityService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode("month");
  }

  ngOnInit() {
    localStorage.setItem("searchFields", null);
    this.minDateFrom = moment().toDate();
    this.getCities("from");
  }

  getCities(target, from = null) {
    this.cityService
      .get(target, "package", from)
      .toPromise()
      .then((data) => {
        const r: any = data;
        if (r.success) {
          if (target === "from") {
            this.citiesFrom = r.data;
            setTimeout(() => {
              $(".myselectfrom").selectpicker();
            }, 1000);
          } else if (target === "to") {
            this.citiesTo = r.data;
            setTimeout(() => {
              $(".myselectto").selectpicker();
            }, 1000);
          }
        } else {
          this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
        }
      })
      .catch((err) => {
        this.toastr.error(
          "Ocurrió un error al cargar las ciudades, inténtelo de nuevo más tarde."
        );
      });
  }

  onChangeCityFrom(deviceValue) {
    this.getCities("to", deviceValue);
  }

  onSubmitSearch() {
    if (this.form.valid) {
      this.hasError = false;
      this.form
        .get("startDate")
        .setValue(moment(this.form.get("startDate").value).format("YYYY-MM"));
      localStorage.setItem("searchFields", JSON.stringify(this.form.value));
      this.router.navigate(["/packages/list"]);
    } else {
      this.hasError = true;
    }
  }

  // getFormValidationErrors() {
  //   Object.keys(this.form.controls).forEach(key => {

  //   const controlErrors: ValidationErrors = this.form.get(key).errors;
  //   if (controlErrors != null) {
  //       Object.keys(controlErrors).forEach(keyError => {
  //         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
  //       });
  //     }
  //   });
  // }

  // startChanged(e) {
  //   if (e != null) {
  //     const prevEndDate = moment(e).add(1, 'days');
  //     if (this.form.get('startDate').value < e || this.form.get('endDate').value === '') {
  //       this.form.get('endDate').setValue(prevEndDate.toDate());
  //       // alert(this.fechaFinRef.nativeElement.show() )
  //       // let ele: HTMLElement = document.getElementById('ll') as HTMLElement;
  //       // ele.open();
  //     }
  //     this.minDateTo = prevEndDate.toDate();
  //   }
  // }

  // populateArrayCities(target) {
  //   if (target === 'from') {
  //     this.citiesFrom.map(city => {
  //       this.citiesFromArray.push(`${city.nombre}, ${city.country.nombre}`);
  //     });
  //   } else if (target === 'to') {
  //     this.citiesTo.map(city => {
  //       this.citiesToArray.push(`${city.nombre}, ${city.country.nombre}`);
  //     });
  //   }
  // }
}
