import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-detail-tab-reviews',
  templateUrl: './hotel-detail-tab-reviews.component.html',
  styleUrls: ['./hotel-detail-tab-reviews.component.css']
})
export class HotelDetailTabReviewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
