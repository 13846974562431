import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-detail-tab-ammenities',
  templateUrl: './hotel-detail-tab-ammenities.component.html',
  styleUrls: ['./hotel-detail-tab-ammenities.component.css']
})
export class HotelDetailTabAmmenitiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
