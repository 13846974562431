import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { PackageService } from '../../../../services/Package/package.service';
import { IncludesServicesService } from '../../../../services/IncludedServices/includes-services.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-package-listado-filters',
  templateUrl: './package-listado-filters.component.html',
  styleUrls: ['./package-listado-filters.component.css']
})
export class PackageListadoFiltersComponent implements OnInit {
  @Output() filterPackages: EventEmitter<any> =  new EventEmitter();
  @Input() numberResult;
  duraciones: any = [];
  tipos: any = [];
  servicios: any = [];
  slidemin = 1;
  slidemax = 5000;
  durationsSelected = [];
  typeSelected = [];
  includedSelected = [];
  URL_WEB = environment.URL_WEB;
  constructor(private packageService: PackageService, private toastr: ToastrService, private includesServicesService: IncludesServicesService) { }

  ngOnInit() {
    this.llenarInformacion();
    const t = this;
    $( '#price-range' ).slider({
      range: true,
      min: 1,
      max: 5000,
      values: [ 1, 5000 ],
      slide: function( event, ui ) {
        $( '#amount' ).val( '$ ' + ui.values[ 0 ] + ' - $ ' + ui.values[ 1 ] );
      },
      stop: function( event, ui ) {
        $( '#amount' ).val( '$ ' + ui.values[ 0 ] + ' - $ ' + ui.values[ 1 ] );
        t.slidemin = ui.values[ 0 ];
        t.slidemax = ui.values[ 1 ];
        t.filterAmmounts();
      }
    });
    $( '#amount' ).val( '$ ' + $( '#price-range' ).slider( 'values', 0 ) +
      ' - $ ' + $( '#price-range' ).slider( 'values', 1 ) );
  }

  filterAmmounts() {
    this.refreshResultWithFilters()
  }

  filterDuration(value){
    const index = this.durationsSelected.indexOf(value)
    if(index >= 0){
      this.durationsSelected.splice(index, 1);
    }else{
      this.durationsSelected.push(value);
    }
    this.refreshResultWithFilters()
  }

  filterPackageTypes(value){
    const index = this.typeSelected.indexOf(value)
    if(index >= 0){
      this.typeSelected.splice(index, 1);
    }else{
      this.typeSelected.push(value);
    }
    this.refreshResultWithFilters()
  }

  filterIncludedServices(value){
    const index = this.includedSelected.indexOf(value)
    if(index >= 0){
      this.includedSelected.splice(index, 1);
    }else{
      this.includedSelected.push(value);
    }
    this.refreshResultWithFilters()
  }

  llenarInformacion() {
    this.duraciones = [
      {id: 1, nombre: 'Hasta 3 días', min: 0, max: 3},
      {id: 2, nombre: '4 a 7 días', min: 4, max: 7},
      {id: 3, nombre: '8 a 11 días', min: 8, max: 11},
      {id: 4, nombre: '12 a 14 días', min: 12, max: 14},
      {id: 5, nombre: 'Más de 14 días', min: 15, max: 9999}
    ];

    this.packageService.getPackageTypes().toPromise().then(data => {
      const t = this;
      const r: any = data;
      if (r.success) {
        t.tipos = r.data;
      } else {
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      }
    })
    .catch((err) => {
      this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
    });

    this.includesServicesService.getServices().toPromise().then(data => {
      const t = this;
      const r: any = data;
      if (r.success) {
        t.servicios = r.data.filter( item => item.type === 1);
      } else {
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      }
    })
    .catch((err) => {
      this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde 22.');
    });
  }

  getMinMaxDurations = () => this.duraciones.filter(item => this.durationsSelected.includes(item.id) )

  refreshResultWithFilters(){
    const filterPrices = {
      slideMin: this.slidemin,
      slideMax: this.slidemax
    };

    const filters = {
      filterPrices,
      durations: this.getMinMaxDurations(),
      types: this.typeSelected, 
      includedServices: this.includedSelected
    };
    this.filterPackages.emit(filters);
  }

}
