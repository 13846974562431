import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  NgModule,
  Input,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../../../environments/environment";

declare var SqPaymentForm: any; //magic to allow us to access the SquarePaymentForm lib

@Component({
  selector: "app-square",
  templateUrl: "./square.component.html",
  styleUrls: ["./square.component.css"],
})
export class SquareComponent implements OnInit {
  @ViewChild("cardNonce") cardNonce: ElementRef;
  @ViewChild("zipCode") zipCode: ElementRef;
  @ViewChild("cardError") cardError: ElementRef;
  @Output() sendPayment: EventEmitter<any> = new EventEmitter();
  @Input() isCheckout = true;
  constructor(private toastr: ToastrService) {}
  nonce = "";
  paymentForm; //this is our payment form object

  ngOnInit() {}

  requestCardNonce(event) {
    // Don't submit the form until SqPaymentForm returns with a nonce
    event.preventDefault();
    // Request a nonce from the SqPaymentForm object
    return this.paymentForm.requestCardNonce();
  }

  ngAfterViewInit() {
    this.createForm();
  }

  createForm() {
    // Set the application ID
    var applicationId = environment.squareApp.applicationId;

    // Set the location ID
    var locationId = environment.squareApp.locationId;
    this.paymentForm = new SqPaymentForm({
      // Initialize the payment form elements
      applicationId: applicationId,
      locationId: locationId,
      inputClass: "sq-input",

      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [
        {
          fontSize: ".9em",
        },
      ],

      // Initialize the credit card placeholders
      cardNumber: {
        elementId: "sq-card-number",
        placeholder: "•••• •••• •••• ••••",
      },
      cvv: {
        elementId: "sq-cvv",
        placeholder: "CVV",
      },
      expirationDate: {
        elementId: "sq-expiration-date",
        placeholder: "MM/YY",
      },
      postalCode: {
        elementId: "sq-postal-code",
      },

      // SqPaymentForm callback functions
      callbacks: {
        /*
         * callback function: methodsSupported
         * Triggered when: the page is loaded.
         */
        methodsSupported: function (methods) {
          var applePayBtn = document.getElementById("sq-apple-pay");
          var applePayLabel = document.getElementById("sq-apple-pay-label");
          var masterpassBtn = document.getElementById("sq-masterpass");
          var masterpassLabel = document.getElementById("sq-masterpass-label");

          // Only show the button if Apple Pay for Web is enabled
          // Otherwise, display the wallet not enabled message.
          if (methods.applePay === true) {
            applePayBtn.style.display = "inline-block";
            applePayLabel.style.display = "none";
          }
          // Only show the button if Masterpass is enabled
          // Otherwise, display the wallet not enabled message.
          if (methods.masterpass === true) {
            masterpassBtn.style.display = "inline-block";
            masterpassLabel.style.display = "none";
          }
        },

        /*
         * callback function: cardNonceResponseReceived
         * Triggered when: SqPaymentForm completes a card nonce request
         */
        cardNonceResponseReceived: function (errors, nonce, cardData) {
          console.log("cardData");
          console.log(cardData);
          if (errors) {
            // Log errors from nonce generation to the Javascript console
            console.log("Encountered errors:");
            let errorMessage = "Please check the following errors";
            errors.forEach(function (error) {
              console.log("  " + error.message);
              errorMessage += "<br>* " + error.message;
            });
            (<HTMLInputElement>(
              document.getElementById("card-error")
            )).value = errorMessage;
            return;
          }

          // alert('Nonce received: ' + nonce); /* FOR TESTING ONLY */

          // Assign the nonce value to the hidden form field
          // document.getElementById('card-nonce').value = nonce;
          //needs to be extracted from the
          (<HTMLInputElement>(
            document.getElementById("card-nonce")
          )).value = nonce; //casting so .value will work
          (<HTMLInputElement>document.getElementById("zip-code")).value =
            "12345"; //casting so .value will work
          //get this value from the database when the user is logged in
          (<HTMLInputElement>document.getElementById("sq-id")).value =
            "CBASEC8F-Phq5_pV7UNi64_kX_4gAQ";

          // POST the nonce form to the payment processing page
          // (<HTMLFormElement>document.getElementById('nonce-form')).submit();
        },

        /*
         * callback function: unsupportedBrowserDetected
         * Triggered when: the page loads and an unsupported browser is detected
         */
        unsupportedBrowserDetected: function () {
          /* PROVIDE FEEDBACK TO SITE VISITORS */
        },

        /*
         * callback function: inputEventReceived
         * Triggered when: visitors interact with SqPaymentForm iframe elements.
         */
        inputEventReceived: function (inputEvent) {
          switch (inputEvent.eventType) {
            case "focusClassAdded":
              /* HANDLE AS DESIRED */
              break;
            case "focusClassRemoved":
              /* HANDLE AS DESIRED */
              break;
            case "errorClassAdded":
              /* HANDLE AS DESIRED */
              break;
            case "errorClassRemoved":
              /* HANDLE AS DESIRED */
              break;
            case "cardBrandChanged":
              /* HANDLE AS DESIRED */
              break;
            case "postalCodeChanged":
              /* HANDLE AS DESIRED */
              break;
          }
        },

        /*
         * callback function: paymentFormLoaded
         * Triggered when: SqPaymentForm is fully loaded
         */
        paymentFormLoaded: function () {
          /* HANDLE AS DESIRED */
          console.log("desired");
        },
      },
    });
  }

  sendForm() {
    this.paymentForm.requestCardNonce();
    var nonceInterval = setInterval(() => {
      const nonce = this.cardNonce.nativeElement.value;
      const zipCode = this.zipCode.nativeElement.value;
      const errors = this.cardError.nativeElement.value;
      if (nonce !== "") {
        clearInterval(nonceInterval);
        this.sendPayment.emit({ nonceCard: nonce, zipCode: zipCode });
      } else if (errors !== "") {
        clearInterval(nonceInterval);
        this.toastr.error(errors, "Error", { enableHtml: true });
      }
    }, 1000);
  }
}
