import { createReducer, on } from "@ngrx/store";
import {
  selectHotel,
  deleteHotel,
  addPasengers,
  deletePassengers,
  saveSearchData,
} from "../actions/hotel.actions";

export const initialState = {
  hotelSelected: undefined,
  passengers: undefined,
  searchData: undefined,
};

const _hotelReducer = createReducer(
  initialState,
  on(selectHotel, (state, { hotel }) => {
    state.hotelSelected = hotel;
    return state;
  }),
  on(deleteHotel, (state) => initialState),
  on(addPasengers, (state, { passengers }) => {
    state.passengers = passengers;
    return state;
  }),
  on(deletePassengers, (state) => initialState),
  on(saveSearchData, (state, { searchData }) => {
    state.searchData = searchData;
    return state;
  })
);

export function hotelReducer(state, action) {
  return _hotelReducer(state, action);
}
