import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {FlyService} from '../../../../services/Fly/fly.service';
import {FlightService} from '../../../../services/Flight/flight.service';
import {Flight} from '../../../../models/flight.model';

@Component({
  selector: 'app-vuelos-listado',
  templateUrl: './vuelos-listado.component.html',
  styleUrls: ['./vuelos-listado.component.css']
})
export class VuelosListadoComponent implements OnInit {
  searchResults: Array<any>;
  searchParams = {};
  filterParams = {
    direction: null,
    scales: null,
    priceOrder: null,
    airlines: null,
  };
  filteredList: Array<any>;
  flightsList: Array<Flight>;
  flightsListFiltered: Array<Flight>;
  
  constructor(private toastr: ToastrService, private flyService: FlyService, private flightService: FlightService) {
  }

  ngOnInit() {
    
  }



  requestFlights() {
    const queryParams = Object.assign({
      returnDate: undefined,
      departureDate: undefined,
    }, this.searchParams);

    queryParams.departureDate = moment(queryParams.departureDate).format('YYYY-MM-DD');
    queryParams.returnDate = moment(queryParams.returnDate).format('YYYY-MM-DD');

    this.flightService.get(queryParams).toPromise().then((response: Array<Flight>) => {
      this.flightsList = response;
      this.filterRequest();
    }).catch(() => {
      this.toastr.error('Ocurrió un error al cargar los resultados, inténtelo de nuevo más tarde.');
    });
  }

  filterRequest() {
    if (this.flightsList) {
      this.flightsListFiltered = this.flightsList.filter((flight) => {
        let valid = true;

        // Scales filter
        if (this.filterParams.scales) {
          const scalesParam = parseInt(this.filterParams.scales, 10);
          const connectionsAmount = flight.connections.length - 1;

          if (scalesParam === 0) {
            if (connectionsAmount !== 0) {
              valid = false;
            }
          } else if (scalesParam === 1) {
            if (connectionsAmount !== 1) {
              valid = false;
            }
          } else if (scalesParam === -1) {
            if (connectionsAmount < 2) {
              valid = false;
            }
          }
        }
        // Price range filter
        if (this.filterParams['price']) {
          const price = this.flightService.calcPrice(flight);
          const minPrice = this.filterParams['price'][0];
          const maxPrice = this.filterParams['price'][1];

          console.log(price);
          console.log(minPrice);
          console.log(maxPrice);

          if (price < minPrice || price > maxPrice) {
            valid = false;
          }
        }
        // Airlines filter
        if (this.filterParams.airlines && this.filterParams.airlines.length > 0) {
          if (this.filterParams.airlines.indexOf(flight.firstConnection.airline.id) === -1) {
            valid = false;
          }
        }
        return valid;
      });

      // Price sort
      if (this.filterParams.priceOrder) {
        this.flightsListFiltered = this.flightsListFiltered.sort((a: Flight, b: Flight) => {
          const priceA = this.flightService.calcPrice(a);
          const priceB = this.flightService.calcPrice(b);

          if (this.filterParams.priceOrder === 'min') {
            return priceA - priceB;
          } else {
            return priceB - priceA;
          }
        });
      }
    }

    /*if (this.searchResults) {
      this.filteredList = this.searchResults.filter((fly) => {
          const airline = fly.directions[0].transits[0].airline;
          let valid = true;

          // Scales amount filter
          if (this.filterParams.scales) {
            if (parseInt(this.filterParams.scales, 10) === 0) {
              if (fly.scalesAmount !== 0) {
                valid = false;
              }
            } else if (parseInt(this.filterParams.scales, 10) === 1) {
              if (fly.scalesAmount !== 1) {
                valid = false;
              }
            } else if (parseInt(this.filterParams.scales, 10) === -1) {
              if (fly.scalesAmount < 2) {
                valid = false;
              }
            }
          }
          // Price range filter
          if (this.filterParams['price']) {
            const minPrice = this.filterParams['price'][0];
            const maxPrice = this.filterParams['price'][1];
            if (fly.price < minPrice || fly.price > maxPrice) {
              valid = false;
            }
          }

          // Airlines filter
          if (this.filterParams.airlines && this.filterParams.airlines.length > 0) {
            console.log(this.filterParams.airlines.indexOf(airline.id) === -1);
            if (this.filterParams.airlines.indexOf(airline.id) === -1) {
              valid = false;
            }
          }

          return valid;
        }
      );

      // Price sort
      if (this.filterParams.priceOrder) {
        this.filteredList = this.filteredList.sort((a, b) => {
          if (this.filterParams.priceOrder === 'min') {
            return a.price - b.price;
          } else {
            return b.price - a.price;
          }
        });
      }
    }*/
  }
}
