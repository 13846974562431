import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HotelService } from "../../services/Hotel/hotel.service";
import { ToastrService } from "ngx-toastr";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";

import { selectHotel } from "../../actions/hotel.actions";

@Component({
  selector: "app-hotel-detail",
  templateUrl: "./hotel-detail.component.html",
  styleUrls: ["./hotel-detail.component.css"],
})
export class HotelDetailComponent implements OnInit {
  private hotelSubscribe: any;
  isLoading = false;
  hotelSelected: any;
  listRooms: any = [];
  gallery = [];
  constructor(
    private store: Store<{ hotel: any }>,
    private router: Router,
    private toastr: ToastrService,
    private hotelService: HotelService
  ) {}

  ngOnInit() {
    this.hotelSubscribe = this.store
      .pipe(select("hotel"))
      .subscribe((hotel) => {
        const { hotelSelected } = hotel;
        if (!hotelSelected) {
          //lo enviamos al inicio
          this.router.navigate(["/"]);
          // this.sub = this.route.params.subscribe((params) => {
          //   this.loadDetail(params["cod_hotel"]);
          // });
        } else {
          this.hotelSelected = hotelSelected;
          this.createGallery();
          this.createListRooms();
        }
      });
  }

  ngOnDestroy() {
    this.hotelSubscribe.unsubscribe();
  }

  getValue() {
    // this.codHotel$.subscribe((value) => {
    //   console.log("Hotel valor", value);
    //   this.codHotelSelected = value;
    // });
    // this.codHotel$.subscribe.toPromise().then((value) => alert(value));
  }

  // ngOnInit() {
  //   $(document).ready(function(){
  //     "use strict";
  //     // The slider being synced must be initialized first
  //     $('#carousel').flexslider({
  //       animation: "slide",
  //       controlNav: false,
  //       animationLoop: false,
  //       slideshow: false,
  //       itemWidth: 150,
  //       itemMargin: 5,
  //       asNavFor: '#slider'
  //     });

  //     $('#slider').flexslider({
  //       animation: "slide",
  //       controlNav: false,
  //       animationLoop: false,
  //       slideshow: false,
  //       sync: "#carousel"
  //     });
  //   });
  // }

  // loadDetail = async (cod_hotel) => {
  //   const formData = JSON.parse(localStorage.getItem("searchHotelFields"));
  //   this.isLoading = true;
  //   try {
  //     const response: any = await this.hotelService
  //       .findAvailabilities(formData)
  //       .toPromise();
  //     const { success, data } = response;
  //     const hotelSelected = data.find((item) => item.cod_hotel === cod_hotel);
  //     this.isLoading = false;
  //     if (success) {
  //       this.hotelSelected = hotelSelected;

  //       this.store.dispatch(selectHotel({ hotel: hotelSelected }));

  //       this.createGallery();
  //       this.createListRooms();
  //     } else {
  //       this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     this.isLoading = false;
  //     this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
  //   }
  // };

  createGallery = () => {
    this.gallery = this.hotelSelected.information.images.map((item) => {
      return { image: item.image };
    });
  };

  createListRooms = () => {
    const newList = [];
    const { offers } = this.hotelSelected;
    offers.map((offer) => {
      offer.map((offerRoom) => {
        const lins = [
          Object.values(offerRoom.availabilities)[0],
          Object.values(offerRoom.availabilities)[1],
        ];
        const room = {
          name: offerRoom.description,
          image: this.hotelSelected.information.image,
          cost: offerRoom.availabilities.cost.prr,
          capacity: undefined,
          includes: [],
          amenities: [],
          lins,
        };
        newList.push(room);
      });
    });
    this.listRooms = this.sortByCost(newList);
  };

  sortByCost(items) {
    return items.sort(function (a, b) {
      if (a.cost > b.cost) {
        return 1;
      }
      if (a.cost < b.cost) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }
}
