import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-recent-blog',
  templateUrl: './recent-blog.component.html',
  styleUrls: ['./recent-blog.component.css']
})
export class RecentBlogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function() {
      $('#post-list').owlCarousel({
        loop: true,
        margin: 30,
        responsiveClass: true,
        autoHeight: true,
        autoplay: true,
        autoplayTimeout: 5000,
        navigation: false,
        stopOnHover: true,
        responsive: {
          0: {
            items: 1,
            loop: true
          },
          600: {
            items: 2,
            loop: true
          },
          1000: {
            items: 4,
            loop: true
          }
        }
      });
    });
  }

}
