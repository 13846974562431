import { Deserializable } from "./deserializable.model";

export class OrderPayment implements Deserializable {
  id: number;
  order_id: number;
  status: string;
  amount: number;
  currency: string;
  exchange_rate: number;
  creditcard_type: string;
  creditcard_lastnumbers: string;
  date: string;

  deserialize(input: any): this {
    this.id = input.id;
    this.order_id = input.order_id;
    this.status = input.status;
    this.amount = input.amount;
    this.currency = input.currency;
    this.exchange_rate = input.exchange_rate;
    this.creditcard_type = input.creditcard_type;
    this.creditcard_lastnumbers = input.creditcard_lastnumbers;
    this.date = input.created_at;

    return this;
  }
}
