import {Component, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import { CreditCard } from '../../../../../models/creditcard.model';
import { CreditCardService } from '../../../../../services/CreditCard/credit-card.service';
import { UserService } from '../../../../../services/User/user.service';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SquareComponent } from '../../../../checkout/paymentforms/square/square.component';

@Component({
  selector: 'app-squareupform',
  templateUrl: './squareupform.component.html',
  styleUrls: ['./squareupform.component.css']
})
export class SquareupformComponent implements OnInit {
  @ViewChild(SquareComponent ) square: SquareComponent ; 
  @Output() loadCreditCards: EventEmitter<any> =  new EventEmitter();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  isLoading = false;
  formHasError = false;
  formCard = new FormGroup({
    cardHolderName: new FormControl(null, [Validators.required])
  });
  
  
  constructor(
    private toastr: ToastrService,
    private userService: UserService, 
    private creditCardService: CreditCardService
    ) {
  }

  onSubmitPayment(){
    
    if(!this.formHasError){
      this.isLoading = true;
      this.square.sendForm()
    }
  }

  ngOnInit() {
  }

  async sendPayment(data){
    const userData = await this.userService.UserDataLogin();
    const dataCC = {
      client_id: userData.id,
      card_nonce: data.nonceCard,
      cardholder_name: this.formCard.get('cardHolderName').value
    }
    
    await this.creditCardService.save(dataCC).toPromise()
    .then(response => {
      this.loadCreditCards.emit();
      this.isLoading = false;
      this.closeModal.emit();
    })
    .catch((err) => {
      this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      this.isLoading = false;
    });
  }
}
