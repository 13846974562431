import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-tour-detail-tabs-disponibilidad',
  templateUrl: './tour-detail-tabs-disponibilidad.component.html',
  styleUrls: ['./tour-detail-tabs-disponibilidad.component.css']
})
export class TourDetailTabsDisponibilidadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#check_in').datepicker({ minDate: -0, maxDate: '+3M' });
    $('#check_out').datepicker({ minDate: -0, maxDate: '+3M' });
    $('#adult_count').spinner({
      min: 1
    });
    $('#child_count').spinner( {
      min: 1
    });
  }

}
