import { Component, OnInit, Input } from "@angular/core";
import { CartService } from "../../../services/Cart/cart.service";
declare var $: any;

@Component({
  selector: "app-checkout-reserva",
  templateUrl: "./checkout-reserva.component.html",
  styleUrls: ["./checkout-reserva.component.css"],
})
export class CheckoutReservaComponent implements OnInit {
  @Input() dataConfirmation: any;
  cart: any;
  imageBanner: string = "";
  constructor(private cartService: CartService) {}

  ngOnInit() {
    $("#open-popup-num-orden").magnificPopup({
      type: "inline",
      midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't
    });

    $("#open-popup-num-confirmacion").magnificPopup({
      type: "inline",
      midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't
    });
  }
}
