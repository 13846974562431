import {Deserializable} from './deserializable.model';

export class Airline implements Deserializable {
  id: number;
  name: string;
  logoImg: string;
  iataNumber: number;

  deserialize(input: any): this {
    this.id = input.id;
    this.name = input.name;
    this.logoImg = input.logo;
    this.iataNumber = input.iatanumber;

    return this;
  }
}
