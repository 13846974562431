import { Component, OnInit, Input } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { CartService } from "../../../services/Cart/cart.service";
import { ExchangerateService } from "../../../services/ExchangeRate/exchangerate.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { PRODUCT_TYPES } from "../../../constants/productTypes";

declare var $: any;

@Component({
  selector: "app-package-detail-availability-multi-item",
  templateUrl: "./package-detail-availability-multi-item.component.html",
  styleUrls: ["./package-detail-availability-multi-item.component.css"],
})
export class PackageDetailAvailabilityMultiItemComponent implements OnInit {
  @Input() package;
  @Input() availability;
  @Input() hotel;
  @Input() adults;
  @Input() children;
  @Input() number = 0;
  URL_WEB = environment.URL_WEB;
  imageAvailability: String = "";
  bookWithPrice = 0;
  flight_departure: any;
  flight_return: any;

  constructor(
    public exchangerateService: ExchangerateService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    const numImages = this.package.images.length;
    const index = Math.floor(Math.random() * (numImages - 0) + 0);
    this.imageAvailability = this.package.images[index].image;
    if (this.availability.book_with !== null) {
      this.calculateBookWithPrice();
    }
    const countFlights = this.availability.id.flights.length;
    this.flight_departure = this.availability.id.flights[0];
    console.log("flight departure");
    console.log(this.flight_departure);
    this.flight_return = this.availability.id.flights[countFlights - 1];
  }

  calculateBookWithPrice() {
    const { book_with, calculatedPrice } = this.availability;
    this.bookWithPrice = this.cartService.calculateBookPrice(
      book_with,
      calculatedPrice
    );
  }

  createReserveLinkPopUp() {
    $(".open-popup-link").magnificPopup({
      type: "inline",
      midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't
    });
  }

  async addItemToCart() {
    let msgItemAdded;
    await this.translate
      .get("item_added_cart")
      .toPromise()
      .then((resp) => (msgItemAdded = resp));
    const item = {
      product_type: PRODUCT_TYPES.PACKAGES,
      product_id: this.package.id,
      subproduct_id: this.availability.id.id,
      multidestiny: true,
      package: this.package,
      availability: this.availability,
      hotel: this.hotel,
      adults: this.adults,
      children: this.children,
      babies: 0,
      cost: this.availability.calculatedPrice,
      discount: 0,
      subtotal: this.availability.calculatedPrice,
      price: this.availability.calculatedPrice,
    };
    await this.cartService.addItem(item);
    this.router.navigateByUrl("/cart");
  }
}
