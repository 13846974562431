import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { CityService } from "../../../services/City/city.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { HotelService } from "../../../services/Hotel/hotel.service";
import { Store } from "@ngrx/store";
import { saveSearchData } from "../../../actions/hotel.actions";
import { HotelSearchData } from "../../../models/hotel-search-data.model";

@Component({
  selector: "app-hotel-search-tab",
  templateUrl: "./hotel-search-tab.component.html",
  styleUrls: ["./hotel-search-tab.component.css"],
})
export class HotelSearchTabComponent implements OnInit, OnDestroy {
  disableSencillo = true;
  citiesFrom = [];
  citiesTo = [];
  minDateFrom: Date = new Date();
  minDateTo: Date;
  bsConfig = {
    dateInputFormat: "YYYY-MM-DD",
    containerClass: "theme-blue",
    showWeekNumbers: false,
  };
  hasError = false;
  form = new FormGroup({
    departureCity: new FormControl(null, [Validators.required]),
    destinyCity: new FormControl(null, [Validators.required]),
    departureDate: new FormControl(null, [Validators.required]),
    returnDate: new FormControl(null, [Validators.required]),
    rooms: new FormControl(1, [Validators.required]),
    adults: new FormControl(1, [Validators.required]),
    children: new FormControl(0, [Validators.required]),
    babies: new FormControl(0, [Validators.required]),
  });
  constructor(
    private store: Store<{ hotel: any }>,
    private toastr: ToastrService,
    private hotelService: HotelService,
    private cityService: CityService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getCities("from");
    this.getCities("to");
  }

  ngOnDestroy(): void {}

  getCities(target) {
    this.cityService
      .get(target, "hotel")
      .toPromise()
      .then((response: any) => {
        if (response.success) {
          if (target === "from") {
            this.citiesFrom = response.data;
          } else if (target === "to") {
            this.citiesTo = response.data;
          }
        } else {
          this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
        }
      })
      .catch((err) => {
        this.toastr.error(
          "Ocurrió un error al cargar las ciudades, inténtelo de nuevo más tarde."
        );
      });
  }

  submitSearch = async () => {
    if (this.form.valid) {
      this.hasError = false;
      const {
        departureCity,
        destinyCity,
        departureDate,
        returnDate,
        rooms,
        adults,
        children,
        babies,
      } = this.form.value;

      const formData: HotelSearchData = {
        city_from_id: departureCity,
        city_to_id: destinyCity,
        stars: "0",
        date_from: moment(departureDate).format("YYYY-MM-DD"),
        date_to: moment(returnDate).format("YYYY-MM-DD"),
        rooms: this.createRoomsArraySearchParam(rooms, adults, "5,6"),
        num_rooms: rooms,
        adults,
        children,
        babies,
      };
      this.store.dispatch(saveSearchData({ searchData: formData }));
      this.router.navigate(["/hotels/list"]);
    } else {
      this.hasError = true;
    }
  };

  createRoomsArraySearchParam = (rooms, adults, childrenAges) => {
    const arrayRooms = [];
    for (let i = 0; i < rooms; i++) {
      arrayRooms.push({
        adults,
        children: childrenAges,
      });
    }
    return arrayRooms;
  };

  startChanged(e) {
    if (e != null) {
      const prevEndDate = moment(e).add(1, "days");
      if (
        this.form.get("departureDate").value < e ||
        this.form.get("returnDate").value === ""
      ) {
        this.form.get("returnDate").setValue(prevEndDate.toDate());
        // let ele: HTMLElement = document.getElementById('ll') as HTMLElement;
        // ele.open();
      }
      this.minDateTo = prevEndDate.toDate();
    }
  }
}
