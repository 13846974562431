import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-item-package-multidestiny',
  templateUrl: './item-package-multidestiny.component.html',
  styleUrls: ['./item-package-multidestiny.component.css']
})
export class ItemPackageMultidestinyComponent implements OnInit {
  @Output() removeItem: EventEmitter<any> =  new EventEmitter();
  @Input() item: any;
  @Input() index: any;
  @Input() isCheckout: boolean = false;
  @Input() isConfirmation: boolean = false;
  URL_WEB = environment.URL_WEB;
  constructor() { }

  ngOnInit() {
  }

  remove(){
    this.removeItem.emit(this.index)
  }

}
