import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
  ValidationErrors,
} from "@angular/forms";
import * as moment from "moment";
import { CountryService } from "../../../services/Country/country.service";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { StateService } from "../../../services/State/state.service";
import { TravelerDocumentService } from "../../../services/TravelerDocument/traveler-document.service";
import { OfacCategoryService } from "../../../services/OfacCategory/ofac-category.service";
import { CartFlight } from "../../../models/cart-flight.model";
import { UserService } from "../../../services/User/user.service";
import { PRODUCT_TYPES } from "../../../constants/productTypes";
@Component({
  selector: "app-checkout-info",
  templateUrl: "./checkout-info.component.html",
  styleUrls: ["./checkout-info.component.css"],
})
export class CheckoutInfoComponent implements OnInit {
  @Output() saveInfoTab: EventEmitter<any> = new EventEmitter();
  bsConfig = {
    dateInputFormat: "YYYY-MM-DD",
    containerClass: "theme-blue",
    showWeekNumbers: false,
  };

  submitted = false;
  formInfo: FormGroup;
  formHasError = false;
  cart: any;
  icon: "fa-user";
  countries = undefined;
  documents = undefined;
  ofac_categories = undefined;
  eeuu_states = undefined;
  cuba_states = undefined;

  flight: CartFlight;
  isFlight = false;
  destinationCuba = false;

  constructor(
    private userService: UserService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private travelerDocumentService: TravelerDocumentService,
    private ofacCategoryService: OfacCategoryService,
    private countryService: CountryService,
    private stateService: StateService,
    private httpClient: HttpClient
  ) {}

  async ngOnInit() {
    this.loadCart();

    const itemCart = this.cart.items[0];

    if (itemCart.product_type === PRODUCT_TYPES.FLIGHTS) {
      this.isFlight = true;
      this.flight = itemCart;
      this.stateService.getByCountry(65).then((states) => {
        this.eeuu_states = states;
      });

      this.stateService.getByCountry(54).then((states) => {
        this.cuba_states = states;
      });
    }

    let contactEmail = "";
    let user = await this.userService.UserDataLogin();
    if (user !== null && user !== undefined) {
      contactEmail = user.email;
    } else {
      contactEmail = await this.userService.getGuestUser();
    }

    this.formInfo = this.fb.group({
      travelers: this.fb.array([]),
      email: this.fb.control(contactEmail, [
        Validators.required,
        Validators.email,
      ]),
      phone: this.fb.control(null, [Validators.required]),
    });

    for (let i = 0; i < itemCart.adults; i++) {
      const travelerGroup = this.formInfo.get("travelers") as FormArray;
      travelerGroup.push(this.crateTravelerGroup("Adult"));
    }

    for (let i = 0; i < itemCart.childs; i++) {
      const travelerGroup = this.formInfo.get("travelers") as FormArray;
      travelerGroup.push(this.crateTravelerGroup("Child"));
    }

    for (let i = 0; i < itemCart.babies; i++) {
      const travelerGroup = this.formInfo.get("travelers") as FormArray;
      travelerGroup.push(this.crateTravelerGroup("Baby"));
    }

    this.travelerDocumentService.getAll().then((response) => {
      this.documents = response;
    });

    if (this.isFlight && this.flight.flight.cityArrival.countryId === 54) {
      this.destinationCuba = true;
      this.ofacCategoryService.getAll().then((response) => {
        this.ofac_categories = response;

        const defaultCategory = response.filter((category) => {
          return category.default === 1;
        });

        (this.formInfo.get("travelers") as FormArray).controls.forEach(
          (traveler: FormGroup) => {
            traveler
              .get("secondary")
              .get("secondary_ofac_category")
              .setValue(defaultCategory[0].id);
          }
        );
      });
    }

    this.countryService.getAll().then((response) => {
      this.countries = response;
    });
  }

  private crateTravelerGroup(type: string) {
    const form = this.fb.group({
      contact_type: this.fb.control(type, [Validators.required]),
      contact_name: this.fb.control(null, [Validators.required]),
      contact_lastname: this.fb.control(null, [Validators.required]),
      contact_gender: this.fb.control(null, [Validators.required]),
      contact_birthdate: this.fb.control(null, [Validators.required]),
      contact_document: this.fb.control(!this.destinationCuba ? 1 : null, [
        Validators.required,
      ]),
      contact_country_issue: this.fb.control(null, [Validators.required]),
      contact_document_number: this.fb.control(null, [Validators.required]),
      contact_expiration_date: this.fb.control(null, [Validators.required]),
    });

    if (this.isFlight && this.flight.flight.cityArrival.countryId === 54) {
      form.addControl(
        "secondary",
        this.fb.group({
          secondary_document: this.fb.control(null, [Validators.required]),
          secondary_country_issue: this.fb.control(null, [Validators.required]),
          secondary_document_number: this.fb.control(null, [
            Validators.required,
          ]),
          secondary_expiration_date: this.fb.control(null, [
            Validators.required,
          ]),
          secondary_eeuu_address: this.fb.control(null, [Validators.required]),
          secondary_eeuu_postalcode: this.fb.control(null, [
            Validators.required,
          ]),
          secondary_eeuu_state: this.fb.control(null, [Validators.required]),
          secondary_eeuu_city: this.fb.control(null, [Validators.required]),
          secondary_cuba_address: this.fb.control(null, [Validators.required]),
          secondary_cuba_postalcode: this.fb.control(null, [
            Validators.required,
          ]),
          secondary_cuba_state: this.fb.control(null, [Validators.required]),
          secondary_cuba_city: this.fb.control(null, [Validators.required]),
          secondary_cuba_phone: this.fb.control(null, [Validators.required]),
          secondary_ofac_category: this.fb.control(null, [Validators.required]),
        })
      );

      form
        .get("secondary")
        .get("secondary_document")
        .valueChanges.subscribe((val) => {
          if (form.get("contact_document").value === val) {
            this.toaster.error("Los documentos no pueden ser iguales");
            form.get("secondary").get("secondary_document").setValue(null);
          }
        });
    }

    return form;
  }

  loadCart() {
    const localCart = localStorage.getItem("cart");
    if (localCart !== null && localCart !== undefined) {
      this.cart = JSON.parse(localCart);
      if (this.cart.items.length === 0) {
        this.cart = null;
      }
    }
  }

  onSubmitInfo() {
    this.getFormValidationErrors();
    this.submitted = true;

    if (this.formInfo.valid) {
      const form = JSON.parse(JSON.stringify(this.formInfo.value));

      form.travelers.map((traveler) => {
        traveler.contact_birthdate = moment(traveler.contact_birthdate).format(
          "YYYY-MM-DD"
        );
        traveler.contact_expiration_date = moment(
          traveler.contact_expiration_date
        ).format("YYYY-MM-DD");
        if (this.isFlight && this.flight.flight.cityArrival.countryId === 54) {
          traveler.secondary.secondary_expiration_date = moment(
            traveler.secondary.secondary_expiration_date
          ).format("YYYY-MM-DD");
        }
        return traveler;
      });
      this.saveInfoTab.emit(form);
    } else {
      this.formHasError = true;
    }
  }

  getFormValidationErrors() {
    const form = JSON.parse(JSON.stringify(this.formInfo.value));
    console.log("llego aqui");
    console.log(form);
    // console.log('entro')
    // Object.keys(this.formInfo.controls).forEach(key => {
    //   console.log('aqui llego')
    //   const controlErrors: ValidationErrors = this.formInfo.get(key).errors;
    //   console.log(controlErrors)
    //   if (controlErrors != null) {
    //       Object.keys(controlErrors).forEach(keyError => {
    //         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
    //       });
    //     }
    // });

    // console.log(this.formInfo.controls.travelers)
    // console.log('aqui entró ')
    // console.log(this.formInfo.controls.travelers)
    // Object.keys(this.formInfo.controls.travelers).forEach(key => {
    //   console.log('aqui llego')
    //   const controlErrors: ValidationErrors = this.formInfo.travelers.get(key).errors;
    //   console.log(controlErrors)
    //   if (controlErrors != null) {
    //       Object.keys(controlErrors).forEach(keyError => {
    //         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
    //       });
    //     }
    // });
  }
}
