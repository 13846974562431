import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { PackageService } from '../../services/Package/package.service';
import { PackageDetailTabsComponent } from '../../components/package/package-detail-tabs/package-detail-tabs.component';

@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.css']
})
export class PackageDetailComponent implements OnInit, OnDestroy {
  private sub: any;
  activeTab = 'tab1';
  package: any;
  minPricePackage = 0;
  isLoading: Boolean = false;
  URL_WEB = environment.URL_WEB;
  bgcolor = true;
  constructor(private packagesService: PackageService, private toastr: ToastrService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.loadDetail(params['id']);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async loadDetail(id) {
    this.isLoading = true;
    this.packagesService.Show(id).then((response) => {
      this.package = response;
      this.isLoading = false;
      console.log(this.package);
    });
  }

  changeMinPricePackage(minPrice) {
    this.minPricePackage = minPrice;
  }

  changeTab() {
    if (this.activeTab === 'tab1') {
      this.activeTab = 'tab0';
      setTimeout(() => {
        this.activeTab = 'tab1';
      }, 100);
    } else {
      this.activeTab = 'tab1';
    }
  }

  scroll() {
    let el = document.getElementById('titleAvailability');
    el.scrollIntoView();
  }
}
