import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CityService } from '../../../../services/City/city.service';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-package-listado-search',
  templateUrl: './package-listado-search.component.html',
  styleUrls: ['./package-listado-search.component.css']
})
export class PackageListadoSearchComponent implements OnInit {
  @Output() searchPackages: EventEmitter<any> =  new EventEmitter();
  isLoadingCityFrom: boolean;
  isLoadingCityTo: boolean;
  citiesFrom: any = [];
  citiesTo: any = [];
  minDateFrom: Date;
  minDateTo: Date;
  bsConfig = { dateInputFormat: 'YYYY-MM', containerClass: 'theme-blue', showWeekNumbers: false };
  hasError = false;
  showFilters = false;
  cityFromText: string = '';
  cityToText: string = '';
  form = new FormGroup({
    departureCity: new FormControl(null, [Validators.required]),
    destinyCity: new FormControl(null, [Validators.required]),
    startDate: new FormControl(null, [Validators.required]),
    adults: new FormControl(1),
    children: new FormControl(0),
    babies: new FormControl(0)
  });
  constructor(private toastr: ToastrService, private cityService: CityService) {}

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };     
    container.setViewMode('month');
  }

  ngOnInit() {
    const searchFiltersParams = JSON.parse(localStorage.getItem('searchFields'));
    this.getCityList(searchFiltersParams.departureCity);
    this.form.get('startDate').setValue(moment(searchFiltersParams.startDate).format('YYYY-MM'));
    this.form.get('adults').setValue(searchFiltersParams.adults);
    this.form.get('children').setValue(searchFiltersParams.children);
    this.form.get('babies').setValue(searchFiltersParams.babies);
    this.form.get('departureCity').setValue(searchFiltersParams.departureCity);
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if(w > 500){
      this.showFilters = true;
    }
  }

  async getCityList(fromValue) {
    this.getCities('from');
    this.getCities('to', fromValue);
  }

  getCities(target, from = null) {
    const searchFiltersParams = JSON.parse(localStorage.getItem('searchFields'));
    this.cityService.get(target, 'package', from).toPromise().then(data => {
      const r: any = data;
      if (r.success) {
        if (target === 'from') {
          this.citiesFrom = r.data;
        } else if (target === 'to') {
          this.citiesTo = r.data;
          this.form.get('destinyCity').setValue(searchFiltersParams.destinyCity);
        }
        this.assignValuesCities();
      } else {
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      }
    })
    .catch((err) => {
      this.toastr.error('Ocurrió un error al cargar las ciudades, inténtelo de nuevo más tarde.');
    });
  }

  onChangeCityFrom(deviceValue){
    this.getCities('to', deviceValue);
  }

  onSubmitSearch() {
    if (this.form.valid) {
      this.hasError = false;
      this.form.get('startDate').setValue(moment(this.form.get('startDate').value).format('YYYY-MM'));
      localStorage.setItem('searchFields', JSON.stringify(this.form.value));
      this.searchPackages.emit(this.form.value);
      this.showFilters = false;
      this.assignValuesCities();
    } else {
      this.hasError = true;
    }
  }

  toggleShowFilters(){
    this.showFilters = !this.showFilters;
  }

  assignValuesCities(){
    const cityFromSelected = this.citiesFrom.filter(item => item.id === parseInt(this.form.get('departureCity').value));
    if(cityFromSelected.length > 0){
      this.cityFromText = `${cityFromSelected[0].nombre}, ${cityFromSelected[0].codigo}`
    }
    const cityToSelected = this.citiesTo.filter(item => item.id === parseInt(this.form.get('destinyCity').value));
    if(cityToSelected.length > 0){
      this.cityToText = `${cityToSelected[0].nombre}, ${cityToSelected[0].codigo}`
    }
  }
}
