import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {CityService} from '../../../../services/City/city.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {FlightService} from '../../../../services/Flight/flight.service';

declare var $: any;

@Component({
  selector: 'app-vuelos-listado-search',
  templateUrl: './vuelos-listado-search.component.html',
  styleUrls: ['./vuelos-listado-search.component.css']
})
export class VuelosListadoSearchComponent implements OnInit {
  @Input('model') params: Object;
  @Output('modelChange') paramsChange = new EventEmitter();
  @Output() onSuccess = new EventEmitter();
  showFilters = false;
  citiesFrom = [];
  citiesTo = [];
  minDateFrom: Date;
  minDateTo: Date;
  bsConfig = {dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue', showWeekNumbers: false};
  hasError = false;
  cityFromText: string = '';
  cityToText: string = '';
  form = new FormGroup({
    departureCity: new FormControl(null, [Validators.required]),
    destinyCity: new FormControl(null, [Validators.required]),
    departureDate: new FormControl(null, [Validators.required]),
    returnDate: new FormControl(null, [Validators.required]),
    adults: new FormControl(1, [Validators.required]),
    children: new FormControl(0, [Validators.required]),
    babies: new FormControl(0, [Validators.required]),
  });

  constructor(private toastr: ToastrService, private cityService: CityService, private flightService: FlightService, private router: Router) {
  }

  async ngOnInit() {
    await this.getCities('from');
    await this.getCities('to');

    const searchFields: any = this.flightService.getSearchParams();

    if (searchFields) {
      this.form.get('departureCity').setValue(searchFields.departureCity);
      this.form.get('destinyCity').setValue(searchFields.destinyCity);
      this.form.get('departureDate').setValue(searchFields.departureDate);
      this.form.get('returnDate').setValue(searchFields.returnDate);
      this.form.get('adults').setValue(searchFields.adults);
      this.form.get('children').setValue(searchFields.children);
      this.form.get('babies').setValue(searchFields.babies);
    }

    this.submitSearch();
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(w > 500){
      this.showFilters = true;
    }
  }

  getCities(target) {
    this.cityService.get(target,'flight').toPromise().then((response: any) => {
      if (response.success) {
        if (target === 'from') {
          this.citiesFrom = response.data;
        } else if (target === 'to') {
          this.citiesTo = response.data;
        }
        this.assignValuesCities();
      } else {
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      }
    }).catch((err) => {
      this.toastr.error('Ocurrió un error al cargar las ciudades, inténtelo de nuevo más tarde.');
    });
  }

  startChanged(e) {
    if (e != null) {
      const prevEndDate = moment(e).add(1, 'days');
      if (this.form.get('departureDate').value < e || this.form.get('returnDate').value === '') {
        this.form.get('returnDate').setValue(prevEndDate.toDate());
      }
      this.minDateTo = prevEndDate.toDate();
    }
  }

  submitSearch() {
    if (this.form.valid) {
      Object.assign(this.params, this.form.value);
      this.flightService.saveSearch(this.params);
      this.paramsChange.emit(this.params);
      this.assignValuesCities();
      this.showFilters = false;
    }
  }

  toggleShowFilters(){
    this.showFilters = !this.showFilters;
  }

  assignValuesCities(){
    const cityFromSelected = this.citiesFrom.filter(item => item.id === parseInt(this.form.get('departureCity').value));
    if(cityFromSelected.length > 0){
      this.cityFromText = `${cityFromSelected[0].nombre}, ${cityFromSelected[0].codigo}`
    }
    const cityToSelected = this.citiesTo.filter(item => item.id === parseInt(this.form.get('destinyCity').value));
    if(cityToSelected.length > 0){
      this.cityToText = `${cityToSelected[0].nombre}, ${cityToSelected[0].codigo}`
    }
  }
}
