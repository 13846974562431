import { Component, OnInit } from '@angular/core';
import {Flight} from '../../models/flight.model';
import {ToastrService} from 'ngx-toastr';
import {FlightService} from '../../services/Flight/flight.service';
import * as moment from 'moment';

@Component({
  selector: 'app-flights-list',
  templateUrl: './flights-list.component.html',
  styleUrls: ['./flights-list.component.css']
})
export class FlightsListComponent implements OnInit {
  searchResults: Array<any>;
  searchParams = {};
  filterParams = {
    direction: null,
    scales: null,
    priceOrder: null,
    airlines: null,
  };
  filteredList: Array<any>;
  flightsList: Array<Flight>;
  showFilters: Boolean = false;
  constructor(private toastr: ToastrService, private flightService: FlightService) {
  }

  ngOnInit() {
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(w > 500){
      this.showFilters = true;
    }
  }

  requestFlies() {
    this.flightService.getSearch().toPromise().then((response: Array<Flight>) => {
      this.flightsList = response;
    }).catch(err => {
      this.toastr.error('Ocurrió un error al cargar los resultados, inténtelo de nuevo más tarde.');
    });

    /*this.flightService.get(queryParams).toPromise().then((response: any) => {
      if (response.success) {
        this.searchResults = response.data.map((fly) => {
          const DIRECTIONS = fly.directions;
          const FROM = DIRECTIONS[0];
          fly.airline = FROM.transits[0].airline;
          fly.timecheckin = FROM.transits[0].timecheckin;
          fly.departureTime = FROM.transits[0].time;
          fly.departureDate = moment(FROM.transits[0].date).format('ddd, DD MMM');
          fly.arrivalGate = FROM.transits[FROM.transits.length - 1].gate;
          fly.arrivalTime = FROM.transits[FROM.transits.length - 1].arrival_time;
          fly.arrivalDate = moment(FROM.transits[FROM.transits.length - 1].arrival_date).format('ddd, DD MMM');
          fly.scalesAmount = FROM.transits.length - 1;
          fly.price = 0;

          for (let i = 0; i < DIRECTIONS.length; i++) {
            const direction = DIRECTIONS[i];
            fly.price += direction.costAdult * this.searchParams['adults'];
            fly.price += direction.costChild * this.searchParams['children'];
            fly.price += direction.costInfant * this.searchParams['babies'];
          }

          fly.adults = this.searchParams['adults'];
          fly.children = this.searchParams['children'];
          fly.babies = this.searchParams['babies'];

          return fly;
        });
        this.filterRequest();
      } else {
        this.toastr.error('Ocurrió un error al cargar los resultados, inténtelo de nuevo más tarde.');
      }
    }).catch(err => {
      this.toastr.error('Ocurrió un error al cargar los resultados, inténtelo de nuevo más tarde.');
    });*/
  }

  filterRequest() {
    if (this.searchResults) {
      this.filteredList = this.searchResults.filter((fly) => {
          const airline = fly.directions[0].transits[0].airline;
          let valid = true;

          // Directions filter
          if (this.filterParams.direction) {
            if (fly.direction !== this.filterParams.direction) {
              valid = false;
            }
          }
          // Scales amount filter
          if (this.filterParams.scales) {
            if (parseInt(this.filterParams.scales, 10) === 0) {
              if (fly.scalesAmount !== 0) {
                valid = false;
              }
            } else if (parseInt(this.filterParams.scales, 10) === 1) {
              if (fly.scalesAmount !== 1) {
                valid = false;
              }
            } else if (parseInt(this.filterParams.scales, 10) === -1) {
              if (fly.scalesAmount < 2) {
                valid = false;
              }
            }
          }
          // Price range filter
          if (this.filterParams['price']) {
            const minPrice = this.filterParams['price'][0];
            const maxPrice = this.filterParams['price'][1];
            if (fly.price < minPrice || fly.price > maxPrice) {
              valid = false;
            }
          }

          // Airlines filter
          if (this.filterParams.airlines && this.filterParams.airlines.length > 0) {
            console.log(this.filterParams.airlines.indexOf(airline.id) === -1);
            if (this.filterParams.airlines.indexOf(airline.id) === -1) {
              valid = false;
            }
          }

          return valid;
        }
      );

      // Price sort
      if (this.filterParams.priceOrder) {
        this.filteredList = this.filteredList.sort((a, b) => {
          if (this.filterParams.priceOrder === 'min') {
            return a.price - b.price;
          } else {
            return b.price - a.price;
          }
        });
      }
    }
  }
}
