import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Flight} from '../../models/flight.model';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FlightService {
  private apiUrl: string = environment.APIURL;
  private searchParams = {
    departureCity: null,
    destinyCity: null,
    departureDate: null,
    returnDate: null,
    adults: null,
    children: null,
    babies: null,
    direction: null,
  };

  constructor(private http: HttpClient) {
    const localSearchFlightParams = JSON.parse(localStorage.getItem('searchFlightParams'));

    if (localSearchFlightParams) {
      this.searchParams = localSearchFlightParams;
    }
  }

  get(params): Observable<Array<Flight>> {
    console.log(params);
    // const url = `${this.apiUrl}admin/flights?search=city_id_from:${params.departureCity};city_id_to:${params.destinyCity}&searchJoin=and&start=${params.departureDate}&end=${params.returnDate}`;
    const url = `${this.apiUrl}flights?start=${params.departureDate}&end=${params.returnDate}&city_id_from=${params.departureCity}&city_id_to=${params.destinyCity}&type=roundtrip`;

    return this.http.get(url).pipe(
      map((res: any): any => {
          return res.data.map((chunk: any) => {
            return {
              departure: new Flight().deserialize(chunk.departure),
              return: chunk.return.map(flight => new Flight().deserialize(flight)),
            };
          });
        }
      )
    );
  }

  saveSearch(params) {
    this.searchParams = params;
    this.searchParams.departureDate = moment(this.searchParams.departureDate).format('YYYY-MM-DD');
    this.searchParams.returnDate = moment(this.searchParams.returnDate).format('YYYY-MM-DD');
    localStorage.setItem('searchFlightParams', JSON.stringify(this.searchParams));
  }

  getSearch(): Observable<Array<Flight>> {
    return this.get(this.searchParams);
  }

  getSearchParams() {
    return this.searchParams;
  }

  calcPrice(flight: Flight) {
    let price = 0;
    price += flight.priceAdult * this.getSearchParams().adults;
    price += flight.priceChild * this.getSearchParams().children;
    price += flight.priceInfant * this.getSearchParams().babies;
    return price;
  }
}
