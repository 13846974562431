import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {CartFlight} from '../../../models/cart-flight.model';

@Component({
  selector: 'app-item-fly',
  templateUrl: './item-fly.component.html',
  styleUrls: ['./item-fly.component.css']
})
export class ItemFlyComponent implements OnInit {
  @Output() removeItem: EventEmitter<any> = new EventEmitter();
  @Input() item: CartFlight;
  @Input() itemSecondary: CartFlight;
  @Input() index: any;
  URL_WEB = environment.URL_WEB;

  constructor() {
  }

  ngOnInit() {
  }

  remove() {
    this.removeItem.emit(this.index);
  }
}
