import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AirlineService} from '../../../../services/Airline/airline.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-vuelos-listado-filters',
  templateUrl: './vuelos-listado-filters.component.html',
  styleUrls: ['./vuelos-listado-filters.component.css']
})
export class VuelosListadoFiltersComponent implements OnInit {
  @Input('resultsAmount') resultsAmount: Number;
  @Input('model') params: any;
  @Output('modelChange') paramsChange = new EventEmitter();
  airlines: Array<any>;
  URL_WEB = environment.URL_WEB;

  constructor(private toastr: ToastrService, private airlineService: AirlineService) {
  }

  ngOnInit() {
    this.airlineService.get().then((response: any) => {
      this.airlines = response.data;
    }).catch(() => {
      this.toastr.error('Ocurrió un error al cargar las ciudades, inténtelo de nuevo más tarde.');
    });

    const $priceSlider = $('#price-range');
    $priceSlider.slider({
      range: true,
      min: 0,
      max: 10000,
      values: [0, 10000],
      slide: (event, ui) => {
        this.params['price'] = ui.values;
        this.handleChange();
        $('#amount').val('$ ' + ui.values[0] + ' - $ ' + ui.values[1]);
      }
    });
    this.params['price'] = $priceSlider.slider('values');
    this.handleChange();
    $('#amount').val('$ ' + $priceSlider.slider('values', 0) + ' - $ ' + $priceSlider.slider('values', 1));
  }

  airlineChecked() {
    this.params['airlines'] = this.airlines.filter(airline => {
      return airline.checked;
    }).map(airline => {
      return airline.id;
    });
    this.handleChange();
  }

  handleChange() {
    this.paramsChange.emit(this.params);
  }
}
