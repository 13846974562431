import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { Order } from "../../../models/order.model";
import { environment } from "../../../../environments/environment";
import { PRODUCT_TYPES } from "../../../constants/productTypes";

@Component({
  selector: "app-booking-center-bar-item",
  templateUrl: "./booking-center-bar-item.component.html",
  styleUrls: ["./booking-center-bar-item.component.css"],
})
export class BookingCenterBarItemComponent implements OnInit {
  @Input() order: Order;
  @Output() onOpenModalPayment: EventEmitter<any> = new EventEmitter();
  @ViewChild("modalAddPayment") modalAddPayment;
  URL_WEB = environment.URL_WEB;
  PRODUCT_TYPES = PRODUCT_TYPES;
  constructor() {}

  ngOnInit() {}

  handleOpenModalPayment() {
    this.onOpenModalPayment.emit(this.order);
  }
}
