import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CreditCard } from '../../../../models/creditcard.model';
import { User } from '../../../../models/user.model';
import {UserService} from '../../../../services/User/user.service';
import {CreditCardService} from '../../../../services/CreditCard/credit-card.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-creditcard-card',
  templateUrl: './creditcard-card.component.html',
  styleUrls: ['./creditcard-card.component.css']
})
export class CreditcardCardComponent implements OnInit {
	@Input() card: CreditCard;
	@Output() loadCreditCards: EventEmitter<any> =  new EventEmitter();
	isLoading = false;
  constructor(
    private userService: UserService,
    private creditCardService: CreditCardService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    
  }

  async deleteCard(){
  	const card_id = this.card.id;
    this.isLoading = true;
    const user: User = await this.userService.UserDataLogin();
    const data = {
      client_id: user.id,
      card_id: card_id
    }
    
    this.creditCardService.delete(data).toPromise()
    .then(response => {
      this.loadCreditCards.emit();
      this.isLoading = false;
    })
    .catch((err) => {
      this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      this.isLoading = false;
    });
  }
}
