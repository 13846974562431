import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ExchangerateService } from "../../../services/ExchangeRate/exchangerate.service";
import { CartService } from "../../../services/Cart/cart.service";
import { PRODUCT_TYPES } from "../../../constants/productTypes";

@Component({
  selector: "app-car-table-row",
  templateUrl: "./car-table-row.component.html",
  styleUrls: ["./car-table-row.component.css"],
})
export class CarTableRowComponent implements OnInit {
  @Input() item: any;
  @Input() type: number;
  @Input() title: string;
  @Input() index: number;
  @Input() price: number;
  @Input() isCheckout: boolean = false;
  @Input() isConfirmation: boolean = false;
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  bookWithPrice = 0;
  PRODUCT_TYPES = PRODUCT_TYPES;
  constructor(
    public exchangerateService: ExchangerateService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.item.product_type === PRODUCT_TYPES.PACKAGES &&
      this.calculateBookWithPrice();
  }

  calculateBookWithPrice() {
    const { book_with, calculatedPrice } = this.item.availability;
    this.bookWithPrice = this.cartService.calculateBookPrice(
      book_with,
      calculatedPrice
    );
  }

  handleRemove() {
    this.onRemove.emit(this.index);
  }
}
