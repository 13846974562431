import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ToolsService } from '../../../services/Tools/tools.service';
declare var $: any;

@Component({
  selector: 'app-package-detail-includes-hotel-item',
  templateUrl: './package-detail-includes-hotel-item.component.html',
  styleUrls: ['./package-detail-includes-hotel-item.component.css']
})
export class PackageDetailIncludesHotelItemComponent implements OnInit {
  @Input() hotel;
  @Input() showOnlyName: Boolean = false;
  URL_WEB = environment.URL_WEB;
  stars: any = [];
  nonStars: any = [];
  serv_includes = [];
  constructor(private tools: ToolsService) { }

  ngOnInit() {
    setTimeout( () => {
      const includes = JSON.parse(JSON.stringify(this.hotel.hotel_amenities));
      const numElementsIncluded = Math.round(includes.length / 2);
      this.serv_includes = this.tools.chunkArray(includes, numElementsIncluded);      
    }, 1000);
    
    for (let cont = 0; cont < this.hotel.stars; cont++) {
      this.stars.push(cont);
    }
    for (let cont = 5; cont > this.hotel.stars; cont--) {
      this.nonStars.push(cont);
    }
  }
}
