import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private apiUrl: string = environment.APIURL;
  constructor(private http: HttpClient) { }

  get(target, type, cityIdFrom = null) {
    let t = (target !== '') ? `?target=${target}` : '';
    let ty = type !== '' ? target !== '' ? `&type=${type}` : `?type=${type}` : '';
    let from = cityIdFrom !== null ? `&city_from_id=${cityIdFrom}` : '';
    const url = `${this.apiUrl}cities${t}${ty}${from}`;
    return this.http.get(url);
  }
}
