import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userregister',
  templateUrl: './userregister.component.html',
  styleUrls: ['./userregister.component.css']
})
export class UserregisterComponent implements OnInit {
  isCheckout: boolean = false;
  viewDefault: string = 'signup'
  view: string = 'signup'
  constructor() { }

  ngOnInit() {
  }

  changeView(view){
    this.view = view;
  }

}
