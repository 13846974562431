import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { OrderService } from "../../../services/Order/order.service";
import { Order } from "../../../models/order.model";
import { environment } from "../../../../environments/environment";
import { PRODUCT_TYPES } from "../../../constants/productTypes";
import { ModalDirective } from "ngx-bootstrap/modal";
@Component({
  selector: "app-booking-center-bar",
  templateUrl: "./booking-center-bar.component.html",
  styleUrls: ["./booking-center-bar.component.css"],
})
export class BookingCenterBarComponent implements AfterViewInit {
  @ViewChild(ModalDirective) modal: ModalDirective;
  @ViewChild("modalPaymentsList") modalPaymentsList;
  @ViewChild("modalAddPayment") modalAddPayment;
  URL_WEB = environment.URL_WEB;
  PRODUCT_TYPES = PRODUCT_TYPES;
  filterParams = {
    type: null,
  };

  orders: Array<Order>;
  orderSelected: Order;

  constructor(private orderService: OrderService) {}

  async ngAfterViewInit() {
    this.orders = await this.orderService.getAll();
  }

  openModal({ orderId }) {
    // this.orderId = orderId;
    this.modalPaymentsList.show();
  }

  closeModal() {
    this.modalPaymentsList.hide();
  }

  openModalNewPayment(order) {
    this.orderSelected = order;
    this.modalAddPayment.show();
  }

  closeModalNewPayment() {
    this.modalAddPayment.hide();
  }

  clearOrderSelected() {
    this.orderSelected = null;
  }
}
