import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home2-search-tabs',
  templateUrl: './home2-search-tabs.component.html',
  styleUrls: ['./home2-search-tabs.component.css']
})
export class Home2SearchTabsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
