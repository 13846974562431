import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlightBuilderComponent} from './flight-builder/flight-builder.component';
import {AccordionModule, ModalModule, TabsModule} from 'ngx-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {FlightReserveCardComponent} from './flight-reserve-card/flight-reserve-card.component';
import { FlightResumeCardComponent } from './flight-resume-card/flight-resume-card.component';
import {ItemFlyComponent} from '../shopping/item-fly/item-fly.component';

@NgModule({
  declarations: [FlightBuilderComponent, FlightReserveCardComponent, FlightResumeCardComponent, ItemFlyComponent],
  exports: [
    FlightBuilderComponent,
    ItemFlyComponent,
    FlightResumeCardComponent,
  ],
  imports: [
    CommonModule,
    TabsModule,
    TranslateModule,
    ModalModule,
    AccordionModule,
  ]
})
export class FlightModule {
}
