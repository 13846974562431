import {Deserializable} from './deserializable.model';

export class CartItem implements Deserializable {
  id: number;
  product_id: number;
  type: string;
  price: number;
  name: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
