import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Order } from "../../../models/order.model";
import { environment } from "../../../../environments/environment";
import { orderStatus } from "../../../constants/orderStatus";

@Component({
  selector: "app-order-card-flight",
  templateUrl: "./order-card-flight.component.html",
  styleUrls: ["./order-card-flight.component.css"],
})
export class OrderCardFlightComponent implements OnInit {
  @Input() order: Order;
  @Output() openModal: EventEmitter<any> = new EventEmitter();

  URL_WEB = environment.URL_WEB;
  ORDER_STATUS = orderStatus;
  flightDeparture: any;
  flightReturn: any;
  constructor() {}

  ngOnInit() {
    const data_departure = this.order.details[0].product_data;
    const data_return = this.order.details[1].product_data;
    const numLastTransit = data_departure.directions[0].transits.length - 1;
    this.flightDeparture = {
      type: data_departure.flightType,
      hours: data_departure.hours,
      charter: {
        logo: data_departure.directions[0].transits[0].airline.logo,
      },
      cityDeparture: {
        name: data_departure.directions[0].transits[0].city.nombre,
        code: data_departure.directions[0].transits[0].city.codigo,
      },
      firstConnection: {
        departureDate: data_departure.directions[0].transits[0].date,
        departureTime: data_departure.directions[0].transits[0].time,
        number: data_departure.directions[0].transits[0].flightNumber,
        checkinTime: data_departure.directions[0].transits[0].timecheckin,
        gate: data_departure.directions[0].transits[0].gate,
        airline: {
          name: data_departure.directions[0].transits[0].airline.name,
        },
        airport: {
          name: data_departure.directions[0].transits[0].airport_from.name,
        },
      },
      cityArrival: {
        name: data_departure.directions[0].transits[0].city_to.nombre,
        code: data_departure.directions[0].transits[0].city_to.codigo,
      },
      lastConnection: {
        arrivalDate: data_departure.directions[0].transits[numLastTransit].date,
        arrivalTime: data_departure.directions[0].transits[numLastTransit].time,
        airline: {
          name:
            data_departure.directions[0].transits[numLastTransit].airline.name,
        },
        airport_arrival: {
          name:
            data_departure.directions[0].transits[numLastTransit].airport_from
              .name,
        },
      },
      connections: data_departure.directions[0].transits,
    };

    this.flightReturn = {
      type: data_return.flightType,
      hours: data_return.hours,
      charter: {
        logo: data_return.directions[0].transits[0].airline.logo,
      },
      cityDeparture: {
        name: data_return.directions[0].transits[0].city.nombre,
        code: data_return.directions[0].transits[0].city.codigo,
      },
      firstConnection: {
        departureDate: data_return.directions[0].transits[0].date,
        departureTime: data_return.directions[0].transits[0].time,
        number: data_return.directions[0].transits[0].flightNumber,
        checkinTime: data_return.directions[0].transits[0].timecheckin,
        gate: data_return.directions[0].transits[0].gate,
        airline: {
          name: data_return.directions[0].transits[0].airline.name,
        },
        airport: {
          name: data_return.directions[0].transits[0].airport_from.name,
        },
      },
      cityArrival: {
        name: data_return.directions[0].transits[0].city_to.nombre,
        code: data_return.directions[0].transits[0].city_to.codigo,
      },
      lastConnection: {
        arrivalDate: data_return.directions[0].transits[numLastTransit].date,
        arrivalTime: data_return.directions[0].transits[numLastTransit].time,
        airline: {
          name: data_return.directions[0].transits[numLastTransit].airline.name,
        },
        airport_arrival: {
          name:
            data_return.directions[0].transits[numLastTransit].airport_from
              .name,
        },
      },
      connections: data_return.directions[0].transits,
    };
  }

  openPaymentsModal() {
    this.openModal.emit({ orderId: this.order.id });
  }
}
