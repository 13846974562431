import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../../services/Tools/tools.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-package-detail-tab-details',
  templateUrl: './package-detail-tab-details.component.html',
  styleUrls: ['./package-detail-tab-details.component.css']
})
export class PackageDetailTabDetailsComponent implements OnInit {
  @Input() package: any;
  URL_WEB = environment.URL_WEB;
  constructor(private tools: ToolsService) { }

  ngOnInit() {
    // 
  }

}
