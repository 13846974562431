import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { UserService } from "../../services/User/user.service";
import { CartService } from "../../services/Cart/cart.service";
import { OrderService } from "../../services/Order/order.service";
import { PaymentService } from "../../services/Payment/payment.service";
import { ToastrService } from "ngx-toastr";
import { ShareDataService } from "../../services/ShareData/share-data.service";
import { ExchangerateService } from "../../services/ExchangeRate/exchangerate.service";
import { Router } from "@angular/router";
import { PRODUCT_TYPES } from "../../constants/productTypes";
@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
})
export class CheckoutComponent implements OnInit {
  isLogged = false;
  emailGuest: string = "";
  activeTab = 2;
  dataInfo: any;
  orderId = 0;
  numOrder: string = "";
  @ViewChild("loginModal") public loginModal;
  constructor(
    private exchangerateService: ExchangerateService,
    private paymentService: PaymentService,
    private cartService: CartService,
    private userService: UserService,
    private orderService: OrderService,
    private toastr: ToastrService,
    private router: Router,
    private shareData: ShareDataService,
    private deviceService: DeviceDetectorService
  ) {}

  async ngOnInit() {}

  async ngAfterViewInit() {
    this.isLogged = this.userService.isLogged;
    await this.userService.getGuestUser().then((resp) => {
      this.emailGuest = resp;
    });

    if (!this.isLogged && !this.emailGuest) {
      if (this.deviceService.isMobile()) {
        this.router.navigateByUrl("/checkout/login");
      } else {
        this.loginModal.show();
      }
    }
  }

  closeModalLogin() {
    this.loginModal.hide();
  }

  saveInfoTab(dataInfo) {
    this.dataInfo = dataInfo;
    this.changeTab(3);
  }

  async savePaymentTab(dataPayment) {
    const cart = await this.cartService.loadCart();
    const userData = await this.userService.UserDataLogin();
    if (this.orderId === 0) {
      await this.createOrder(null, this.dataInfo);
    }

    const data = {
      order_id: this.orderId,
      amount: cart.to_pay,
      source_id: dataPayment.nonceCard,
      currency: "USD",
      zipcode: dataPayment.zipCode,
      client_id: userData.id,
      cardholder_name: dataPayment.name,
      type: "AUTHORIZATION",
      save_card: true,
    };

    this.paymentService
      .squareCreateCharge(data)
      // .toPromise()
      .then((response) => {
        const r: any = response;
        if (r.success) {
          localStorage.removeItem("cart");
          localStorage.removeItem("guest");
          this.router.navigate(["/confirmation", this.numOrder]);
        } else {
          this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
        }
      })
      .catch((err) => {
        this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
      });
  }

  async createOrder(transaction_id, dataPayment) {
    //en datapayment van a venir los datos del viajero
    const userData = await this.userService.UserDataLogin();
    const cart = await this.cartService.loadCart();
    const items = this.getItemsCart(cart);
    const total = cart.total;
    const subtotal = cart.subtotal;
    const paid = cart.to_pay;
    //TODO: Aqui manejar modelo de la orden y detalle de la orden
    const order = {
      client_id: userData.id,
      cost: cart.cost,
      subtotal,
      discount: cart.discount,
      coupon_id: cart.coupon_id,
      total,
      paid,
      is_reserved: paid < total ? 1 : 0,
      paymentmethod_id: 1,
      comments: "",
      sale_channel_id: 1, //TODO Aqui manejar un enum de tipos de canal de venta
      sale_country: 123, //MEXICO
      currency: this.exchangerateService.currentCurrency,
      transaction_id: transaction_id,
      items,
      contact_email: dataPayment.email,
      contact_phone: dataPayment.phone,
      travelers: dataPayment.travelers,
    };
    return this.orderService
      .create(order)
      .toPromise()
      .then((response) => {
        const r: any = response;
        // this.shareData.serviceData = r.data;
        this.orderId = r.data.id;
        this.numOrder = r.data.num_order;
      })
      .catch((err) => {
        this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
      });
  }

  getItemsCart(cart) {
    const products = [];
    cart.items.map((product) => {
      const subtotal = product.subtotal;
      const total = product.price;

      const prod = {
        product_type: product.product_type,
        product_id: product.product_id,
        subproduct_id: product.subproduct_id,
        adults: product.adults,
        children: product.children,
        babies: product.babies,
        cost: product.cost,
        subtotal: subtotal,
        discount: product.discount,
        total: total,
      };
      if (product.product_type === PRODUCT_TYPES.HOTELS) {
        products.push({
          ...prod,
          hotel: product.hotel,
          room_selected: product.roomSelected,
        });
      } else {
        products.push(prod);
      }
    });
    return products;
  }

  changeTab(tab) {
    this.activeTab = tab;
  }

  clickToChangeTab(tab) {
    if (this.activeTab > tab) {
      this.changeTab(tab);
    }
  }
}
