import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {User} from '../../models/user.model';
import {UserService} from '../User/user.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private apiUrl: string = environment.APIURL;

  constructor(private http: HttpClient, private userService: UserService) {
  }

  getCategories() {
    const url = `${this.apiUrl}help_desk_categories`;

    return this.http.get(url).pipe(map((response: any) => {
      return response.data;
    })).toPromise();
  }

  getSubcategories(category_id) {
    const url = `${this.apiUrl}help_desk_categories/${category_id}/sub_categories`;

    return this.http.get(url).pipe(map((response: any) => {
      return response.data;
    })).toPromise();
  }

  async createTicket(form) {
    const user: User = await this.userService.getCurrent();

    const clonedForm = {...form};
    clonedForm.client_id = user.id;
    clonedForm.status_id = 1;

    const url = `${this.apiUrl}help_desk_requirements`;

    return this.http.post(url, clonedForm).pipe(map((response: any) => {
      return response.data;
    })).toPromise();
  }

  async getAll() {
    const user: User = await this.userService.getCurrent();

    const url = `${this.apiUrl}help_desk_requirements_byuser/${user.id}`;

    return await this.http.get(url).pipe(map((response: any) => {
      return response.data;
    })).toPromise();
  }

  async getSuccess() {
    const user: User = await this.userService.getCurrent();

    const url = `${this.apiUrl}tickets?user_id=${user.id}`;

    return await this.http.get(url).pipe(map((response: any) => {
      return response.data;
    })).toPromise();
  }

  async getPending() {
    const user: User = await this.userService.getCurrent();
    const url = `${this.apiUrl}tickets?user_id=${user.id}`;

    return await this.http.get(url).pipe(map((response: any) => {
      return response.data;
    })).toPromise();
  }
}
