import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asistenciabox',
  templateUrl: './asistenciabox.component.html',
  styleUrls: ['./asistenciabox.component.css']
})
export class AsistenciaboxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
