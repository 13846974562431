import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-search-vuelos',
  templateUrl: './search-vuelos.component.html',
  styleUrls: ['./search-vuelos.component.css']
})
export class SearchVuelosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#departure_date').datepicker({ minDate: -0, maxDate: '+3M' });
      $('#return_date').datepicker({ minDate: -0, maxDate: '+3M' });
      $('#adult_count').spinner({
        min: 1
      });
      $('#child_count').spinner( {
        min: 1
      });
      $('#babies_count').spinner( {
        min: 1
      });
      $('.selectpicker').selectpicker({
        style: 'custom-select-button'
      });
  }

}
