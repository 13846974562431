import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-help-ticket-card',
  templateUrl: './help-ticket-card.component.html',
  styleUrls: ['./help-ticket-card.component.css']
})
export class HelpTicketCardComponent implements OnInit {
  @Input() ticket;
  constructor() { }

  ngOnInit() {
  }

}
