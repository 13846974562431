import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.css']
})
export class UserloginComponent implements OnInit {
  @Input() isCheckout: boolean = false;
  viewDefault: string = 'login'
  view: string = 'login'
  constructor() { }

  ngOnInit() {
  }

  changeView(view){
    this.view = view;
  }

}
