import { Component, OnInit, Input } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { CartService } from "../../../services/Cart/cart.service";
import { ExchangerateService } from "../../../services/ExchangeRate/exchangerate.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { PRODUCT_TYPES } from "../../../constants/productTypes";
import { Store, select } from "@ngrx/store";
// declare var $: any;

@Component({
  selector: "app-hotel-detail-tab-rooms",
  templateUrl: "./hotel-detail-tab-rooms.component.html",
  styleUrls: ["./hotel-detail-tab-rooms.component.css"],
})
export class HotelDetailTabRoomsComponent implements OnInit {
  @Input() listRooms: any = [];
  constructor(
    private store: Store<{ hotel: any }>,
    public exchangerateService: ExchangerateService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private cartService: CartService
  ) {}

  ngOnInit() {}

  async addItemToCart(room) {
    let msgItemAdded;
    await this.translate
      .get("item_added_cart")
      .toPromise()
      .then((resp) => (msgItemAdded = resp));

    this.store.pipe(select("hotel")).subscribe(async (hotel) => {
      const { searchData, hotelSelected } = hotel;
      const item = {
        date_from: searchData.date_from,
        date_to: searchData.date_to,
        product_type: PRODUCT_TYPES.HOTELS,
        hotel: hotelSelected,
        roomSelected: room,
        num_rooms: searchData.num_rooms,
        adults: searchData.adults,
        children: searchData.children,
        babies: searchData.babies,
        cost: room.cost,
        discount: 0,
        subtotal: room.cost,
        price: room.cost,
      };
      await this.cartService.addItem(item);
      this.router.navigateByUrl("/cart");
    });
  }
}
