import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-top-bar-light',
  templateUrl: './top-bar-light.component.html',
  styleUrls: ['./top-bar-light.component.css']
})
export class TopBarLightComponent implements OnInit {

  constructor(private translate: TranslateService) { }
  currency: String = 'USD';
  language: String = 'Español(MX)';
  ngOnInit() {

    $('.selectpicker').selectpicker({
      style: 'custom-select-button'
    });
  }

  changeLang(lang) {
    this.translate.use(lang);
    if (lang === 'es') {
      this.language = 'Español(MX)';
    } else if (lang === 'en') {
      this.language = 'English(US)';
    }
  }

  changeCurrency(currency) {
    this.currency = currency;
  }

}
