import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { UserService } from "../User/user.service";
import { User } from "../../models/user.model";
import { OrderPayment } from "../../models/orderPayment.model";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  private apiUrl: string = environment.APIURL;
  constructor(private http: HttpClient, private userService: UserService) {}

  async squareCreateCharge(data) {
    const url = `${this.apiUrl}payment/charge/squareup`;
    return await this.http.post(url, data).toPromise();
  }

  async list(orderId): Promise<any> {
    const user: User = await this.userService.getCurrent();
    const url = `${this.apiUrl}orders/${orderId}/payments?client_id=${user.id}`;
    return await this.http
      .get(url)
      .pipe(
        map((response: any) => {
          return response.data.map((orderPayment) => {
            return new OrderPayment().deserialize(orderPayment);
          });
        })
      )
      .toPromise();
  }
}
