import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-package-detail-tab-itinerary',
  templateUrl: './package-detail-tab-itinerary.component.html',
  styleUrls: ['./package-detail-tab-itinerary.component.css']
})
export class PackageDetailTabItineraryComponent implements OnInit {
  @Input() itinerary: any;
  @Input() nights: number;
  URL_WEB = environment.URL_WEB;
  constructor() { }

  ngOnInit() {
  }

}
