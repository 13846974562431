import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  private apiUrl: string = environment.APIURL;
  constructor(private http: HttpClient) { }

  Show(id) {
    const url = `${this.apiUrl}packages/${id}`;
    return this.http.get(url).pipe(map((response: any) => {
      return response.data;
    })).toPromise();
  }

  searchPackages(data) {
    // tslint:disable-next-line:max-line-length
    const url = `${this.apiUrl}packages?start=${data.startDate}&departure_city=${data.departureCity}&destiny_city=${data.destinyCity}&adults=${data.adults}&children=${data.children}`;
    return this.http.get(url);
  }

  searchRooms(data) {
    // tslint:disable-next-line:max-line-length
    const url = `${this.apiUrl}packages/${data.packageId}/availaility?start=${data.startDate}&adults=${data.adults}&children=${data.children}`;
    return this.http.get(url);
  }

  getPackageTypes(){
    const url = `${this.apiUrl}package_types`;
    return this.http.get(url);
  }


}
