import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.css']
})
export class GuestComponent implements OnInit {
  @Output() changeView: EventEmitter<any> =  new EventEmitter();
  @Input() viewDefault: string;
  @Input() isCheckout: boolean = false;
  formLoginHasError = false;
  formLogin = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    checkTerms: new FormControl(null,[Validators.required])
  })
  constructor() { }

  ngOnInit() {
  }

  handleChangeView(view) {
    this.changeView.emit(view);
  }

  returnLastScreen(){
    this.changeView.emit(this.viewDefault)
  }

  onSubmitLogin(){
    this.formLoginHasError = !this.formLogin.valid;
    if(this.formLogin.valid){
      this.sendLogin();
    }
  }

  async sendLogin(){
    localStorage.setItem('guest',this.formLogin.get('email').value);
    if(this.isCheckout){
      document.location.href = "/checkout";
    }else{
      document.location.href = "/";
    }
  }

}
