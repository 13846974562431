import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-detail-tab-features',
  templateUrl: './car-detail-tab-features.component.html',
  styleUrls: ['./car-detail-tab-features.component.css']
})
export class CarDetailTabFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
