import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-car-listado-search',
  templateUrl: './car-listado-search.component.html',
  styleUrls: ['./car-listado-search.component.css']
})
export class CarListadoSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(function() {
      'use strict';
      $( '#check_in' ).datepicker({ minDate: -0, maxDate: '+3M' });
      $( '#check_out' ).datepicker({ minDate: -0, maxDate: '+3M' });
      $( '#adult_count' ).spinner({
        min: 1
      });
      $('.selectpicker').selectpicker({
        style: 'custom-select-button'
      });
    });
  }

}
