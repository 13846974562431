import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-car-hot-sale',
  templateUrl: './car-hot-sale.component.html',
  styleUrls: ['./car-hot-sale.component.css']
})
export class CarHotSaleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
        if (jQuery("#car-list").length){
    		 	jQuery("#car-list").owlCarousel({
    		 		loop:true,
    		 		margin:30,
    		 		responsiveClass:true,
    		 		autoHeight:true,
    		 		autoplay:true,
    		 		autoplayTimeout:5000,
    		 		navigation:false,
    		 		stopOnHover:true,
    		 		responsive:{
    		 			0:{
    		 				items:1,
    		 				loop:true
    		 			},
    		 			600:{
    		 				items:2,
    		 				loop:true
    		 			},
    		 			1000:{
    		 				items:4,
    		 				loop:true
    		 			}
    		 		}
    		 	});
    		 }
  }

}
