import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-package-hot-sale',
  templateUrl: './package-hot-sale.component.html',
  styleUrls: ['./package-hot-sale.component.css']
})
export class PackageHotSaleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    if (jQuery("#post-list").length){
		 	jQuery("#post-list").owlCarousel({
		 		loop:true,
		 		margin:30,
		 		responsiveClass:true,
		 		autoHeight:true,
		 		autoplay:true,
		 		autoplayTimeout:5000,
		 		navigation:false,
		 		stopOnHover:true,
		 		responsive:{
		 			0:{
		 				items:1,
		 				loop:true
		 			},
		 			600:{
		 				items:2,
		 				loop:true
		 			},
		 			1000:{
		 				items:4,
		 				loop:true
		 			}
		 		}
		 	});
		 }
  }

}
