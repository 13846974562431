import {Injectable} from '@angular/core';
import {User} from '../../models/user.model';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../User/user.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  private apiUrl: string = environment.APIURL;

  constructor(private http: HttpClient, private userService: UserService) {
  }

  async getAll() {
    const user: User = await this.userService.getCurrent();
    const url = `${this.apiUrl}customer/${user.id}/cards/list`;

    return await this.http.get(url).pipe(map((response: any) => {
      return response.data;
    })).toPromise();
  }

  delete(data){
    const url = `${this.apiUrl}customer/cards/delete`;
    return this.http.post(url, data);
  }

  save(data){
    const url = `${this.apiUrl}customer/cards/save`;
    return this.http.post(url, data);
  }
}
