import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/User/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user: any;

  constructor(private userService: UserService) { }

  async ngOnInit() {
    this.user = await this.userService.UserDataLogin();
  }

}
