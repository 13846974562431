import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { UserService } from '../../../../services/User/user.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Output() changeView: EventEmitter<any> =  new EventEmitter();
  @Input() isCheckout: boolean = false;
  isLoading = false;
  formLoginHasError = false;
  formLogin = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required]),
  })
  constructor(private toastr: ToastrService, private userService: UserService, private translate: TranslateService) { }

  ngOnInit() {
  }

  handleChangeView(view) {
    this.changeView.emit(view);
  }

  onSubmitLogin(){
    this.formLoginHasError = !this.formLogin.valid;
    if(this.formLogin.valid){
      this.sendLogin();
    }
  }

  async sendLogin(){
    let loginFailed;
    await this.translate.get('LoginFallido').toPromise().then(resp => loginFailed = resp)
    this.isLoading = true;
    this.userService.Login(this.formLogin.value).toPromise().then(data => {
      this.isLoading = false;
      const response: any = data;
      if (response.success) {
        localStorage.removeItem('guest');
        localStorage.setItem("user", JSON.stringify(response.data));
        if(this.isCheckout){
          document.location.href = "/checkout";
        }else{
          document.location.href = "/";
        }
      } else {
        this.toastr.error(loginFailed);
      }
    })
    .catch((err) => {
      this.isLoading = false;
      const msgError = err.error.message === 'unauthorized' ? loginFailed : err.message
      this.toastr.error(msgError);
    });
  }

}
