import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HotelService {
  private apiUrl: string = environment.APIURL;
  constructor(private http: HttpClient) {}

  findAvailabilities(data) {
    const url = `${this.apiUrl}restel/availability`;
    return this.http.post(url, data);
  }
}
