import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";

@Component({
  selector: "app-hotel-detail-tab-overview",
  templateUrl: "./hotel-detail-tab-overview.component.html",
  styleUrls: ["./hotel-detail-tab-overview.component.css"],
})
export class HotelDetailTabOverviewComponent implements OnInit {
  description: string;
  hotelName: string;
  private hotelSubscribe: any;
  constructor(private store: Store<{ hotel: any }>) {}

  ngOnInit() {
    this.hotelSubscribe = this.store
      .pipe(select("hotel"))
      .subscribe((hotel) => {
        const { hotelSelected } = hotel;
        this.description = hotelSelected.information.description;
        this.hotelName = hotelSelected.name;
      });
  }

  ngOnDestroy() {
    this.hotelSubscribe.unsubscribe();
  }
}
