import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-listado-result',
  templateUrl: './car-listado-result.component.html',
  styleUrls: ['./car-listado-result.component.css']
})
export class CarListadoResultComponent implements OnInit {
  cars: any = [];
  constructor() { }

  ngOnInit() {
    this.cars = [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}];
  }

}
