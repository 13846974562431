import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-car-search',
  templateUrl: './car-search.component.html',
  styleUrls: ['./car-search.component.css']
})
export class CarSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#offer1').owlCarousel({
      loop: true,
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      navigation: false,
      stopOnHover: true,
      responsive: {
        1000: {
          items: 1,
          loop: true
        }
      }
    });

    $( '#departure_date' ).datepicker({ minDate: -0, maxDate: '+3M' });
    $( '#delivery_date' ).datepicker({ minDate: -0, maxDate: '+3M' });

    $('.selectpicker').selectpicker({
      style: 'custom-select-button',
      width: 'auto'
    });
  }

}
