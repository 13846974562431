import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ExchangerateService } from '../../../services/ExchangeRate/exchangerate.service';

@Component({
  selector: 'app-package-detail-sidebar',
  templateUrl: './package-detail-sidebar.component.html',
  styleUrls: ['./package-detail-sidebar.component.css']
})
export class PackageDetailSidebarComponent implements OnInit {
  @Output() reserveEvent: EventEmitter<any> =  new EventEmitter();
  URL_WEB = environment.URL_WEB;
  @Input() package: any;
  @Input() minPrice = 0;
  constructor(
    public exchangerateService: ExchangerateService
  ) { }

  ngOnInit() {
    
  }

  reserve() {
    this.reserveEvent.emit();
  }

}
