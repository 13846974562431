import {Component, OnInit, ViewChild, } from '@angular/core';
import { CreditCard } from '../../../models/creditcard.model';
import {CreditCardService} from '../../../services/CreditCard/credit-card.service';

@Component({
  selector: 'app-my-cards-center-bar',
  templateUrl: './my-cards-center-bar.component.html',
  styleUrls: ['./my-cards-center-bar.component.css']
})
export class MyCardsCenterBarComponent implements OnInit {
  @ViewChild('modalCreditCard') modalCreditCard;
  cards: Array<CreditCard>;
  isLoading = false;
  constructor(
    private creditCardService: CreditCardService
    ) {
  }

  ngOnInit() {
    // $('.open-popup-link').magnificPopup({
    //   type: 'inline',
    //   midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't
    // });
    this.getCards();
  }

  async getCards(){
    this.cards = await this.creditCardService.getAll();
  }

  closeModal(){
    this.modalCreditCard.hide();
  }
}
