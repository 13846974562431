import {CartItem} from './cart-item.model';
import {Flight} from './flight.model';
import {Deserializable} from './deserializable.model';

export class CartFlight extends CartItem implements Deserializable {
  flight: Flight;
  adults: number;
  children: number;
  babies: number;
  type: string;
  product_type = 1;
  multidestiny = true;
  cost = 0;
  price = 0;
  subtotal = 0;
  discount = 0;
  total = 0;

  deserialize(input: any): this {
    this.flight = new Flight().deserialize(input.flight);
    this.adults = input.adults;
    this.children = input.children;
    this.babies = input.babies;

    return super.deserialize(input);
  }

  calculateTotal(adults, children, babies) {
    this.adults = adults;
    this.children = children;
    this.babies = babies;

    this.cost = 0;
    this.cost += this.flight.costAdult * adults;
    this.cost += this.flight.costChild * children;
    this.cost += this.flight.costInfant * babies;

    this.price = 0;
    this.price += this.flight.priceAdult * adults;
    this.price += this.flight.priceChild * children;
    this.price += this.flight.priceInfant * babies;

    this.subtotal = this.price;
    this.total = this.price;
  }
}
