import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AlternativeReservationService } from '../../../services/AlternativeReservation/alternative-reservation.service';
@Component({
  selector: 'app-home2-contact-us',
  templateUrl: './home2-contact-us.component.html',
  styleUrls: ['./home2-contact-us.component.css']
})
export class Home2ContactUsComponent implements OnInit {
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
  });
  isLoading: Boolean = false;
  constructor(private reservationService: AlternativeReservationService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  onSubmit() {
    this.isLoading = true;
    this.reservationService.Save(this.form.value).toPromise().then(data => {
      this.isLoading = false;
      const response: any = data;
      if (response.success) {
        this.toastr.success('Sus datos han sido enviados exitosamente. En breve nos pondremos en contacto.');
      } else {
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      }
    })
    .catch((err) => {
      this.isLoading = false;
      this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
    });
  }
}
