import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-package-listado-result',
  templateUrl: './package-listado-result.component.html',
  styleUrls: ['./package-listado-result.component.css']
})
export class PackageListadoResultComponent implements OnInit {
  @Input() isLoading = false;
  @Input() packages: any;
  URL_WEB = environment.URL_WEB;
  constructor() { }

  ngOnInit() {
    $('.selectpicker').selectpicker({
      style: 'custom-select-button'
    });
  }

  

  // minPrice(availabilities, is_multiple_destiny) {
  //   if (is_multiple_destiny === 1) {
  //     let minAv = 999999;
  //     // availabilities.map(function(av) {
  //     //   console.log('rooms:');
  //     //   console.log(av.rooms);
  //     // });
  //     availabilities.map(function(av) {
  //       const minRoom = av.rooms.reduce((min, p) => Math.min(min, p.pivot.adult), av.rooms[0].pivot.adult);
  //       minAv =  minAv < minRoom ? minAv : minRoom;
  //     });
  //     return minAv;
  //   } else {
  //     return availabilities.reduce((min, p) => Math.min(min, p.single), availabilities[0].single);
  //   }
  // }
}
