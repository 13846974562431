import {Deserializable} from './deserializable.model';
import {Airline} from './airline.model';
import {Airport} from './airport.model';

export class FlightConnection implements Deserializable {
  id: number;
  departureDate: string;
  departureTime: string;
  arrivalDate: string;
  arrivalTime: string;
  checkinTime: string;
  gate: number;
  type: string;
  airline: Airline;
  airport: Airport;
  airport_arrival: Airport;
  number: number;

  deserialize(input: any): this {
    this.id = input.id;
    this.arrivalDate = input.arrival_date;
    this.arrivalTime = input.arrival_time;
    this.departureDate = input.date;
    this.departureTime = input.time;
    this.checkinTime = input.timecheckin;
    this.gate = input.gate;
    this.number = input.flightNumber;

    this.airline = new Airline().deserialize(input.airline);
    this.airport = new Airport().deserialize(input.airport_from);
    this.airport_arrival = new Airport().deserialize(input.airport_arrival);

    return this;
  }
}
