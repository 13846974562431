import {Deserializable} from './deserializable.model';

export class CreditCard implements Deserializable {
  id: string;
  card_brand: string;
  last_4: string;
  exp_month: number;
  exp_year: number;
  cardholder_name: string;

  deserialize(input: any): this {
    this.id = input.id;
    this.card_brand = input.card_brand;
    this.last_4 = input.last_4;
    this.exp_month = input.exp_month;
    this.exp_year = input.exp_year;
    this.cardholder_name = input.cardholder_name;

    return this;
  }
}
