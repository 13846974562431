import { createAction, props } from "@ngrx/store";
import { HotelSearchData } from "../models/hotel-search-data.model";

export const selectHotel = createAction(
  "[Hotel Component] Select",
  props<{ hotel: any }>()
);
export const deleteHotel = createAction("[Hotel Component] Delete");

export const addPasengers = createAction(
  "[Hotel Component] Add Passengers",
  props<{ passengers: any }>()
);
export const deletePassengers = createAction(
  "[Hotel Component] Delete Passengers"
);

export const saveSearchData = createAction(
  "[Hotel Component] Search Data",
  props<{ searchData: HotelSearchData }>()
);
