import { Component, OnInit, Input } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-image-gallery-mobile",
  templateUrl: "./image-gallery-mobile.component.html",
  styleUrls: ["./image-gallery-mobile.component.css"],
})
export class ImageGalleryMobileComponent implements OnInit {
  @Input() images: any;
  arrayImagenes: any = [];
  URL_WEB = environment.URL_WEB;
  constructor() {}

  ngOnInit() {
    this.images.map((item) => {
      this.arrayImagenes.push(this.URL_WEB + item.image);
    });
  }
}
