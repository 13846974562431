import { Component, OnInit, Input } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { Router } from "@angular/router";
import { ExchangerateService } from "../../../../services/ExchangeRate/exchangerate.service";

@Component({
  selector: "app-package-listado-result-item",
  templateUrl: "./package-listado-result-item.component.html",
  styleUrls: ["./package-listado-result-item.component.css"],
})
export class PackageListadoResultItemComponent implements OnInit {
  @Input() package: any;
  primaryServices: any = [];
  secondaryServices: any = [];
  included_services: any = [];
  URL_WEB = environment.URL_WEB;
  constructor(
    public exchangerateService: ExchangerateService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.primaryServices = this.package.included_services.filter(
      (item) => item.type === 1
    );
    this.secondaryServices = this.package.included_services.filter(
      (item) => item.type === 2
    );
    this.included_services = this.primaryServices.concat(
      this.secondaryServices
    );
    this.included_services.length = 6;
  }

  getDetail(id) {
    this.router.navigate(["/packages/detail/", id]);
  }
}
