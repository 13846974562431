import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {User} from '../../models/user.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl: string = environment.APIURL;
  private user: User;
  public updated$ = new EventEmitter();

  isLogged;

  constructor(private http: HttpClient) {
    this.checkLogin();
  }

  async checkLogin() {
    const userData = await localStorage.getItem('user');
    if (userData) {
      this.isLogged = true;
    }
  }

  Register(data) {
    const url = this.apiUrl + 'user/register';
    return this.http.post(url, data);
  }

  Login(data) {
    const url = this.apiUrl + 'user/login';
    return this.http.post(url, data);
  }

  async Logout() {
    await localStorage.removeItem('user');
    this.isLogged = false;
    document.location.href = '/';
  }

  async UserDataLogin() {
    const userData = await localStorage.getItem('user');
    return JSON.parse(userData);
  }

  async getById(id: number): Promise<any> {
    return await this.http.get(`${this.apiUrl}user/data/${id}`).pipe(map((response: any) => {
      return new User().deserialize(response.data);
    })).toPromise();
  }

  async getCurrent() {
    const localUser = JSON.parse(localStorage.getItem('user'));
    if(localUser !== null){
      return await this.getById(localUser.id);
    }
    return null;
    
  }

  async update(user) {
    const localUser = JSON.parse(localStorage.getItem('user'));
    const promise = this.http.post(`${this.apiUrl}user/data/${localUser.id}`, user).toPromise();

    await promise.then(() => {
      this.updated$.emit();
    });

    return await promise;
  }

  async updatePassword(form) {
    const localUser = JSON.parse(localStorage.getItem('user'));
    return this.http.post(`${this.apiUrl}user/changepassword/${localUser.id}`, form).toPromise();
  }

  async getGuestUser(){
    const email = await localStorage.getItem('guest');
    return email;
  }
}
