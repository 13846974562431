import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.css']
})
export class HotelSearchComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    $('#offer1').owlCarousel({
      loop: true,
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      navigation: false,
      stopOnHover: true,
      responsive: {
        1000: {
          items: 1,
          loop: true
        }
      }
    });
    $( '#check_out' ).datepicker({ minDate: -0, maxDate: '+3M' });
    $( '#check_in' ).datepicker({ minDate: -0, maxDate: '+3M' });
    $('.selectpicker').selectpicker({
      style: 'custom-select-button'
    });
    $('#hotel_adult_count').spinner( {
      min: 1
    });
    $('#hotel_child_count').spinner( {
      min: 1
    });
  }
}
