import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-asistencia-horizontal',
  templateUrl: './asistencia-horizontal.component.html',
  styleUrls: ['./asistencia-horizontal.component.css']
})
export class AsistenciaHorizontalComponent implements OnInit {
  @Input() bgcolortitle = false;
  constructor() { }

  ngOnInit() {
  }

}
