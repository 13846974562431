import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { selectHotel, addPasengers } from "../../../../actions/hotel.actions";
import { ExchangerateService } from "../../../../services/ExchangeRate/exchangerate.service";

@Component({
  selector: "app-hotel-listado-result-item",
  templateUrl: "./hotel-listado-result-item.component.html",
  styleUrls: ["./hotel-listado-result-item.component.css"],
})
export class HotelListadoResultItemComponent implements OnInit {
  @Input() hotelAvailability: any;

  constructor(
    public exchangerateService: ExchangerateService,
    private router: Router,
    private store: Store<{ hotel: any }>
  ) {}

  ngOnInit() {}

  getDetail(hotel: any) {
    this.store.dispatch(selectHotel({ hotel }));
    this.router.navigate(["/hotels/detail/", hotel.cod_hotel]);
  }
}
