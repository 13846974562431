import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-renta-search',
  templateUrl: './renta-search.component.html',
  styleUrls: ['./renta-search.component.css']
})
export class RentaSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#offer1').owlCarousel({
      loop: true,
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      navigation: false,
      stopOnHover: true,
      responsive: {
        1000: {
          items: 1,
          loop: true
        }
      }
    });

    /* LAST MINUTE DEALS SLIDER */

		if (jQuery("#lastminute").length){
			jQuery("#lastminute").owlCarousel({
				loop:true,
				responsiveClass:true,
				margin:30,
				autoplay:false,
				autoplayTimeout:5000,
				navigation:false,
				stopOnHover:true,
				responsive:{
					0:{
						items:1,
						loop:true
					},
					600:{
						items:2,
						loop:true
					},
					1000:{
						items:4,
						loop:true
					}
				}
			});
		}

    $( '#check_out' ).datepicker({ minDate: -0, maxDate: '+3M' });
    $( '#check_in' ).datepicker({ minDate: -0, maxDate: '+3M' });
    $('.selectpicker').selectpicker({
      style: 'custom-select-button'
    });
    $('#rooms_count').spinner( {
      min: 1
    });
    $('#adult_count').spinner( {
      min: 1
    });
    $('#child_count').spinner( {
      min: 1
    });
  }

}
