import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { CityService } from "../../../services/City/city.service";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { FlightService } from "../../../services/Flight/flight.service";

declare var $: any;

@Component({
  selector: "app-vuelos-search",
  templateUrl: "./vuelos-search.component.html",
  styleUrls: ["./vuelos-search.component.css"],
})
export class VuelosSearchComponent implements OnInit, OnDestroy {
  disableSencillo = true;
  citiesFrom = [];
  citiesTo = [];
  minDateFrom: Date = new Date();
  minDateTo: Date;
  bsConfig = {
    dateInputFormat: "YYYY-MM-DD",
    containerClass: "theme-blue",
    showWeekNumbers: false,
  };
  hasError = false;
  form = new FormGroup({
    departureCity: new FormControl(null, [Validators.required]),
    destinyCity: new FormControl(null, [Validators.required]),
    departureDate: new FormControl(null, [Validators.required]),
    returnDate: new FormControl(null, [Validators.required]),
    adults: new FormControl(1, [Validators.required]),
    children: new FormControl(0, [Validators.required]),
    babies: new FormControl(0, [Validators.required]),
    direction: new FormControl("Redondo", [Validators.required]),
  });

  constructor(
    private toastr: ToastrService,
    private flightService: FlightService,
    private cityService: CityService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getCities("from");
    this.getCities("to");
  }

  ngOnDestroy(): void {}

  getCities(target) {
    this.cityService
      .get(target, "flight")
      .toPromise()
      .then((response: any) => {
        if (response.success) {
          if (target === "from") {
            this.citiesFrom = response.data;
          } else if (target === "to") {
            this.citiesTo = response.data;
          }
        } else {
          this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
        }
      })
      .catch((err) => {
        this.toastr.error(
          "Ocurrió un error al cargar las ciudades, inténtelo de nuevo más tarde."
        );
      });
  }

  submitSearch() {
    if (this.form.valid) {
      this.hasError = false;
      // this.form.get('departureDate').setValue(moment(this.form.get('departureDate').value).format('YYYY-MM-DD'));
      // this.form.get('returnDate').setValue(moment(this.form.get('returnDate').value).format('YYYY-MM-DD'));
      localStorage.setItem("searchFlyFields", JSON.stringify(this.form.value));
      this.flightService.saveSearch(this.form.value);

      this.router.navigate(["/flights/list"]);
    } else {
      this.hasError = true;
    }
  }

  startChanged(e) {
    if (e != null) {
      const prevEndDate = moment(e).add(1, "days");
      if (
        this.form.get("departureDate").value < e ||
        this.form.get("returnDate").value === ""
      ) {
        this.form.get("returnDate").setValue(prevEndDate.toDate());
        // let ele: HTMLElement = document.getElementById('ll') as HTMLElement;
        // ele.open();
      }
      this.minDateTo = prevEndDate.toDate();
    }
  }
}
