import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-car-listado-filters',
  templateUrl: './car-listado-filters.component.html',
  styleUrls: ['./car-listado-filters.component.css']
})
export class CarListadoFiltersComponent implements OnInit {
  tiposAutos: any = [];
  proveedorAutos: any = [];
  transmisionAutos: any = [];
  constructor() {
    this.tiposAutos = [
      {nombre: 'Compacto'},
      {nombre: 'Sedán'},
      {nombre: 'Cabriolet'},
      {nombre: 'Van'},
      {nombre: 'Jeep'},
      {nombre: 'Crossover'},
      {nombre: 'Económico'},
      {nombre: 'Compacto'},
      {nombre: 'Sedán'},
      {nombre: 'Cabriolet'},
      {nombre: 'Van'},
      {nombre: 'Jeep'},
      {nombre: 'Crossover'},
      {nombre: 'Económico'}
    ];
    this.proveedorAutos = [
      {imagen: 'cdba.png'},
      {imagen: 'islamabad.png'},
      {imagen: 'carrental.png'},
      {imagen: 'cubacar.png'},
      {imagen: 'cdba.png'},
      {imagen: 'islamabad.png'},
      {imagen: 'carrental.png'},
      {imagen: 'cubacar.png'},
    ];
    this.transmisionAutos = [
      {nombre: 'Transmisión Manual'},
      {nombre: 'Transmisión Automática'}
    ];
  }

  ngOnInit() {
    $( '#price-range' ).slider({
      range: true,
      min: 0,
      max: 100,
      values: [ 0, 50 ],
      slide: function( event, ui ) {
      $( '#amount' ).val( '$ ' + ui.values[ 0 ] + ' - $ ' + ui.values[ 1 ] );
      }
    });
    $( '#amount' ).val( '$ ' + $( '#price-range' ).slider( 'values', 0 ) +
      ' - $ ' + $( '#price-range' ).slider( 'values', 1 ) );
  }

}
