import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-package-detail-tab-info',
  templateUrl: './package-detail-tab-info.component.html',
  styleUrls: ['./package-detail-tab-info.component.css']
})
export class PackageDetailTabInfoComponent implements OnInit {
  @Input() policies: string;
  constructor() { }

  ngOnInit() {
  }

}
