import { Component, OnInit, Input } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { ExchangerateService } from "../../../services/ExchangeRate/exchangerate.service";

@Component({
  selector: "app-confirmation-resume",
  templateUrl: "./confirmation-resume.component.html",
  styleUrls: ["./confirmation-resume.component.css"],
})
export class ConfirmationResumeComponent implements OnInit {
  @Input() order;
  URL_WEB = environment.URL_WEB;
  imageBanner: string = "";
  bookWithPrice = 0;
  payBook = false;
  constructor(public exchangerateService: ExchangerateService) {}

  ngOnInit() {
    // console.log(this.order);
    if (this.order.details[0].product_type == 7) {
      this.imageBanner = this.order.details[0].product_data.image;
    }
  }
}
