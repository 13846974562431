import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home/home.component";
import { HotelComponent } from "./components/hotel/hotel/hotel.component";
import { HotelDetailComponent } from "./pages/hotel-detail/hotel-detail.component";
import { HotelListadoComponent } from "./pages/hotel-listado/hotel-listado.component";
import { RentaComponent } from "./components/renta/renta/renta.component";
import { RentaDetailComponent } from "./components/renta/renta-detail/renta-detail.component";
import { VuelosComponent } from "./components/vuelos/vuelos/vuelos.component";
import { VuelosListadoComponent } from "./components/vuelos/vuelosListado/vuelos-listado/vuelos-listado.component";
import { PackageComponent } from "./pages/package/package.component";
import { PackageDetailComponent } from "./pages/package-detail/package-detail.component";
import { PackageListadoComponent } from "./pages/package-listado/package-listado.component";
import { TourComponent } from "./components/tour/tour/tour.component";
import { TourDetailComponent } from "./components/tour/tour-detail/tour-detail.component";
import { TourListadoComponent } from "./components/tour/tourListado/tour-listado/tour-listado.component";
import { CarComponent } from "./components/car/car/car.component";
import { CarDetailComponent } from "./components/car/car-detail/car-detail.component";
import { CarListadoComponent } from "./components/car/carListado/car-listado/car-listado.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { ShoppingCartComponent } from "./pages/shopping-cart/shopping-cart.component";
import { PoliciesComponent } from "./pages/policies/policies.component";
import { Home2Component } from "./pages/home2/home2.component";
import { WhoWeAreComponent } from "./pages/who-we-are/who-we-are.component";
import { LoginregisterComponent } from "./components/user/loginregister/loginregister.component";
import { OrderConfirmationComponent } from "./pages/order-confirmation/order-confirmation.component";
import { FlightsListComponent } from "./pages/flights-list/flights-list.component";
import { UserloginComponent } from "./pages/userlogin/userlogin.component";
import { UserregisterComponent } from "./pages/userregister/userregister.component";
import { UserlogincheckoutComponent } from "./pages/userlogincheckout/userlogincheckout.component";
// import { AuthGuardService } from './services/auth/guards/auth-guard.service';

const routes: Routes = [
  { path: "", component: Home2Component },
  { path: "flights/list", component: FlightsListComponent },
  { path: "packages", component: PackageComponent },
  { path: "packages/list", component: PackageListadoComponent },
  { path: "packages/detail/:id", component: PackageDetailComponent },

  { path: "profile", component: DashboardComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "confirmation/:numOrder", component: OrderConfirmationComponent },
  { path: "cart", component: ShoppingCartComponent },
  { path: "terms", component: PoliciesComponent },
  { path: "whoweare", component: WhoWeAreComponent },
  { path: "login", component: UserloginComponent },
  { path: "signup", component: UserregisterComponent },
  { path: "checkout/login", component: UserlogincheckoutComponent },
  // { path: "home2", component: HomeComponent },
  // { path: 'vuelos', component: VuelosComponent },
  // { path: 'home', component: Home2Component },
  // { path: 'tours', component: TourComponent },
  // { path: 'tours/detalles', component: TourDetailComponent },
  // { path: 'tours/listado', component: TourListadoComponent },
  // { path: 'autos', component: CarComponent },
  // { path: 'autos/detalles', component: CarDetailComponent },
  // { path: 'autos/listado', component: CarListadoComponent },
  { path: "hotels", component: HotelComponent },
  { path: "hotels/detail/:cod_hotel", component: HotelDetailComponent },
  { path: "hotels/list", component: HotelListadoComponent },
  // { path: 'rentas', component: RentaComponent },
  // { path: 'rentas/detalles', component: RentaDetailComponent },
  { path: "**", pathMatch: "full", redirectTo: "" },
];

export const routing = RouterModule.forRoot(routes, { useHash: false });
