import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
declare var $: any;

@Component({
  selector: "app-hotel-listado-filters",
  templateUrl: "./hotel-listado-filters.component.html",
  styleUrls: ["./hotel-listado-filters.component.css"],
})
export class HotelListadoFiltersComponent implements OnInit {
  @Output() filterPackages: EventEmitter<any> = new EventEmitter();
  @Input() numberResult;
  intereses: any = [];

  constructor() {}

  ngOnInit() {
    this.llenarInformacion();
    $("#price-range").slider({
      range: true,
      min: 0,
      max: 100,
      values: [0, 50],
      slide: function (event, ui) {
        $("#amount").val("$ " + ui.values[0] + " - $ " + ui.values[1]);
      },
    });
    $("#amount").val(
      "$ " +
        $("#price-range").slider("values", 0) +
        " - $ " +
        $("#price-range").slider("values", 1)
    );
  }

  llenarInformacion() {
    this.intereses = [
      { nombre: "Reuniones y eventos", fa: "bicycle" },
      { nombre: "Calidad de vida y spa", fa: "book" },
      { nombre: "Comer y beber", fa: "bath" },
      { nombre: "Viaje de historia y cultura", fa: "bed" },
      { nombre: "Viaje de negocios", fa: "beer" },
      { nombre: "Sol y playas", fa: "sun-o" },
      { nombre: "Sólo adultos", fa: "bullseye" },
    ];
  }
}
