import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-search-offer-slider',
  templateUrl: './search-offer-slider.component.html',
  styleUrls: ['./search-offer-slider.component.css']
})
export class SearchOfferSliderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#offer1').owlCarousel({
      loop: true,
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      navigation: false,
      stopOnHover: true,
      responsive: {
        1000: {
          items: 1,
          loop: true
        }
      }
    });
  }

}
