import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-detail-tab-write-review',
  templateUrl: './hotel-detail-tab-write-review.component.html',
  styleUrls: ['./hotel-detail-tab-write-review.component.css']
})
export class HotelDetailTabWriteReviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
