import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SquareComponent } from "./../paymentforms/square/square.component";
import { CartService } from "../../../services/Cart/cart.service";
import { ExchangerateService } from "../../../services/ExchangeRate/exchangerate.service";
import { NoCommaPipe } from "../../../pipes/noComma.pipe";
import { PRODUCT_TYPES } from "../../../constants/productTypes";
@Component({
  selector: "app-checkout-pago",
  templateUrl: "./checkout-pago.component.html",
  styleUrls: ["./checkout-pago.component.css"],
  providers: [NoCommaPipe],
})
export class CheckoutPagoComponent implements OnInit {
  @ViewChild(SquareComponent) square: SquareComponent;
  @Output() savePaymentTab: EventEmitter<any> = new EventEmitter();
  isLoading = false;
  bookWithPrice = 0;
  cart: any;
  payBook = false;
  amounttopaid = 0;
  formPayment = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    terms: new FormControl(null, [Validators.required]),
  });
  formHasError = false;
  constructor(
    private noComma: NoCommaPipe,
    public exchangerateService: ExchangerateService,
    private cartService: CartService
  ) {}

  async ngOnInit() {
    await this.loadCart();
    // await this.calculateBookWithPrice();
    this.changeAmountToPay(2);
  }

  onSubmitPayment() {
    this.formHasError = !this.formPayment.valid;
    if (!this.formHasError) {
      this.isLoading = true;
      this.square.sendForm();
      // this.isLoading = false;
    }
  }

  sendPayment(data) {
    const dataPayment = {
      name: this.formPayment.get("name").value,
      zipCode: data.zipCode,
      terms: this.formPayment.get("terms").value,
      nonceCard: data.nonceCard,
    };
    this.savePaymentTab.emit(dataPayment);
  }

  changeAmountToPay(n) {
    if (n === 1) {
      this.cartService.changeAmountToPaid(this.bookWithPrice);
      this.payBook = true;
      this.amounttopaid = parseInt(
        this.noComma.transform(
          this.bookWithPrice * this.exchangerateService.currentExchangeRate
        )
      );
    } else {
      this.payBook = false;
      this.cartService.changeAmountToPaid(this.cart.total);
      this.amounttopaid = parseInt(
        this.noComma.transform(
          this.cart.total * this.exchangerateService.currentExchangeRate
        )
      );
    }
  }

  async loadCart() {
    const localCart = await localStorage.getItem("cart");
    if (localCart !== null && localCart !== undefined) {
      this.cart = JSON.parse(localCart);
      if (this.cart.items.length === 0) {
        this.cart = null;
      }
      if (
        this.cart.items[0].product_type === PRODUCT_TYPES.PACKAGES &&
        this.cart.items[0].availability.book_with
      ) {
        this.calculateBookWithPrice();
      }
    }
  }

  calculateBookWithPrice() {
    const { book_with, calculatedPrice } = this.cart.items[0].availability;
    this.bookWithPrice = this.cartService.calculateBookPrice(
      book_with,
      calculatedPrice
    );
  }
}
