import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tour-detail-resumen-tour',
  templateUrl: './tour-detail-resumen-tour.component.html',
  styleUrls: ['./tour-detail-resumen-tour.component.css']
})
export class TourDetailResumenTourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
