import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { CartService } from "../../../../services/Cart/cart.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../../../environments/environment";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { PRODUCT_TYPES } from "../../../../constants/productTypes";

@Component({
  selector: "app-vuelos-listado-result",
  templateUrl: "./vuelos-listado-result.component.html",
  styleUrls: ["./vuelos-listado-result.component.css"],
})
export class VuelosListadoResultComponent implements OnInit {
  selectedFly: any;

  @Input("modelParams") filterParams: any;
  @Output("modelParamsChange") filterParamsChange = new EventEmitter<Object>();
  @Input() list: any;
  URL_WEB = environment.URL_WEB;

  constructor(
    private modalService: BsModalService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private cartService: CartService
  ) {}

  ngOnInit() {}

  handleChange() {
    this.filterParamsChange.emit(this.filterParams);
  }

  async reserveFly(fly) {
    let msgItemAdded;
    await this.translate
      .get("item_added_cart")
      .toPromise()
      .then((resp) => (msgItemAdded = resp));
    const item = {
      product_type: PRODUCT_TYPES.FLIGHTS,
      multidestiny: true,
      fly: fly,
      adults: fly.adults,
      children: fly.children,
      babies: fly.babies,
      cost: fly.price,
      subtotal: fly.price,
      discount: 0,
      price: fly.price,
    };
    this.cartService.addItem(item);
    this.toastr.success(msgItemAdded);
  }

  openMoreInfo(fly) {
    this.selectedFly = fly;
  }
}
