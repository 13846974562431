import { Component, OnInit } from '@angular/core';
import { AlternativePackagesService } from '../../../services/AlternativePackages/alternative-packages.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-home2-destinies',
  templateUrl: './home2-destinies.component.html',
  styleUrls: ['./home2-destinies.component.css']
})
export class Home2DestiniesComponent implements OnInit {
	destinies: any;
  isLoading: Boolean = false;
  URL_WEB = environment.URL_WEB;
  constructor(private packagesService: AlternativePackagesService, private toastr: ToastrService) { }

  ngOnInit() {
  	this.loadPackages();
  }

  loadPackages() {
    this.isLoading = true;
    this.packagesService.ListDestinies().toPromise().then(data => {
      console.log(data);
      this.isLoading = false;
      const r: any = data;
      if (r.success) {
        this.destinies = r.data;
      } else {
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      }
    })
    .catch((err) => {
      this.isLoading = false;
      this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
    });
  }

}
