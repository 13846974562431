import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-package-multidestiny-resume-card",
  templateUrl: "./package-multidestiny-resume-card.component.html",
  styleUrls: ["./package-multidestiny-resume-card.component.css"],
})
export class PackageMultidestinyResumeCardComponent implements OnInit {
  @Input() package;
  @Input() index;
  @Input() isCheckout;
  @Input() isConfirmation: Boolean = false;
  @Output() removeItem: EventEmitter<any> = new EventEmitter();
  URL_WEB = environment.URL_WEB;
  imageBanner = "";
  departureFlight;
  returnFlight;
  constructor() {}

  ngOnInit() {
    this.imageBanner = this.package.package.image;
    // if(!this.package.hasOwnProperty('jsonData')){
    //   this.departureFlight = this.package.availability.id.flights[0].directions[0].transits[0]
    //   this.returnFlight = this.package.availability.id.flights[1].directions[0].transits[0]
    // }else{
    //   this.departureFlight = this.package.availability.flights[0].directions[0].transits[0]
    //   this.returnFlight = this.package.availability.flights[1].directions[0].transits[0]
    // }
  }
}
