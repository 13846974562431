import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-tour-listado-result',
  templateUrl: './tour-listado-result.component.html',
  styleUrls: ['./tour-listado-result.component.css']
})
export class TourListadoResultComponent implements OnInit {
  tours: any = [];
  constructor() { }

  ngOnInit() {
    $('.selectpicker').selectpicker({
      style: 'custom-select-button'
    });
    this.tours = [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}];
  }

}
