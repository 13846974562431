import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-tour-listado-search',
  templateUrl: './tour-listado-search.component.html',
  styleUrls: ['./tour-listado-search.component.css']
})
export class TourListadoSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(function() {
      'use strict';
      $( '#check_in' ).datepicker({ minDate: -0, maxDate: '+3M' });
      $( '#check_out' ).datepicker({ minDate: -0, maxDate: '+3M' });
      $( '#adult_count' ).spinner({
        min: 1
      });
      $( '#child_count' ).spinner( {
        min: 1
      });
    });
  }

}
