import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HotelService } from "../../services/Hotel/hotel.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { saveSearchData } from "../../actions/hotel.actions";

@Component({
  selector: "app-hotel-listado",
  templateUrl: "./hotel-listado.component.html",
  styleUrls: ["./hotel-listado.component.css"],
})
export class HotelListadoComponent implements OnInit {
  private hotelSubscribe$: any;
  isLoading = false;
  hotelAvailabilities: any = [];
  hotelAvailabilitiesFiltered: any = [];
  constructor(
    private store: Store<{ hotel: any }>,
    private router: Router,
    private toastr: ToastrService,
    private hotelService: HotelService
  ) {}

  ngOnInit() {
    this.hotelSubscribe$ = this.store
      .pipe(select("hotel"))
      .subscribe((hotel) => {
        const { searchData } = hotel;
        if (!searchData) {
          this.router.navigate(["/"]);
        } else {
          this.search(searchData, false);
        }
      });
  }

  ngOnDestroy() {
    this.hotelSubscribe$.unsubscribe();
  }

  search = async (formData, saveData) => {
    this.hotelAvailabilities = [];
    this.hotelAvailabilitiesFiltered = [];
    saveData && this.store.dispatch(saveSearchData({ searchData: formData }));

    this.isLoading = true;
    try {
      const response: any = await this.hotelService
        .findAvailabilities(formData)
        .toPromise();
      const { success, data } = response;
      this.isLoading = false;
      if (success) {
        this.hotelAvailabilities = data;
        this.hotelAvailabilitiesFiltered = data;
      } else {
        this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
      }
    } catch (error) {
      console.log(error);
      this.isLoading = false;
      this.toastr.error("Ocurrió un error, inténtelo de nuevo más tarde.");
    }
  };

  filter = () => {};

  // filterPackages(filters) {
  //   const { filterPrices, durations, includedServices, types } = filters;
  //   this.packagesFiltered = this.packages.filter(
  //     (pac) =>
  //       pac.minPrice >= filterPrices.slideMin &&
  //       pac.minPrice <= filterPrices.slideMax
  //   );
  //   if (durations.length > 0) {
  //     let idsDurationsPac = [];
  //     durations.forEach((ele) => {
  //       this.packagesFiltered
  //         .filter(
  //           (pac) => pac.nights >= ele.min - 1 && pac.nights <= ele.max - 1
  //         )
  //         .map((item) => idsDurationsPac.push(item.id));
  //     });
  //     this.packagesFiltered = this.packages.filter((pac) =>
  //       idsDurationsPac.includes(pac.id)
  //     );
  //   }
  //   if (types.length > 0) {
  //     this.packagesFiltered = this.packagesFiltered.filter(
  //       (pac) =>
  //         pac.types.filter((pacType) => types.includes(pacType.id)).length > 0
  //     );
  //   }
  //   if (includedServices.length > 0) {
  //     this.packagesFiltered = this.packagesFiltered.filter(
  //       (pac) =>
  //         pac.included_services.filter((pacServ) =>
  //           includedServices.includes(pacServ.id)
  //         ).length > 0
  //     );
  //   }
  // }
}
