import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-package-detail-includes-flight-item',
  templateUrl: './package-detail-includes-flight-item.component.html',
  styleUrls: ['./package-detail-includes-flight-item.component.css']
})
export class PackageDetailIncludesFlightItemComponent implements OnInit {
  @Input() flight;
  firstTransit: any;
  lastTransit: any;
  URL_WEB = environment.URL_WEB;
  constructor() { }

  ngOnInit() {
    console.log('flight')
    console.log(this.flight)
    const transits = this.flight.directions[0].transits;
    this.firstTransit = transits[0];
    this.lastTransit = transits[transits.length-1];
  }

}
