import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css']
})
export class PasswordresetComponent implements OnInit {
  @Output() changeView: EventEmitter<any> =  new EventEmitter();
  @Input() viewDefault: string;
  constructor() { }

  ngOnInit() {
  }

  handleChangeView(view) {
    this.changeView.emit(view);
  }

  returnLastScreen(){
    this.changeView.emit(this.viewDefault)
  }

}
