import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-hotel-detail-tabs",
  templateUrl: "./hotel-detail-tabs.component.html",
  styleUrls: ["./hotel-detail-tabs.component.css"],
})
export class HotelDetailTabsComponent implements OnInit {
  @Input() listRooms: any = [];
  constructor() {}

  ngOnInit() {
    console.log("listRooms tabs", this.listRooms);
  }
}
