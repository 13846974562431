import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css']
})
export class WhoWeAreComponent implements OnInit {
	public currentLang: string;

  constructor(private changeDetectorRef: ChangeDetectorRef, private translate: TranslateService) { 
  	this.currentLang = translate.currentLang;

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit() {
  	this.currentLang = this.translate.currentLang;
  }

}
