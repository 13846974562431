import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-detail-similar-hotels',
  templateUrl: './hotel-detail-similar-hotels.component.html',
  styleUrls: ['./hotel-detail-similar-hotels.component.css']
})
export class HotelDetailSimilarHotelsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
