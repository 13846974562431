import { Component, OnInit, Input } from "@angular/core";
import { environment } from "../../../../environments/environment";
declare var $: any;

type imageType = {
  url: string;
};

@Component({
  selector: "app-image-gallery",
  templateUrl: "./image-gallery.component.html",
  styleUrls: ["./image-gallery.component.css"],
})
export class ImageGalleryComponent implements OnInit {
  URL_WEB = environment.URL_WEB;
  @Input() images: any;
  @Input() isFullUrl: boolean = false;
  listImages: imageType[] = [];
  constructor() {}

  ngOnInit() {
    this.createListImages();
    // const t = this;
    // $(document).ready(function() {
    //     setTimeout(() => {
    //       t.createCarrousel();
    //     }, 1000);
    // });
  }

  createListImages = () => {
    if (this.isFullUrl) {
      this.listImages = this.images.map((item) => {
        return { url: item.image };
      });
    } else {
      this.listImages = this.images.map((item) => {
        return { url: `${this.URL_WEB}${item.image}` };
      });
    }
    setTimeout(() => {
      this.createCarrousel();
    }, 1000);
  };

  createCarrousel() {
    $("#carousel").flexslider({
      animation: "slide",
      controlNav: false,
      animationLoop: false,
      slideshow: false,
      itemWidth: 150,
      itemMargin: 5,
      asNavFor: "#slider",
    });
    $("#slider").flexslider({
      animation: "slide",
      controlNav: false,
      animationLoop: false,
      slideshow: false,
      sync: "#carousel",
    });
  }
}
