import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-last-minute-deals',
  templateUrl: './last-minute-deals.component.html',
  styleUrls: ['./last-minute-deals.component.css']
})
export class LastMinuteDealsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#lastminute').owlCarousel({
      loop: true,
      responsiveClass: true,
      margin: 30,
      autoplay: false,
      autoplayTimeout: 5000,
      navigation: false,
      stopOnHover: true,
      responsive: {
        0: {
          items: 1,
          loop: true
        },
        600: {
          items: 2,
          loop: true
        },
        1000: {
          items: 4,
          loop: true
        }
      }
    });
  }

}
