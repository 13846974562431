import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.css']
})
export class Home2Component implements OnInit {

  constructor() { }

  ngOnInit() {
    localStorage.removeItem('guest');
    localStorage.removeItem('searchFields');
    localStorage.removeItem('searchFlightParams');
    localStorage.removeItem('searchFlyFields');
  }

}
