import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-add-more-products',
  templateUrl: './add-more-products.component.html',
  styleUrls: ['./add-more-products.component.css']
})
export class AddMoreProductsComponent implements OnInit {
  @Input() isConfirmation: Boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
