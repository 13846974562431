import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../../services/Tools/tools.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-package-detail-tab-noincludes',
  templateUrl: './package-detail-tab-noincludes.component.html',
  styleUrls: ['./package-detail-tab-noincludes.component.css']
})
export class PackageDetailTabNoincludesComponent implements OnInit {
  @Input() package: any;
  serv_not_includes = [];
  URL_WEB = environment.URL_WEB;
  constructor(private tools: ToolsService) { }

  ngOnInit() {
    const notIncludes = this.package.not_included_services;
    const numElementsNotIncluded = Math.round(notIncludes.length / 2);
    this.serv_not_includes = this.tools.chunkArray(notIncludes, numElementsNotIncluded);
  }

}
