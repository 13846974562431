import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-tour-special-offers',
  templateUrl: './tour-special-offers.component.html',
  styleUrls: ['./tour-special-offers.component.css']
})
export class TourSpecialOffersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    if (jQuery("#tour-list").length){
      jQuery("#tour-list").owlCarousel({
        loop:true,
        margin:30,
        responsiveClass:true,
        autoHeight:true,
        autoplay:true,
        autoplayTimeout:5000,
        navigation:false,
        stopOnHover:true,
        responsive:{
          0:{
            items:1,
            loop:true
          },
          600:{
            items:2,
            loop:true
          },
          1000:{
            items:4,
            loop:true
          }
        }
      });
     }
  }

}
