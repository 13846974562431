import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackageService} from '../../services/Package/package.service';
import { ToastrService } from 'ngx-toastr';
// import { PackageListadoSearchComponent } from '../../components/package/package-listado/package-listado-search';




@Component({
  selector: 'app-package-listado',
  templateUrl: './package-listado.component.html',
  styleUrls: ['./package-listado.component.css']
})
export class PackageListadoComponent implements OnInit {
  isLoading = false;
  packages: any = [];
  packagesFiltered: any = [];
  constructor(private toastr: ToastrService, private packageService: PackageService, private route: ActivatedRoute) { }

  ngOnInit() {
    const searchFields = JSON.parse(localStorage.getItem('searchFields'));
    if (searchFields != null) {
      this.search(searchFields);
    }
  }

  search(formData) {
    this.packages = [];
    this.packagesFiltered = [];
    localStorage.setItem('searchFields', JSON.stringify(formData));
    this.isLoading = true;
    this.packageService.searchPackages(formData).toPromise().then(data => {
      this.isLoading = false;
      const r: any = data;
      if (r.success) {
        this.packages = r.data;
        this.calculateMinPrice();
      } else {
        this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
      }
    })
    .catch((err) => {
      console.log(err);
      this.isLoading = false;
      this.toastr.error('Ocurrió un error, inténtelo de nuevo más tarde.');
    });
  }

  calculateMinPrice() {
    const t = this;
    this.packages.map(pack => {
      if (pack.is_multiple_destiny === 1) {
        let minAv = 999999;
        pack.availabilities.map(function(av) {
          const minRoom = av.rooms.reduce((min, p) => Math.min(min, p.pivot.adult), av.rooms[0].pivot.adult);
          minAv =  minAv < minRoom ? minAv : minRoom;
          pack.minPrice = minAv;
        });
      } else {
        pack.minPrice = pack.availabilities.reduce((min, p) => Math.min(min, p.single), pack.availabilities[0].single);
      }
      if(pack.minPrice > 0){
        this.packagesFiltered.push(pack);
      }
    });
    this.packages = this.packagesFiltered;
  }

  filterPackages(filters) {
    const { filterPrices, durations, includedServices, types } = filters
    this.packagesFiltered = this.packages.filter(pac => pac.minPrice >= filterPrices.slideMin && pac.minPrice <= filterPrices.slideMax);
    if(durations.length > 0){
      let idsDurationsPac = [];
      durations.forEach( (ele) => {
        this.packagesFiltered.filter(pac => pac.nights >= ele.min-1 && pac.nights <= ele.max-1).map(item => idsDurationsPac.push(item.id))
      })
      this.packagesFiltered = this.packages.filter(pac => idsDurationsPac.includes(pac.id));
    }
    if(types.length > 0){
      this.packagesFiltered = this.packagesFiltered.filter(pac => pac.types.filter(pacType => types.includes(pacType.id)).length > 0)
    }
    if(includedServices.length > 0){
      this.packagesFiltered = this.packagesFiltered.filter(pac => pac.included_services.filter(pacServ => includedServices.includes(pacServ.id)).length > 0)
    }
  }
}
