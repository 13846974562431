import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
  selector: "app-hotel-resume-card",
  templateUrl: "./hotel-resume-card.component.html",
  styleUrls: ["./hotel-resume-card.component.css"],
})
export class HotelResumeCardComponent implements OnInit {
  @Input() hotel;
  @Input() index;
  @Input() isCheckout;
  @Input() isConfirmation: Boolean = false;
  @Output() removeItem: EventEmitter<any> = new EventEmitter();
  imageBanner = "";
  constructor() {}

  ngOnInit() {
    this.imageBanner = this.hotel.hotel.information.image;
  }
}
