import {Deserializable} from './deserializable.model';

export class City implements Deserializable {
  id: number;
  name: string;
  code: number;
  countryId: number;

  deserialize(input: any): this {
    this.id = input.id;
    this.name = input.nombre;
    this.code = input.codigo;
    this.countryId = input.countryId;

    return this;
  }
}
